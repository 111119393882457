// @flow
import * as React from 'react'
import { ThemeProvider } from 'styled-components'
import { ScreenBadge } from 'react-awesome-styled-grid'
import { isDev } from '../../env'

export type PropsType = {
  children: React.Node
}

export const customConf = {
  mediaQuery: 'only screen',
  columns: {
    xs: 4, // Mobile: 4-cols
    sm: 8, // Tablet: 8-cols
    md: 8, // Tablet: 8-cols
    lg: 12, // DesktopLarge: 12-cols
    xl: 12
  },
  gutterWidth: {
    xs: 1, // Mobile: 16px
    sm: 1.875, // Tablet 30px
    md: 1.875, // Tablet 30px
    lg: 1.875, // DesktopLarge: 30px
    xl: 3.75 // 40px
  },
  paddingWidth: {
    xs: 1.25, // Mobile: 20px
    sm: 2.5, // Tablet: 40px
    md: 2.5, // Tablet: 40px
    lg: 3.75, // DesktopLarge: 60px
    xl: 3.75
  },
  container: {
    xs: 'full', // 'full' = max-width: 100%
    sm: 'full', // 'full' = max-width: 100%
    md: 'full', // 'full' = max-width: 100%
    lg: 'full', // 'full' = max-width: 100%
    xl: 'full' // 'full' = max-width: 100%
  },
  breakpoints: {
    xs: 1, // 16 and up (phone)
    sm: 30, // 480 and up (tablet)
    md: 48, // 768 and up (laptop/small desktop)
    lg: 64, // 1024 and up (desktop large)
    xl: 100 // 1600 and up (desktop xl / tv)
  }
}

export function DipseaTheme (props: PropsType): React.Node {
  return (
    <ThemeProvider theme={{ awesomegrid: customConf }}>
      {props.children}
      {isDev && <ScreenBadge />}
    </ThemeProvider>
  )
}
