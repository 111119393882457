// @flow
import bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import * as React from 'react'
import { isDev, isLocal, isServer, isStaging, version } from '../env'
import { currentUser } from './firebaseAuth'

type BugsnagCallbackEventType = {
  setUser: (userId: string, email: string, name?: string) => void
}
export function createErrorBoundary (): any {
  if (isDev || isLocal()) {
    return (class ErrorBoundary extends React.Component<{children: React.Node}> {
      render (): React.Node {
        return this.props.children
      }
    })
  } else {
    const bugsnagClient = bugsnag.start({
      apiKey: 'b61387ebfd348ea3d8fe068deae9a57b',
      collectUserIp: false,
      enabledReleaseStages: ['production', 'staging'],
      releaseStage: isStaging() ? 'staging' : 'production',
      appVersion: version,
      plugins: [new BugsnagPluginReact(React)],
      onError: (event: BugsnagCallbackEventType): void => {
        const user = currentUser()
        if (user && user.id && user.email && !isServer) {
          event.setUser(user.id, user.email)
        }
      }
    })

    return bugsnagClient.getPlugin('react')
  }
}

export function reportError (e: Error): void {
  bugsnag.notify(e)
}
