// @flow
import * as React from 'react'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { currentUser } from '@services/firebaseAuth'
import type { AppStateType } from '@reducers/appstate'
import { useSelector } from 'react-redux'
import { isFavoriteAsync, toggleFavoriteAsync } from '../../models/Favorites'
import type { UserType } from '../../flowTypes'
import HeartOutlineIcon from '@components/shared/icons/HeartOutlineIcon'
import HeartFilledIcon from '@components/shared/icons/HeartFilledIcon'

export function FavoriteButtonComponent (props: {favorited: boolean, size?: number}): React.Node {
  return (
    <FavoriteButtonContainer>
        {!props.favorited && <HeartOutlineIcon size={props.size || 24}/>}
        {props.favorited && <HeartFilledIcon size={props.size || 24}/>}
    </FavoriteButtonContainer>
  )
}

export function FavoriteButton (props: {trackId: string, size?: number}): React.Node {
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const [isFavorite, setIsFavorite] = React.useState<boolean>(false)
  const user = useSelector((state: AppStateType): UserType => state.user)
  React.useEffect((): void => {
    if (currentUser()) {
      setIsVisible(true)
      isFavoriteAsync(props.trackId).then((result: boolean): void => {
        setIsFavorite(result)
      })
    }
  }, [user])
  if (!isVisible) {
    return null
  }
  const toggleFavorite = (): void => {
    setIsFavorite(!isFavorite)
    toggleFavoriteAsync(props.trackId)
  }
  return <div onClick={toggleFavorite}><FavoriteButtonComponent favorited={isFavorite} size={props.size}/></div>
}

const FavoriteButtonContainer = styled.div`
    display: flex;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
