// @flow

// convenience methods for testing and mocking
export function dateNowMiliseconds (): number {
  return (new Date()).getTime()
}

export function dateNowSeconds (): number {
  return Math.round((new Date()).getTime() / 1000)
}

export function convertDateToString (date?: number): ?string {
  if (date) {
    const newDate = new Date(date)
    // getMonth() method returns the month (from 0 to 11)
    const showMonth = newDate.getMonth() + 1

    return `${showMonth}/${newDate.getDate()}/${newDate.getFullYear()}`
  }
  return null
}

export function humanReadableDate (dirtyDate?: string | number): string {
  if (!dirtyDate) {
    return 'unknown'
  }
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(dirtyDate).toLocaleDateString(undefined, options)
}
