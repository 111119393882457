// @flow
import * as actionTypes from './actionTypes'
import type { Dispatch } from 'redux'
import { reportError } from '../services/bugSnag'
import type { AppStateType } from '../reducers/appstate'
import { userSubscriptionExpiring } from '../models/User'
import { openSubscribeConfirmed } from './navigationActions'

export function togglePromoModal (): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<any> => {
    try {
      const state = getState()
      const isLoggedInAndSubscribed = !!(state.user && state.user.email && state.user.isSubscribed)
      const isExpiring = userSubscriptionExpiring(state.user)

      if (!state.promoFlowModalOpen && isLoggedInAndSubscribed && !isExpiring) {
        return dispatch(openSubscribeConfirmed(true))
      }

      return dispatch({
        type: actionTypes.TOGGLE_PROMO_FLOW_MODAL
      })
    } catch (e) {
      reportError(e)
    }
  }
}

export function setHeaderCopyForAuthAndSubscribeModals (
  textAuthHeader: string | null,
  textAuthSubheader: string | null,
  textSubscriptionConfirmationHeader: string | null,
  textSubscriptionConfirmationSubheader: string | null
): actionTypes.SetHeaderCopyForAuthAndSubscribeModalsType {
  return {
    type: actionTypes.SET_HEADER_COPY_FOR_AUTH_AND_SUBSCRIBE_MODALS,
    textAuthHeader,
    textAuthSubheader,
    textSubscriptionConfirmationHeader,
    textSubscriptionConfirmationSubheader
  }
}
