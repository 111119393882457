// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared'

export type PropsType = {
  position: number,
  duration: number,
  isDisabled: boolean,
  seek: (position: number) => void,
  togglePlayState: () => void
}
export default function Seek (
  props: PropsType
): React.Node {
  const {
    position,
    duration,
    seek,
    isDisabled,
    togglePlayState
  } = props
  const [timeoutLog, setTimeoutLog] = React.useState<number[]>([])
  const [currentPosition, setCurrentPosition] = React.useState<number>(position)

  React.useEffect((): void => {
    setCurrentPosition(position)
  }, [position])
  const setTimeoutCadence = (pos: number): void => {
    if (timeoutLog.length > 1) {
      timeoutLog.map((id: number): void => window.clearTimeout(id))
    }
    const onChangeTimeout = window.setTimeout((): void => {
      const newPosition = pos >= duration ? duration - 1 : pos
      setCurrentPosition(newPosition)
      seek(newPosition)
    }, 200)
    setTimeoutLog([...timeoutLog, onChangeTimeout])
  }

  const change = (e: any): void => {
    e.preventDefault()
    const pos = parseInt(e.target.value)
    setTimeoutCadence(pos)
  }

  return (
    <RangeInput
      isDisabled={!!isDisabled}
      aria-label="Audio track position"
      type="range"
      min="1"
      step='.01'
      max={duration}
      value={currentPosition}
      onChange={change}
      onKeyDown={(e: KeyboardEvent): void => {
        if (e.key === 'Space' || e.key === 'Enter') {
          togglePlayState()
        }
      }}
    />
  )
}

const height = 6 // default
const trackHeight = `${height}px`
const progressRadius = `0px ${height / 2}px ${height / 2}px 0px`

const thumbSize = '16px' // 16px so it's easy to grab on a mobile device
const thumbColor = 'transparent'
const progressColor = colors.white
const trackColor = colors.white50

const RangeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: ${trackHeight};
  outline: none;
  position: relative;
  background: transparent;
  opacity: ${(props: any): number => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${(props: any): string => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props: any): string => (props.isDisabled ? 'wait' : 'pointer')};

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${trackHeight};
    background: ${trackColor};
    border: none;
    border-radius: 0;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${thumbSize};
    height: ${thumbSize};
    background: ${thumbColor};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -5px; /* Adjust for thumb height */
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    height: ${trackHeight};
    width: ${(props: any): number => (props.value / props.max) * 100}%;
    background: ${progressColor};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${progressRadius};
    z-index: 0;
  }

  /* Mozilla Firefox */
  &::-moz-range-track {
    width: 100%;
    height: ${trackHeight};
    background: ${trackColor};
    border-radius: 0;
  }

  &::-moz-range-progress {
    background: ${progressColor};
    height: ${trackHeight};
    border-radius: ${progressRadius};
  }

  &::-moz-range-thumb {
    width: ${thumbSize};
    height: ${thumbSize};
    background: ${thumbColor};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  /* Internet Explorer */
  &::-ms-track {
    width: 100%;
    height: ${trackHeight};
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: ${progressColor};
    border-radius: 0;
  }

  &::-ms-fill-upper {
    background: ${trackColor};
    border-radius: 0;
  }

  &::-ms-thumb {
    width: ${thumbSize};
    height: ${thumbSize};
    background: ${thumbColor};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
    position: relative;
    z-index: 1;
  }
`
