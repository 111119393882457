// @flow
import type { Dispatch } from 'redux'
import { debounce } from 'lodash'
import type { AppStateType } from '../reducers/appstate'
import { firebaseApp } from '../services/firebaseInitialize'
import {
  getRedirectResult,
  monitorAuthChangeAsync,
  remoteConfigAsync,
  setDeviceType,
  setScreenWidth
} from './userActions'
import { listenToRouterEvents } from './navigationActions'
import * as actionTypes from './actionTypes'
import { initializeSegment } from './segmentActions'

export function loadAppActionAsync (): any {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppStateType
  ): Promise<void> => {
    // set initial window size & listen for resizing
    if (window && !window.listeningResize) {
      dispatch(setScreenWidth(window.innerWidth))
      window.addEventListener(
        'resize',
        debounce((): void => {
          dispatch(setScreenWidth(window.innerWidth))
        }, 10)
      )

      // set touch listener
      const onFirstHover = (): void => {
        window.USER_HAS_MOUSE = true
        dispatch(setDeviceType(true))
        window.removeEventListener('mouseover', onFirstHover, false)
      }
      window.addEventListener('mouseover', onFirstHover, false)
      window.listeningResize = true
    }
    // setup firebase app
    firebaseApp()
    dispatch(initializeSegment())
    dispatch(listenToRouterEvents())
    dispatch(monitorAuthChangeAsync())
    dispatch(getRedirectResult())
    await dispatch(remoteConfigAsync())
    dispatch(checkUserAgent())

    dispatch({
      type: actionTypes.APP_LOADED
    })
  }
}

export function isWebView (): boolean {
  const userAgent = (window.navigator || {}).userAgent
  const rules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to lollipop webview will put {version}.0.0.0
    'Android.*(wv|.0.0.0)',
    // old chrome android webview agent
    'Linux; U; Android',
    'Instagram', // ex: Mozilla/5.0 (iPhone; CPU iPhone OS 12_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/16B92 Instagram 132.1.0.21.129 (iPhone6,1; iOS 12_1; en_US; en; scale=2.00; 640x1136; 202764138)
    'Facebook',
    'FB_IAB/FB4A;FBAV', // facebook ex: Mozilla/5.0 (Linux; Android 6.0.1; SM-G532F Build/MMB29T; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/75.0.3770.143 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/290.0.0.44.121;]
    'Snapchat'
  ]
  const webViewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')
  return !!userAgent.match(webViewRegExp)
}

export function checkUserAgent (): any {
  return (dispatch: Dispatch<any>): void => {
    const isWebview = isWebView()
    // if userAgent matches a webview, hide social auth
    if (isWebview) {
      dispatch({
        type: actionTypes.DISABLE_SOCIAL_AUTH
      })
    }
  }
}
