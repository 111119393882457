// @flow
import * as React from 'react'
import { colors } from '../colors'

export const InstagramIcon = (props: {
  color?: string,
  size?: number
}): React.Node => {
  const color = props.color || colors.sand
  const size = props.size || 30

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="instagram"
    >
      <path
        d="M14.9997 0C6.71553 0 0 6.71553 0 14.9997C0 23.2839 6.71553 30 14.9997 30C23.2839 30 30 23.2845 30 14.9997C30 6.71498 23.2845 0 14.9997 0ZM21.5248 9.60964L21.5187 9.6313L21.4954 9.6163H21.4887L21.4859 9.60853L21.4954 9.61464L21.5248 9.60964ZM21.6558 17.7445C21.6508 19.6324 20.4342 21.1611 18.6074 21.5648C18.3276 21.6231 18.0424 21.6515 17.7567 21.6497C15.9209 21.6568 14.0854 21.6577 12.25 21.6525C10.3765 21.6475 8.8428 20.427 8.43911 18.6107C8.3786 18.3155 8.34882 18.0147 8.35027 17.7134C8.33972 16.8033 8.34638 15.8932 8.34638 14.9831H8.34916C8.34916 14.0213 8.33472 13.059 8.34916 12.0956C8.38248 10.2038 9.81787 8.61847 11.6992 8.37526C11.8797 8.35305 12.0614 8.34192 12.2433 8.34194C14.0787 8.33972 15.9143 8.33972 17.75 8.34194C19.6246 8.34638 21.155 9.56355 21.5614 11.3826C21.621 11.6673 21.6498 11.9575 21.6475 12.2483C21.6571 14.0818 21.6599 15.9139 21.6558 17.7445Z"
        fill={color}
      />
      <path
        d="M20.288 11.6606C20.0658 10.4789 19.1074 9.6771 17.9003 9.67044C16.938 9.66488 15.9762 9.67044 15.0128 9.67044C14.0611 9.67044 13.1099 9.67044 12.1581 9.67044C11.9918 9.66794 11.8257 9.68058 11.6617 9.7082C10.474 9.93031 9.67269 10.8943 9.67047 12.1103C9.66714 14.0353 9.66714 15.9603 9.67047 17.8852C9.66894 18.036 9.68139 18.1865 9.70767 18.335C9.92978 19.5277 10.8971 20.3246 12.1259 20.3262C14.0394 20.3288 15.9529 20.3288 17.8664 20.3262C18.0225 20.3274 18.1784 20.3146 18.3323 20.2879C19.5139 20.0658 20.3196 19.1063 20.3229 17.9002C20.3285 15.9656 20.3229 14.031 20.3229 12.0964C20.3245 11.9504 20.3128 11.8045 20.288 11.6606ZM15.0983 18.3239C13.2981 18.4061 11.7544 16.9551 11.6717 15.1033C11.5912 13.2908 13.0393 11.75 14.9006 11.6689C16.7125 11.5906 18.2517 13.0443 18.3273 14.9056C18.4006 16.7136 16.9574 18.2417 15.0983 18.3272V18.3239ZM18.4944 12.338C18.3297 12.338 18.1686 12.2891 18.0317 12.1976C17.8947 12.1061 17.7879 11.976 17.7249 11.8238C17.6618 11.6716 17.6453 11.5042 17.6775 11.3426C17.7096 11.181 17.789 11.0326 17.9054 10.9161C18.0219 10.7996 18.1703 10.7203 18.3319 10.6882C18.4935 10.656 18.6609 10.6725 18.8131 10.7356C18.9653 10.7986 19.0954 10.9054 19.1869 11.0423C19.2785 11.1793 19.3273 11.3403 19.3273 11.5051C19.3254 11.7254 19.2371 11.9362 19.0813 12.092C18.9255 12.2477 18.7147 12.3361 18.4944 12.338V12.338Z"
        fill={color}
      />
      <path
        d="M15.002 13.0029C14.607 13.002 14.2205 13.1181 13.8913 13.3366C13.5622 13.5551 13.3052 13.8662 13.1528 14.2307C13.0004 14.5952 12.9594 14.9966 13.035 15.3844C13.1106 15.7721 13.2993 16.1288 13.5775 16.4093C13.8556 16.6899 14.2107 16.8817 14.5977 16.9606C14.9848 17.0395 15.3866 17.002 15.7524 16.8527C16.1182 16.7034 16.4315 16.4491 16.6528 16.1219C16.8742 15.7947 16.9936 15.4092 16.996 15.0141C16.9976 14.751 16.9473 14.4902 16.8478 14.2466C16.7484 14.003 16.6019 13.7814 16.4166 13.5945C16.2314 13.4077 16.011 13.2592 15.7683 13.1577C15.5255 13.0562 15.2651 13.0036 15.002 13.0029V13.0029Z"
        fill={color}
      />
      <path
        d="M21.4891 9.61792H21.4958L21.4863 9.61182L21.4891 9.61792Z"
        fill={color}
      />
      <path
        d="M21.5255 9.60938L21.4961 9.61604L21.5194 9.63103L21.5255 9.60938Z"
        fill={color}
      />
    </svg>
  )
}
