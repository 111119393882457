// @flow
import { applyMiddleware, createStore } from 'redux'
import type { Store, Action } from 'redux'
import { mainReducer } from './reducers'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import type { AppStateType } from './reducers/appstate'

const middleware = [thunk]

export function initializeStore (state?: AppStateType): Store<AppStateType, Action<any>> {
  return createStore(
    mainReducer,
    state,
    composeWithDevTools(
      applyMiddleware(...middleware)
    )
  )
}
