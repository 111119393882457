// @flow
import * as React from 'react'
import { colors } from '../colors'

export const DipseaBrandmark = (props: {
  color?: string,
  size?: number
}): React.Node => {
  const color = props.color || colors.sand
  const width = props.size || 66.99
  const height = props.size ? props.size * 1.04935235 : 64

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 61 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.9939 35.6025C60.9939 13.5465 43.3057 0 21.1588 0H0V0.21743C0 0.21743 3.47054 0.893842 3.49494 6.5528V57.5378C3.39735 63.0337 0.00606812 63.7041 0.00606812 63.7041V63.9215H13.3637V63.7041C13.3637 63.7041 8.94169 63.0216 8.94169 57.3143V50.8884C13.3089 58.6249 22.6348 64 31.7473 64C47.4471 64 60.9939 52.7485 60.9939 35.6025ZM38.9934 32.4801C38.9934 26.6219 33.8089 18.2935 26.1847 18.2935C18.3226 18.2935 13.565 25.6677 13.565 32.5103C13.565 44.7583 22.6714 55.8467 32.0218 55.8467C41.6405 55.8467 50.1065 45.7126 50.1065 35.1798C50.1065 20.3831 38.664 9.02293 24.7757 9.02293C21.7528 8.97695 18.7582 9.60439 16.0132 10.8589C13.2682 12.1133 10.8432 13.9627 8.9173 16.2703V2.02322L20.6281 1.95075C39.9693 1.95075 54.9799 14.9114 54.9799 35.7716C54.9799 51.5467 45.7881 62.2184 31.851 62.2184C18.286 62.2184 8.51475 48.7263 8.50255 32.5889V32.5103C8.50255 18.6981 16.3524 10.8227 24.7513 10.8227C37.9992 10.8227 44.9586 23.8498 44.9586 35.2039C44.9586 42.5539 40.4694 54.0772 32.1499 54.0772C23.9889 54.0772 18.0847 44.559 18.0847 32.0936C18.0847 25.6314 21.7443 20.1476 26.0627 20.1476C31.4484 20.1476 34.6018 26.9057 34.6018 32.0936"
        fill={color}
      />
    </svg>
  )
}
