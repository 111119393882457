// @flow
import * as React from 'react'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { Tween, ScrollTrigger } from 'react-gsap'
import { v4 as uuidv4 } from 'uuid'

/* Note: if using parallax for a container at the bottom of a page. ie the module before the footer.
  Use quadrants 3 | 4 to full cover the background. This is because parallax is set to 25vh
*/
type QuadrentType = 1 | 2 | 3 | 4
type OverlayColorType = 'magenta' | 'blue' | 'orange'
type PropsType = {
  loadingComplete?: () => void,
  quadrant?: QuadrentType,
  gradientPosition?: 'top' | 'bottom' | 'both',
  gradientColor?: string, // the background color of the next section
  gradientHeight?: string, // ex: 100px | 12vh | 20rem
  overlayColor?: OverlayColorType,
  parallax?: boolean
}

export default function BackgroundGradient (props: PropsType): React.Node {
  const { gradientPosition, gradientColor, gradientHeight, quadrant, loadingComplete, overlayColor, parallax } = props
  const uniqueId = uuidv4()
  const [show, setShow] = React.useState(false)

  const handleLoadingComplete = (): void => {
    if (loadingComplete) loadingComplete()
    setShow(true)
  }

  const renderImage = (): React.Node => {
    return (
      <ImageContainer quadrant={quadrant} className={show ? 'fade-in' : ''}>
        <Image
          className="background-image"
          src="/images/background-gradient.jpg"
          aria-hidden="true"
          layout='fill'
          objectFit="cover"
          objectPosition='center'
          unoptimized
          onLoadingComplete={handleLoadingComplete}
          priority
        />
        {overlayColor && <OverlayColor color={overlayColor} />}
      </ImageContainer>
    )
  }

  if (parallax) {
    return (
      <Container>
        <ScrollTrigger
          id={`marketing-section-background-${uniqueId}`}
          start="top top" // Start the animation when the top of the viewport reaches the top of the page
          end="bottom top" // End the animation when the bottom of the viewport reaches the top of the page
          scrub={true}
        >
          <Tween
            from={{ yPercent: -5 }} // Start the image from the top -5%
            to={{ yPercent: 25 }} // Move the image down by 25% of its height
          >
            {renderImage()}
          </Tween>
        </ScrollTrigger>
        {gradientPosition && (gradientPosition === 'top' || gradientPosition === 'both') && <Gradient isTop gradient={{ color: gradientColor, height: gradientHeight }} />}
        {gradientPosition && (gradientPosition === 'bottom' || gradientPosition === 'both') && <Gradient isBottom gradient={{ color: gradientColor, height: gradientHeight }} />}
      </Container>
    )
  }

  return (
    <Container>
      {renderImage()}
      {gradientPosition && (gradientPosition === 'top' || gradientPosition === 'both') && <Gradient isTop gradient={{ color: gradientColor, height: gradientHeight }} />}
      {gradientPosition && (gradientPosition === 'bottom' || gradientPosition === 'both') && <Gradient isBottom gradient={{ color: gradientColor, height: gradientHeight }} />}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
`
const ImageContainer = styled.div`
  width: 200%;
  height: 200%;
  position: absolute;
  top: ${(props: { quadrant: QuadrentType }): ?number => props.quadrant === 1 || props.quadrant === 2 ? 0 : undefined};
  right: ${(props: { quadrant: QuadrentType }): ?number => props.quadrant === 2 || props.quadrant === 4 ? 0 : undefined};
  bottom: ${(props: { quadrant: QuadrentType }): ?number => props.quadrant === 3 || props.quadrant === 4 ? 0 : undefined};
  left: ${(props: { quadrant: QuadrentType }): ?number => props.quadrant === 1 || props.quadrant === 3 ? 0 : undefined};
  opacity: 0;
  transition: opacity 1s ease-in-out; 
  &.fade-in {
    opacity: 1; 
  }
`
const OverlayColor = styled.div`
  height: 100%;
  left: 0;
  mix-blend-mode: screen;
  position: absolute;
  top: 0;
  width: 100%;
  ${(props: { color: OverlayColorType }): ?string => {
    if (props.color === 'orange') {
      return `background: #FF5C00; opacity: 1;`
    } else if (props.color === 'blue') {
      return `background: #0024A5; opacity: .58;`
    } else if (props.color === 'magenta') {
      return `background: #A5006D; opacity: .58;`
    }
  }}
`
const Gradient = styled.div`
  position: absolute;
  left:0;
  width: 100%;
  height: 500px;
  top: ${(props: { isTop: boolean }): ?number => props.isTop ? 0 : undefined};
  bottom: ${(props: { isBottom: boolean }): ?number => props.isBottom ? 0 : undefined};
  height: ${(props: { gradient: { height: ?string } }): ?string => props.gradient?.height || '180px'};
  background: ${(props: { isTop: boolean, gradient: { color: ?string } }): string => `linear-gradient(to 
    ${props.isTop ? 'top' : 'bottom'}, rgba(255,255,255,0) 0%,
    ${props.gradient?.color || colors.midnightBlue} 73%,
    ${props.gradient?.color || colors.midnightBlue} 100%)
  `};
`
