// @flow
import { getDatabase, ref, set, onValue, get, update, off, remove, push } from 'firebase/database'

import type { OnRecordChangeType } from '../flowTypes'

export function fetchOnceAsync (path: string): Promise<any> {
  return get(ref(getDatabase(), path)).then((snapshot: any): any => snapshot.val())
}

export function fetchOnAsync (path: string, callback: (snapshot: {val: () => any}) => void): Promise<any> {
  return onValue(ref(getDatabase(), path), callback)
}

export function turnFetchOff (path: string): void {
  off(ref(getDatabase(), path))
}

export function updateRecordAsync (path: string, params: any): Promise<any> {
  return update(ref(getDatabase(), path), params)
}

export function setRecordAsync (path: string, params: any): Promise<any> {
  return set(ref(getDatabase(), path), params)
}

export function deleteRecordAsync (path: string): Promise<any> {
  return remove(ref(getDatabase(), path))
}

export async function createRecordAsync (path: string, params: any): Promise<string> {
  const index: any = ref(getDatabase(), path)
  const newRecordRef: any = await push(index, params)
  return newRecordRef.key
}

export function onRecordChange (path: string, callback: (params: any)=> void): OnRecordChangeType {
  const firebaseRef: any = ref(getDatabase(), path)
  const refCallback = (snapshot: any): void => {
    const value: any = snapshot.val()
    if (value) {
      callback(value)
    }
  }
  onValue(firebaseRef, refCallback)
  return {
    off: (): void => {
      off(firebaseRef)
    }
  }
}

export function turnOffListeners (listeners: string[]): void {
  listeners.forEach((item: string): void => {
    if (window.listeners && window.listeners[item] && window.listeners[item].off) {
      window.listeners[item].off()
      delete window.listeners[item]
    }
  })
}
