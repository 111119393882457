// @flow
import * as React from 'react'
import styled from 'styled-components'

type PropsType = {
  fadeInTime?: number,
  children: React.Node
}

const FadeInDiv = styled.div`
  width: 100%;
  position: relative;
  opacity: ${(props: { loadAnimation: boolean }): number => props.loadAnimation ? 1 : 0};
  transition: ${(props: { fadeTime: number }): string => `opacity ${props.fadeTime}s ease-in-out`};
`

export default function FadeInContent (props: PropsType): React.Node {
  const [loadAnimation, setLoadAnimation] = React.useState<boolean>(false)

  React.useEffect((): void => {
    if (!loadAnimation) {
      setTimeout((): void => setLoadAnimation(true), 200)
    }
  }, [loadAnimation])

  const fadeTime = props.fadeInTime || 1.5
  return <FadeInDiv loadAnimation={loadAnimation} fadeTime={fadeTime}>
    {props.children}
  </FadeInDiv>
}
