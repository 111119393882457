// @flow
import {
  deleteRecordAsync,
  fetchOnceAsync, onRecordChange,
  setRecordAsync,
  updateRecordAsync
} from '../services/firebaseDatabase'
import { currentUser } from '../services/firebaseAuth'
import type {
  OnRecordChangeType,
  PurchaseSkuType,
  UserCategoryFollowsType,
  UserFavoritesType,
  UserListenType,
  UserType,
  QuizType,
  UserScoreCardConversionQuizResultsType,
  UserScoreCardHunkQuizType,
  UserScoreCardTropeQuizType,
  UserCancellationSurveyAnswerType
} from '../flowTypes'
import { reportError } from '../services/bugSnag'

export async function fetchUserAsync (userId: string): Promise<$Shape<UserType>> {
  try {
    const userObject: UserType = await fetchOnceAsync(`/users/${userId}`)
    const userObjClone = Object.assign({}, userObject)
    userObjClone.id = userId
    userObjClone.listens = filterUserListens(userObjClone.listens)
    return userObjClone
  } catch (error) {
    console.log('Error fetching user', { userId, error })
  }
  return {
    id: userId
  }
}

// We can create dynamic Firebase User Properties by updating the analyticsProperties on the users index
// First, create the key in the Firebase User Properties
// Then, apply that key to the user index analyticsProperties
// When the app loads or the user/uid record changes, the user's properties will change as well
// This means we can micro target people for notifications and other types of tests

// export function updateUserPropertiesAsync (user: UserType): void {
//   try {
//     if (user && user.analyticsProperties) {
//       Object.keys(user.analyticsProperties).forEach((key: string): void => {
//         if (user.analyticsProperties) { // flow thing
//           setUserAnalyticsPropertyAsync(key, user.analyticsProperties[key].toString())
//         }
//       })
//     }
//   } catch (error) {
//     console.log('Error setting user properties', { error })
//   }
// }

export async function updateSingleUserPropertyAsync (key: string, value: string | boolean | number): Promise<void> {
  try {
    const user = currentUser()
    if (!user) {
      return
    }
    await setRecordAsync(`users/${user.id}/analyticsProperties/${key}`, value)
  } catch (error) {
    console.log('Error setting user record analytics property', { error })
  }
}

export async function userSaveSurveyAnswer (answer: string, now: number): Promise<void> {
  try {
    const user = currentUser()
    if (!user) {
      return
    }
    await updateRecordAsync(`users/${user.id}`, {
      survey_answer: answer,
      survey_dismissed_at: now
    })
  } catch (error) {
    console.log('Error saving survey answer', { error })
  }
}

export async function userDismissSurvey (now: number): Promise<void> {
  try {
    const user = currentUser()
    if (!user) {
      return
    }
    await setRecordAsync(`users/${user.id}/dismissed_survey_at/${now}`)
  } catch (error) {
    console.log('Error dismissing survey', { error })
  }
}

export function userChange (callback: (user: $Shape<UserType>) => void): ?OnRecordChangeType {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }

  return onRecordChange(`/users/${user.id}`, (userObject: $Shape<UserType>): void => {
    if (userObject) {
      userObject.id = user.id
      // updateUserPropertiesAsync(userObject)
      if (user.providers) {
        userObject.providers = user.providers
      }
      userObject.listens = filterUserListens(userObject.listens)
      callback(userObject)
    } else {
      const blankUser: $Shape<UserType> = {
        id: user.id
      }
      callback(blankUser)
    }
  })
}

export async function newUserIsReferredAsync (): Promise<any> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  return updateRecordAsync(`/users/${user.id}/referred`, {
    isReferred: true
  })
}

export async function userNudgedForRatingAsync (date?: number): Promise<any> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return Promise.resolve()
  }
  return setRecordAsync(`/users/${user.id}/userNudgedForRating`, date || Date.now())
}

export async function getCurrentUserAsync (): Promise<UserType> {
  const user = currentUser()
  if (!user) {
    return Promise.resolve({})
  } else {
    const dbUser = await fetchUserAsync(user.id)
    if (user.providers) {
      dbUser.providers = user.providers
    }
    return dbUser
  }
}

export function userRatedAppAsync (): Promise<any> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return Promise.resolve()
  }
  return setRecordAsync(`/users/${user.id}/userRatedApp`, Date.now())
}

export async function enableNotificationsForUserAsync (fcmToken?: string, apnsToken: ?string): Promise<void> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  const params: $Shape<UserType> = {
    notifications_enabled: true,
    notification_dismissed_at: null
  }
  if (fcmToken) {
    params.fcm_token = fcmToken
  } else {
    throw new Error('No FCM token')
  }

  if (apnsToken) {
    params.apns_token = apnsToken
  }

  await updateRecordAsync(`/users/${user.id}`, params)
  await updateSingleUserPropertyAsync('notifications_enabled', true)
}

export async function updateTokensForUserAsync (fcmToken: string, apnsToken: ?string): Promise<void> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  if (fcmToken && apnsToken) {
    const params: $Shape<UserType> = {
      fcm_token: fcmToken,
      apns_token: apnsToken
    }
    await updateRecordAsync(`/users/${user.id}`, params)
  }
}

export async function dismissNotificationPromptForUserAsync (): Promise<void> {
  const user: ?UserType = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  const params: $Shape<UserType> = {
    notifications_enabled: false,
    notification_dismissed_at: Date.now()
  }

  await updateRecordAsync(`/users/${user.id}`, params)
  await updateSingleUserPropertyAsync('notifications_enabled', false)
}

export async function subscribeUserAsync (entitlement: PurchaseSkuType): Promise<boolean> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return false
  }

  const params: $Shape<UserType> = {
    entitlements: entitlement,
    isSubscribed: true,
    subscription_type: 'stripe',
    subscription_expires_at: undefined
  }
  await updateRecordAsync(`/users/${user.id}`, params)
  return true
}

export async function dismissSubscriptionPromptForUserAsync (date?: number): Promise<void> {
  const user: ?UserType = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  const params: $Shape<UserType> = {
    user_dismissed_paywall_at: date || Date.now()
  }
  await updateRecordAsync(`/users/${user.id}`, params)
}

export async function fetchUserFavoritesAsync (): Promise<UserFavoritesType> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return {}
  }
  const userFavorites: UserFavoritesType = await fetchOnceAsync(`favorites/${user.id}`)
  return userFavorites
}

export async function fetchUserCategoryFollowsAsync (): Promise<UserCategoryFollowsType> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return {}
  }
  const userCategoryFollows: UserCategoryFollowsType = await fetchOnceAsync(`categoryFollows/${user.id}`)
  return userCategoryFollows
}

export async function toggleUserFavoriteAsync (trackId: string, isFavorited: boolean): Promise<number | typeof undefined> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  if (isFavorited) {
    const date: number = Date.now()
    await setRecordAsync(`favorites/${user.id}/${trackId}/favorited_at`, date)
    return date
  } else {
    await deleteRecordAsync(`favorites/${user.id}/${trackId}`)
  }
}

export async function dismissUserFirstCategoryFollowModal (timestamp: number): Promise<void> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  return setRecordAsync(`users/${user.id}/dismissed_first_category_follow_modal_at`, timestamp)
}

export async function setUserFirstCategoryFollow (timestamp: number): Promise<void> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  return setRecordAsync(`users/${user.id}/first_category_follow_at`, timestamp)
}

export async function toggleUserFollowCategoryAsync (categoryId: string, isFollowed: boolean, now: number): Promise<number | typeof undefined> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  if (isFollowed) {
    await setRecordAsync(`categoryFollows/${user.id}/${categoryId}/followed_at`, now)
    return now
  } else {
    await deleteRecordAsync(`categoryFollows/${user.id}/${categoryId}`)
  }
}

export function addTrackToFavoritesAsync (trackId: string): Promise<any> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return Promise.resolve({})
  }

  const favoritesObject = {
    favorited_at: Date.now()
  }

  return setRecordAsync(`favorites/${user.id}/${trackId}`, favoritesObject)
}

export type GlobalListenUpdateType = {
  trackId: string,
  secondsSinceLastUpdated: number
}

export async function updateUserListenAsync (trackId: string, position: number, duration: number): Promise<void> {
  // don't track previews
  if (duration < 60) {
    return
  }
  const user: ?UserType = currentUser()
  if (!user || user.isAnonymous) {
    return
  }
  let listen: UserListenType = await fetchOnceAsync(`users/${user.id}/listens/${trackId}`)

  if (!listen) {
    listen = {}
    listen.firstListenTimestamp = Date.now()
    listen.furthest_position = Math.round(position)
  } else if (position > listen.furthest_position) {
    listen.furthest_position = Math.round(position)
  }

  listen.position = Math.round(position)
  listen.timestamp = Date.now()
  if (!listen.completed && position / duration > 0.9) {
    listen.completed = Date.now()
  }
  if (!listen) {
    await setRecordAsync(`users/${user.id}/listens/${trackId}`, listen)
  } else {
    await updateRecordAsync(`users/${user.id}/listens/${trackId}`, listen)
  }
}

export function updateEmailAndAgeAsync (email: string): Promise<any> {
  const user = currentUser()
  if (!user) {
    return Promise.resolve()
  }
  return updateRecordAsync(`users/${user.id}`, { email, ageVerified: Date.now() })
}

export async function updateAgeAsync (): Promise<any> {
  try {
    const user = currentUser()
    if (!user) {
      return Promise.resolve()
    }
    return setRecordAsync(`users/${user.id}/ageVerified`, Date.now())
  } catch (e) {
    reportError(e)
  }
  return Promise.resolve()
}

export function updateEmailAsync (email: string): Promise<any> {
  const user = currentUser()
  if (!user) {
    return Promise.resolve()
  }
  return updateRecordAsync(`users/${user.id}`, { email })
}

export function deleteAccountAsync (): Promise<any> {
  const user = currentUser()
  if (!user) {
    return Promise.resolve()
  }
  return updateRecordAsync(`users/${user.id}`, { deleteAt: Date.now() })
}

export async function addReferredBySuccessAsync (referId: string): Promise<void> {
  try {
    const user = currentUser()
    if (!user || !referId) {
      return Promise.resolve()
    }
    await updateRecordAsync(`users/${user.id}/referredBy`, {
      id: referId,
      subscribed: true
    })
  } catch (error) {
    console.log('Error setting referredBy', error)
  }
}

export async function addReferredByFirstViewedAsync (referId: string): Promise<void> {
  try {
    const user = currentUser()
    if (!user) {
      return Promise.resolve()
    }
    await updateRecordAsync(`users/${user.id}/referredBy`, {
      id: referId,
      first_viewed_30_day_trial: Date.now()
    })
  } catch (error) {
    console.log('Error setting referredBy', error)
  }
}

export async function createUserReferIdAsync (referId: string): Promise<void> {
  try {
    const user = currentUser()
    if (!user || !referId) {
      return Promise.resolve()
    }
    await updateRecordAsync(`/referrals/${referId}`, {
      userId: user.id
    })
  } catch (error) {
    console.log('Error setting referredBy', error)
  }
}

export async function addReferViewedModalAsync (referId: string): Promise<void> {
  try {
    const user = currentUser()
    if (!user || !referId) {
      return Promise.resolve()
    }
    await updateRecordAsync(`/referrals/${referId}`, {
      viewedModal: true
    })
  } catch (error) {
    console.log('Error setting viewedModal to true', { error })
  }
}

export function userSubscriptionExpiring (user?: UserType): boolean {
  if (!user || !user.isSubscribed) {
    return false
  } else if (user.subscription_expires_at && user.subscription_expires_at < Date.now() + 60 * 24 * 60 * 60 * 1000) {
    return true
  }
  return false
}

export async function syncUserEmailAsync (userId: string, email: string): Promise<void> {
  try {
    await updateRecordAsync(`/users/${userId}`, {
      email
    })
  } catch (error) {
    console.log('Error syncing user email', { error })
  }
}

export function filterUserListens (listens?: {[key: string]: UserListenType }): {[key: string]: UserListenType } {
  const newListenObject: {[key: string]: UserListenType } = {}
  if (!listens) {
    return {}
  }
  Object.keys(listens || {}).filter((listenKey: string): boolean => {
    // if they have listened less than 30 seconds or the timestamp on that listen is less than five minutes
    return listens[listenKey].furthest_position >= 30 || listens[listenKey].position >= 30 || listens[listenKey].timestamp >= Date.now() - 60 * 1000
  }).forEach((listenKey: string): void => {
    newListenObject[listenKey] = listens[listenKey]
  })
  return newListenObject
}

export function userTrackListenCompleteAsync (trackId: string): Promise<any> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return Promise.resolve()
  }
  return setRecordAsync(`/users/${user.id}/listens/${trackId}/completed`, Date.now())
}

export function saveConversionDataToScoreCard (id: string, name: string, genderPreference: string, quizType: QuizType, results: UserScoreCardConversionQuizResultsType): Promise<any> {
  const user = currentUser()
  if (!user || user.isAnonymous) {
    return Promise.resolve()
  }

  if (quizType === 'trope-quiz' || quizType === 'fantasy-quiz') {
    const tropeParams: UserScoreCardTropeQuizType = {
      series_id: id,
      series_name: name,
      sexual_preference_answer: genderPreference,
      timestamp: Date.now(),
      results
    }
    return setRecordAsync(`/scoreCards/${user.id}/tropeQuiz`, tropeParams)
  }

  const hunkParams: UserScoreCardHunkQuizType = {
    hunk_id: id,
    hunk_name: name,
    sexual_preference_answer: genderPreference,
    timestamp: Date.now(),
    results
  }
  return setRecordAsync(`/scoreCards/${user.id}/conversionQuiz`, hunkParams)
}

export async function userSaveCancellationSurveyAnswer (params: UserCancellationSurveyAnswerType): Promise<void> {
  try {
    const user = currentUser()
    if (!user) {
      return Promise.resolve()
    }

    await updateRecordAsync(`users/${user.id}/cancellation_survey`, params)
  } catch (error) {
    console.log('Error syncing user email', { error })
  }
}

export async function saveTikTokCreatorPromoToScoreCard (creatorDisplayName: string): Promise<void> {
  try {
    const user = currentUser()
    if (!user || user.isAnonymous) {
      return Promise.resolve()
    }
    return setRecordAsync(`/scoreCards/${user.id}/tikTokCreatorPromo`, creatorDisplayName)
  } catch (error) {
    console.log('Error saveTikTokCreatorPromoToScoreCard', { error })
  }
}

export function removeStorageItems (): void {
  try {
    window.localStorage.removeItem(`dipsea_age_status`)
    window.localStorage.removeItem(`dipsea_age_date`)
    window.localStorage.removeItem(`dipsea_download_banner`)
  } catch (e) {}
}
