// @flow
import * as React from 'react'
import { contentfulLoader } from '../../services/images'
import Image from 'next/legacy/image'
import styled from 'styled-components'

type PropsType = {
  fadeInTime?: number,
  src: string,
  width?: number,
  height?: number,
  onLoadingComplete?: () => void,
  alt?: string,
  layout?: 'fill' | 'intrinsic' | 'responsive' | 'fixed',
  ariaHidden?: boolean,
  loading?: 'eager',
  priority?: boolean,
  styles?: {},
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' // Defines how the image will fit into its parent container when using layout="fill".
}

const FadeInDiv = styled.div`
  display: flex;
  height: 100%;
  opacity: ${(props: { imageLoaded: boolean }): number => props.imageLoaded ? 1 : 0};
  position: relative;
  transition: ${(props: { fadeTime: number }): string => `opacity ${props.fadeTime}s ease-in-out`};
  width: 100%;
  ${(props: any): any => props.styles}
`

export function FadeInImage (props: PropsType): React.Node {
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false)
  const fadeTime = props.fadeInTime || 0.5

  const dimensions = {}
  if (props.width) {
    dimensions.width = props.width
  }
  if (props.height) {
    dimensions.height = props.height
  }

  return (
    <FadeInDiv
      imageLoaded={imageLoaded}
      fadeTime={fadeTime}
      styles={props.styles}
    >
      <Image
        loader={contentfulLoader}
        aria-hidden={!!props.ariaHidden}
        className={'fade-in-image'}
        src={props.src}
        alt={props.alt || 'dipsea image'}
        layout={props.layout}
        onLoad={(): void => {
          setImageLoaded(true)
          if (props.onLoadingComplete) {
            props.onLoadingComplete()
          }
        }}
        loading={props.loading}
        priority={props.priority}
        objectFit={props.objectFit}
        {...dimensions}
      />
    </FadeInDiv>
  )
}
