// @flow
import type { UserType } from '../flowTypes'

export function updateIntercom (user: UserType): void {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('update', {
      email: user.email,
      user_id: user.id,
      hide_default_launcher: false
    })
  }
}

export function dismissIntercom (): void {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true
    })
  }
}

export function showIntercom (): void {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: false
    })
  }
}
