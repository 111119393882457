// @flow

import { createGlobalStyle } from 'styled-components'
import type { ColorsType } from './colors'
import { colors } from './colors'

type GlobalStyleType = {
  backgroundColor: ColorsType,
  styles?: string
}

export const GlobalStyle = createGlobalStyle`
  body {
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    background-color: ${(props: GlobalStyleType): string => (props.backgroundColor || colors.orange)};
    ${(props: GlobalStyleType): ?string => props.styles}
  }
  *::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
`
