// @flow
import * as React from 'react'
import {
  colors,
  CTAPrimary
} from '@components/shared'
import { config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import Modal from '@components/shared/Modal'
import { H1, P } from '@components/shared/typography'
import { useDispatch, useSelector } from 'react-redux'
import BackgroundGradient from '@components/BackgroundGradient'
import { openSubscribe } from '../../actions/navigationActions'
import { events } from '@services/analytics'
import type { AppStateType } from '@reducers/appstate'

type PropsType = {
  isVisible: boolean,
  handleClose: () => void
}
export default function TrackPreviewModal (props: PropsType): React.Node {
  const dispatch = useDispatch()

  const blogEmailCaptureText = {
    header: 'The story has just begun…',
    body: 'Listen to the rest of this audiobook and more!'
  }
  const subscribeCta = useSelector<string>((state: AppStateType): string => state.subscriptionCTA)

  const { header, body } = blogEmailCaptureText

  const handleClose = (): void => {
    props.handleClose()
  }

  const openSubscribePage = React.useCallback((): void => {
    props.handleClose()
    events.cta_button_clicked({ cta_name: subscribeCta, url: '/subscribe?f=2&promo=try30d', type: 'previewPlay' })
    dispatch(openSubscribe('try30d'))
  })

  return (
    <PageContainer>
      <Modal
        isVisible={props.isVisible}
        toggleModalFunction={
          handleClose
        }
        modalCardBackgroundColor={colors.cobalt}
        disableFullScreenMobile
        overflow='unset'
      >
        <BackgroundGradient overlayColor='blue' quadrant={3} />
        <Card>
            <TextContainer>
              <Header id={'preview-modal-header'}>{header}</Header>
              <Body id={'preview-modal-body'}>{body}</Body>
              <CTAPrimary className={'subscribe-cta'} onClick={openSubscribePage}>{subscribeCta}</CTAPrimary>
            </TextContainer>
        </Card>
      </Modal>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  .modal-window {
    border-radius: 10px;
    max-width: 511px;
    ${(props: any): string => config(props).media.md`
      max-width: 594px;
    `}
  }
`
const Header = styled(H1)`
  color: ${colors.white};
  font-size: 45px;
  line-height: 54px;
  margin: 0 0 16px;
  padding: 0;
  text-align: center;
  ${(props: any): string => config(props).media.md`
    white-space: pre-wrap
  `}
`
const Body = styled(P)`
  color: ${colors.white};
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  margin: 0 0 30px;
  padding: 0;
  text-align: center;
`
const Card = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 56px 20px 38px;
  width: 100%;
  position: relative;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 332px;
  ${(props: any): string => config(props).media.md`
    max-width: 439px;
  `}
`
