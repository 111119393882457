// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { config, Container, Row, Col } from 'react-awesome-styled-grid'
import { useDispatch, useSelector } from 'react-redux'
import type { AppStateType } from '@reducers/appstate'
import type { PlayType } from '@services/audio'
import { FadeInImage } from '@components/shared/FadeInImage'
import { useGlobalAudioPlayer } from 'react-use-audio-player'
import FadeInContent from '@components/shared/FadeInContent'
import { clearAudio } from '../../actions/audioActions'
import Seek from '@components/Miniplayer/Seek'
import PlayIcon from '@components/shared/icons/PlayIcon'
import PauseIcon from '@components/shared/icons/PauseIcon'
import { DiscoverBottomMobileNav, NAV_BAR_HEIGHT } from '@components/shared/DiscoverMobileBottomNav'
import { FavoriteButton } from '@components/Miniplayer/FavoriteButton'
import { useRouter } from 'next/router'
import { updateUserListenAsync } from '../../models/User'
import { dismissIntercom } from '@services/intercom'
import { events } from '@services/analytics'
import TrackPreviewModal from '@components/Miniplayer/TrackPreviewModal'

const MiniPlayerComponent = (props: PlayType): React.Node => {
  const {
    load,
    getPosition,
    duration,
    seek,
    togglePlayPause
  } = useGlobalAudioPlayer()
  const [pos, setPos] = React.useState(0)
  const [playState, setPlayState] = React.useState<'play' | 'paused'>('paused')
  const [previewModalVis, setPreviewModalVis] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const router = useRouter()
  const isSubscribed: boolean = useSelector((state: AppStateType): boolean => !!state.user?.isSubscribed)
  const removeMiniPlayer = (): void => {
    setTimeout((): void => {
      dispatch(clearAudio())
    }, 7000)
  }

  const removeMiniPlayerPause = (): void => {
    setPlayState('paused')
    setTimeout((): void => {
      dispatch(clearAudio())
    }, 20000)
  }

  const removeIntercom = (): void => {
    dismissIntercom()
    setPlayState('play')
  }
  React.useEffect((): void => {
    load(props.audioUrl, {
      autoplay: true,
      onend: (): void => removeMiniPlayer(),
      onplay: (): void => removeIntercom(),
      onpause: (): void => removeMiniPlayerPause()
    })
  }, [props.audioUrl])

  React.useEffect((): any => {
    let trigger = 30
    const i = setInterval((): void => {
      const position = getPosition()
      if (trigger === 0) {
        trigger = 30
        if (playState === 'play' && !props.audioUrl.includes('preview')) {
          updateUserListenAsync(props.id, position, props.duration)
          if (position >= (duration * 0.90)) {
            events.track_completed(props.id)
          }
        }
      }
      if (playState === 'play' && props.audioUrl.includes('preview')) {
        if (Math.round(position) === Math.round(duration / 2) && !isSubscribed) {
          setPreviewModalVis(true)
          events.modal_shown('previewPlay', props.id)
        }
      }
      trigger -= 1
      setPos(position)
    }, 1000)

    return (): void => clearInterval(i)
  }, [getPosition, playState])

  const handleClose = React.useCallback((): void => {
    setPreviewModalVis(false)
    events.modal_dismissed('previewPlay')
  })

  return (
    <>
    <MiniPlayerContainer>
      <FadeInContent fadeInTime={2}>
        <SeekContainer>
          <Seek position={pos} duration={duration} togglePlayState={togglePlayPause} seek={seek} isDisabled={false}/>
        </SeekContainer>
        <PlayerInfoContainer>
          <Row>
            <Col justify={'center'} xs={3} sm={6} lg={7}>
              <MiniPlayerInfoContainer onClick={(): void => router.push(props.slug)}>
                <MiniPlayerImageContainer><FadeInImage src={props.imageUrl} width={100}
                                                       height={100}/></MiniPlayerImageContainer>
                <MiniPlayerText>
                  <TitleText>{props.title}</TitleText>
                  <DescriptionText>{props.description}</DescriptionText>
                </MiniPlayerText>
              </MiniPlayerInfoContainer>
            </Col>
            <Col justify={'center'} xs={1} sm={2} lg={5}>
              <MiniPlayerActionsContainer>
                <FavoriteButtonContainer>
                  <FavoriteButton trackId={props.id} size={30}/>
                </FavoriteButtonContainer>
                <div onClick={togglePlayPause}>{playState === 'paused'
                  ? <PlayIcon size={34}/>
                  : <PauseIcon size={34}/>}</div>
              </MiniPlayerActionsContainer>
            </Col>
          </Row>
        </PlayerInfoContainer>
      </FadeInContent>
      <DiscoverBottomMobileNav isMiniPlayer={true}/>
    </MiniPlayerContainer>
    <TrackPreviewModal isVisible={previewModalVis} handleClose={handleClose}/>
    </>
  )
}

const MiniPlayer = React.memo(MiniPlayerComponent)

export function MiniPlayerHOC (): React.Node {
  const currentlyPlaying = useSelector((state: AppStateType): ?PlayType => state.currentPlaying)
  if (!currentlyPlaying) {
    return null
  }

  return <MiniPlayer {...currentlyPlaying} />
}

export default MiniPlayerHOC
const PlayerInfoContainer = styled(Container)`
    margin-top: 16px;
    margin-bottom: 16px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const MiniPlayerInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const FavoriteButtonContainer = styled.div`
    display: none;
    ${(props: any): string => config(props).media.sm`
      display: block;
      margin-right: 16px;
  `}
`
const MiniPlayerActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const MiniPlayerImageContainer = styled.div`
    width: 60px;
    height: 60px;
    margin-right: 8px;

    img {
        border-radius: 10px;
    }

    flex-shrink: 0;
`
const MiniPlayerText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const SeekContainer = styled.div`
    position: absolute;
    height: 15px;
    width: 100vw;
    top: -8px;
    margin-bottom: 8px;

    @supports (-moz-appearance:none) {
        top: -5.5px;
    }
`
const DescriptionText = styled.div`
    font-family: "Good Sans Regular";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: ${colors.white};
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
    font-size: 12px;
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const TitleText = styled.div`
    font-family: "Good Sans Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.3px;
    color: ${colors.white};
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
      font-size: 16px;
    `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const MiniPlayerContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    left: 0;
    bottom: 0px;
    width: 100vw;
    height: ${NAV_BAR_HEIGHT + 90}px;
    margin: 0px;
    padding: 0px;
    background-color: ${colors.midnightBlue70};
    backdrop-filter: blur(10px);
    z-index: 10;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
    height: ${NAV_BAR_HEIGHT + 90}px;
  `}
    ${(props: any): string => config(props).media.md`
    bottom: 0;
    height: 90px;
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
