// @flow
import { getRemoteConfig, fetchAndActivate, getAll, getString } from 'firebase/remote-config'
import type { AppStateType } from '../reducers/appstate'
import { isDev, isStaging } from '../env'

export function remoteConfig (): any {
  if (!window.remoteConfig) {
    try {
      window.remoteConfig = getRemoteConfig()
      window.remoteConfig.settings = {
        minimumFetchIntervalMillis: 3600000
      }
    } catch (e) {
      console.error(e)
      return null
    }
  }
  return window.remoteConfig
}

export function formatValue (value: string): any {
  try {
    if (typeof value === 'string' && value === 'true') {
      return true
    } else if (typeof value === 'string' && value === 'false') {
      return false
    } else if ((typeof value === 'string')) {
      return JSON.parse(value)
    } else {
      return value
    }
  } catch (error) {
    return value
  }
}

export async function fetchRemoteConfigAsync (): Promise<any> {
  try {
    const rConfig = remoteConfig()
    if (rConfig) {
      return fetchAndActivate(rConfig).then((): any => {
        const config = getAll(rConfig)
        const keys = Object.keys(config)
        const remoteConfigState: $Shape<AppStateType> = {}
        keys.forEach((key: string): void => {
          if (!['textCurrencySymbol', 'textAnnualYearlyPrice', 'textAnnualYearlyPrice', 'textAnnualPrice', 'textCurrencyISO', 'textAnnualSubheader', 'textMonthlyPrice'].includes(key)) { // we want stripe prices quoted in US dollars
            remoteConfigState[key] = formatValue(config[key]._value)
          }
        })
        return remoteConfigState
      }).catch((error: any): void => {
        if (isDev || isStaging()) {
          console.error({ error })
        }
      })
    }
  } catch (error) {

  }
}

export function getRemoteConfigString (key: string): ?string {
  try {
    const rConfig = remoteConfig()
    if (rConfig) {
      return getString(rConfig, key)
    }
  } catch (e) {}
}
