// @flow

import type { UserFavoritesType } from '../flowTypes'
import { deleteRecordAsync, fetchOnceAsync, setRecordAsync } from '../services/firebaseDatabase'
import { currentUser } from '../services/firebaseAuth'
import type { TrackPropsType } from './Track'
import { getLibrary } from '../services/dipseaApi'
import { convertTrackToProps } from './Track'
export async function getFavoritesAsync (): Promise<UserFavoritesType> {
  const user = currentUser()
  if (user) {
    const favorites = await fetchOnceAsync(`/favorites/${user.id}`)
    return favorites || {}
  }
  return {}
}

export async function getFavoritesPropsAsync (): Promise<TrackPropsType[]> {
  const [favorites, library] = await Promise.all([getFavoritesAsync(), getLibrary()])
  const tracks = Object.keys(favorites || {})
    .sort((a: string, b: string): number => favorites[b].favorited_at - favorites[a].favorited_at)
    .map((id: string): TrackPropsType => convertTrackToProps(library.tracks[id], library))
    .filter((track: TrackPropsType | null): boolean => !!track)
  return tracks
}

export async function toggleFavoriteAsync (trackId: string): Promise<void> {
  const user = currentUser()
  if (user) {
    const favorites = await fetchOnceAsync(`/favorites/${user.id}/${trackId}`)
    if (favorites) {
      return deleteRecordAsync(`/favorites/${user.id}/${trackId}`)
    } else {
      return setRecordAsync(`/favorites/${user.id}/${trackId}`, { favorited_at: Date.now() })
    }
  }
}

export async function isFavoriteAsync (trackId: string): Promise<boolean> {
  const user = currentUser()
  if (user) {
    const favorites = await fetchOnceAsync(`/favorites/${user.id}/${trackId}`)
    return !!favorites
  }
  return false
}
