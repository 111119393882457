// @flow
import type {
  CategoriesObjectType,
  SerialsObjectType,
  TagsObjectType,
  TagToTrackType,
  TracksObjectType,
  TrackType,
  UserFavoritesType,
  UserType,
  TokenType,
  RecommendationType,
  FilterTagsType,
  SlugsType,
  UserStripeUpgradeType,
  PromosType,
  ProvidersType,
  AdminEnumType,
  RequestStatusType,
  StripePurchaseCardType,
  AlgoTestAssignmentsType,
  CancelFlowType, RefundObjectType
} from '../flowTypes'
import type { AppStateType } from '../reducers/appstate'
import type { SearchFilterType } from '../models/Search'
import type { DiscoverPageRowType } from '../models/Discover'

export const APP_LOADED = 'APP_LOADED'

export type AppLoadedType = {
  type: typeof APP_LOADED
}

export const SERVER_SIDE_CONTENT_FETCH = 'SERVER_SIDE_CONTENT_FETCH'
export type ServerSideContentFetchType = {
  type: typeof SERVER_SIDE_CONTENT_FETCH
}

export const SERVER_SIDE_CONTENT_FETCH_SUCCESS = 'SERVER_SIDE_CONTENT_FETCH_SUCCESS'
export type ServerSideContentFetchSuccessType = {
  type: typeof SERVER_SIDE_CONTENT_FETCH_SUCCESS
}

export const SERVER_SIDE_CONTENT_FETCH_FAIL = 'SERVER_SIDE_CONTENT_FETCH_FAIL'
export type ServerSideContentFetchFailType = {
  type: typeof SERVER_SIDE_CONTENT_FETCH_FAIL
}

export const SERVER_SIDE_FETCH_SLUG_SUCCESS = 'SERVER_SIDE_FETCH_SLUG_SUCCESS'
export type ServerSideSlugFetchSuccessType = {
  type: typeof SERVER_SIDE_FETCH_SLUG_SUCCESS,
  slugs: SlugsType
}

export const COOKIE_SESSION_PRESENT = 'COOKIE_SESSION_PRESENT'
export type CookieSessionPresentType = {
  type: typeof COOKIE_SESSION_PRESENT
}

export const CATEGORIES_FETCH = 'CATEGORIES_FETCH'
export type CategoriesFetchType = {
  type: typeof CATEGORIES_FETCH
}

export const CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS'
export type CategoriesFetchSuccessType = {
  type: typeof CATEGORIES_FETCH_SUCCESS,
  categories: CategoriesObjectType
}

export const CATEGORIES_FETCH_FAIL = 'CATEGORIES_FETCH_FAIL'
export type CategoriesFetchFailType = {
  type: typeof CATEGORIES_FETCH_FAIL
}

export const EDITORIAL_CONTENT_FETCH = 'EDITORIAL_CONTENT_FETCH'
export type EditorialContentFetchType = {
  type: typeof EDITORIAL_CONTENT_FETCH
}

export const EDITORIAL_CONTENT_FETCH_SUCCESS = 'EDITORIAL_CONTENT_FETCH_SUCCESS'
export type EditorialContentFetchSuccessType = {
  type: typeof EDITORIAL_CONTENT_FETCH_SUCCESS,
  editorialContent: string[]
}

export const EDITORIAL_CONTENT_FETCH_FAIL = 'EDITORIAL_CONTENT_FETCH_FAIL'
export type EditorialContentFetchFailType = {
  type: typeof EDITORIAL_CONTENT_FETCH_FAIL
}

export const USER_EMAIL_UPDATE_SUCCESS = 'USER_EMAIL_UPDATE_SUCCESS'
export type UserEmailUpdateSuccessType = {
  type: typeof USER_EMAIL_UPDATE_SUCCESS
}

export const USER_EMAIL_UPDATE_RESET = 'USER_EMAIL_UPDATE_RESET'
export type UserEmailUpdateResetType = {
  type: typeof USER_EMAIL_UPDATE_RESET
}

export const USER_EMAIL_UPDATE_FAIL = 'USER_EMAIL_UPDATE_FAIL'
export type UserEmailUpdateFailType = {
  type: typeof USER_EMAIL_UPDATE_FAIL
}

export const USER_PASSWORD_UPDATE_SUCCESS = 'USER_PASSWORD_UPDATE_SUCCESS'
export type UserPasswordUpdateSuccessType = {
  type: typeof USER_PASSWORD_UPDATE_SUCCESS
}

export const USER_PASSWORD_UPDATE_FAIL = 'USER_PASSWORD_UPDATE_FAIL'
export type UserPasswordUpdateFailType = {
  type: typeof USER_PASSWORD_UPDATE_FAIL
}

export const TRACKS_FETCH = 'TRACKS_FETCH'

export type TracksFetchType = {
  type: typeof TRACKS_FETCH
}

export const TRACKS_FETCH_SUCCESS = 'TRACKS_FETCH_SUCCESS'

export type TracksFetchSuccessType = {
  type: typeof TRACKS_FETCH_SUCCESS,
  tracks: TracksObjectType
}

export const TRACKS_FETCH_FAIL = 'TRACKS_FETCH_FAIL'

export type TracksFetchFailType = {
  type: typeof TRACKS_FETCH_FAIL
}

export const TRACK_FETCH = 'TRACK_FETCH'

export type TrackFetchType = {
  type: typeof TRACK_FETCH,
  trackId: string
}

export const TRACK_FETCH_SUCCESS = 'TRACK_FETCH_SUCCESS'

export type TrackFetchSuccessType = {
  type: typeof TRACK_FETCH_SUCCESS,
  trackId: string,
  track: TrackType
}

export const TRACK_FETCH_FAIL = 'TRACK_FETCH_FAIL'

export type TrackFetchFailType = {
  type: typeof TRACKS_FETCH_FAIL,
  trackId: string
}

export const TAGS_FETCH = 'TAGS_FETCH'
export type TagsFetchType = {
  type: typeof TAGS_FETCH
}

export const TAGS_FETCH_SUCCESS = 'TAGS_FETCH_SUCCESS'
export type TagsFetchSuccessType = {
  type: typeof TAGS_FETCH_SUCCESS,
  tags: TagsObjectType
}

export const TAGS_FETCH_FAIL = 'TAGS_FETCH_FAIL'
export type TagsFetchFailType = {
  type: typeof TAGS_FETCH_FAIL
}

export const FILTER_TAGS_FETCH = 'FILTER_TAGS_FETCH'
export type FilterTagsFetchType = {
  type: typeof FILTER_TAGS_FETCH
}

export const FILTER_TAGS_FETCH_SUCCESS = 'FILTER_TAGS_FETCH_SUCCESS'
export type FilterTagsFetchSuccessType = {
  type: typeof FILTER_TAGS_FETCH_SUCCESS,
  filterTags: FilterTagsType
}

export const FILTER_TAGS_FETCH_FAIL = 'FILTER_TAGS_FETCH_FAIL'
export type FilterTagsFetchFailType = {
  type: typeof FILTER_TAGS_FETCH_FAIL
}

export const TAG_TO_TRACKS_FETCH = 'TAG_TO_TRACKS_FETCH'
export type TagToTracksFetchType = {
  type: typeof TAG_TO_TRACKS_FETCH
}

export const TAG_TO_TRACKS_FETCH_SUCCESS = 'TAG_TO_TRACKS_FETCH_SUCCESS'
export type TagToTracksFetchSuccessType = {
  type: typeof TAG_TO_TRACKS_FETCH_SUCCESS,
  tagToTracks: TagToTrackType
}

export const TAG_TO_TRACKS_FETCH_FAIL = 'TAG_TO_TRACKS_FETCH_FAIL'
export type TagToTracksFetchFailType = {
  type: typeof TAG_TO_TRACKS_FETCH_FAIL
}

export const SERIAL_FETCH = 'SERIAL_FETCH'
export type SerialFetchType = {
  type: typeof SERIAL_FETCH
}

export const SERIAL_FETCH_SUCCESS = 'SERIAL_FETCH_SUCCESS'
export type SerialFetchSuccessType = {
  type: typeof SERIAL_FETCH_SUCCESS,
  serials: SerialsObjectType
}

export const SERIAL_FETCH_FAIL = 'SERIAL_FETCH_FAIL'
export type SerialFetchFailType = {
  type: typeof SERIAL_FETCH_FAIL
}

export const FEATURED_TRACK_FETCH = 'FEATURED_TRACK_FETCH'
export type FeaturedTrackFetchType = {
  type: typeof FEATURED_TRACK_FETCH
}

export const FEATURED_TRACK_FETCH_SUCCESS = 'FEATURED_TRACK_FETCH_SUCCESS'
export type FeaturedTrackFetchSuccessType = {
  type: typeof FEATURED_TRACK_FETCH_SUCCESS,
  featuredTracks: string[]
}

export const FEATURED_TRACK_FETCH_FAIL = 'FEATURED_TRACK_FETCH_FAIL'
export type FeaturedTrackFetchFailType = {
  type: typeof FEATURED_TRACK_FETCH_FAIL
}

export const TOP_PICKS_FETCH = 'TOP_PICKS_FETCH'
export type TopPicksFetchType = {
  type: typeof TOP_PICKS_FETCH
}

export const TOP_PICKS_FETCH_SUCCESS = 'TOP_PICKS_FETCH_SUCCESS'
export type TopPicksFetchSuccessType = {
  type: typeof TOP_PICKS_FETCH_SUCCESS,
  topPicksSubscribed: string[]
}

export const TOP_PICKS_FETCH_FAIL = 'TOP_PICKS_FETCH_FAIL'
export type TopPicksFetchFailType = {
  type: typeof TOP_PICKS_FETCH_FAIL
}

export const WELLNESS_CATEGORY_ID_FETCH = 'WELLNESS_CATEGORY_ID_FETCH'
export type WellnessCategoryIdFetchType = {
  type: typeof WELLNESS_CATEGORY_ID_FETCH
}

export const WELLNESS_CATEGORY_ID_FETCH_SUCCESS = 'WELLNESS_CATEGORY_ID_FETCH_SUCCESS'
export type WellnessCategoryIdFetchSucessType = {
  type: typeof WELLNESS_CATEGORY_ID_FETCH_SUCCESS,
  wellnessCategoryId: string
}

export const WELLNESS_CATEGORY_ID_FETCH_FAIL = 'WELLNESS_CATEGORY_ID_FETCH_FAIL'
export type WellnessCategoryIdFetchFailType = {
  type: typeof WELLNESS_CATEGORY_ID_FETCH_FAIL
}

export const UNLOCK_TRACKS_SUCCESS = 'UNLOCK_TRACKS_SUCCESS'
export type UnlockTracksSuccessType = {
  tracks: string[],
  type: typeof UNLOCK_TRACKS_SUCCESS
}

export const FREE_FOR_YOU_FETCH_SUCCESS = 'FREE_FOR_YOU_FETCH_SUCCESS'
export type FreeForYouSuccessType = {
  tracks: string[],
  type: typeof FREE_FOR_YOU_FETCH_SUCCESS
}

export const UNLOCK_TRACKS_REMOTE_CONF_SUCCESS = 'UNLOCK_TRACKS_REMOTE_CONF_SUCCESS'
export type UnlockTracksRemoteConfSuccessType = {
  tracks: string[],
  type: typeof UNLOCK_TRACKS_REMOTE_CONF_SUCCESS
}

export const USER_LOGGED_IN = 'USER_LOGGED_IN'

export type UserLoggedInType = {
  type: typeof USER_LOGGED_IN
}

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export type UserLoggedOutType = {
  type: typeof USER_LOGGED_OUT
}

export const USER_FETCHED = 'USER_FETCHED'

export type UserFetchedType = {
  type: typeof USER_FETCHED,
  user?: UserType
}

export const USER_SIGN_IN = 'USER_SIGN_IN'

export type UserSignInType = {
  type: typeof USER_SIGN_IN
}

export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS'

export type UserSignInSuccessType = {
  type: typeof USER_SIGN_IN_SUCCESS
}

export const USER_SIGN_IN_FAIL = 'USER_SIGN_IN_FAIL'

export type UserSignInFailType = {
  type: typeof USER_SIGN_IN_FAIL,
  errorText?: string
}

export const USER_SIGN_UP = 'USER_SIGN_UP'

export type UserSignUpType = {
  type: typeof USER_SIGN_UP
}

export const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS'

export type UserSignUpSuccessType = {
  type: typeof USER_SIGN_UP_SUCCESS
}

export const USER_SIGN_UP_FAIL = 'USER_SIGN_UP_FAIL'

export type UserSignUpFailType = {
  type: typeof USER_SIGN_UP_FAIL,
  errorText?: string
}

export const USER_SIGN_OUT = 'USER_SIGN_OUT'

export type UserSignOutType = {
  type: typeof USER_SIGN_OUT
}

export const USER_SIGN_OUT_SUCCESS = 'USER_SIGN_OUT_SUCCESS'

export type UserSignOutSuccessType = {
  type: typeof USER_SIGN_OUT_SUCCESS
}

export const USER_SIGN_OUT_FAIL = 'USER_SIGN_OUT_FAIL'

export type UserSignOutFailType = {
  type: typeof USER_SIGN_OUT_FAIL
}

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD'
export type UserResetPasswordType = {
  type: typeof USER_RESET_PASSWORD
}

export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export type UserResetPasswordSuccessType = {
  type: typeof USER_RESET_PASSWORD_SUCCESS
}

export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL'
export type UserResetPasswordFailType = {
  type: typeof USER_RESET_PASSWORD_FAIL,
  error?: {
    code: string,
    message: string
  }
}

export const SUBSCRIBE_PROMO_CODE_SUCCESS = 'SUBSCRIBE_PROMO_CODE_SUCCESS'
export const SUBSCRIBE_PROMO_CODE_ERROR = 'SUBSCRIBE_PROMO_CODE_ERROR'
export const SUBSCRIBE_PROMO_CODE_RESET = 'SUBSCRIBE_PROMO_CODE_RESET'

export type SubscribePromoCodeSuccessType = {
  type: typeof SUBSCRIBE_PROMO_CODE_SUCCESS,
  promo: string,
  userEnteredPromo: string,
  event?: string,
  welcomeBannerText?: string
}

export type SubscribePromoCodeFailType = {
  type: typeof SUBSCRIBE_PROMO_CODE_ERROR,
  userEnteredPromo: string
}

export const SUBSCRIBE_PURCHASE = 'SUBSCRIBE_PURCHASE'
export const SUBSCRIBE_PURCHASE_SUCCESS = 'SUBSCRIBE_PURCHASE_SUCCESS'
export const SUBSCRIBE_PURCHASE_ERROR = 'SUBSCRIBE_PURCHASE_ERROR'

export type SubscribePurchaseType = {
  type: typeof SUBSCRIBE_PROMO_CODE_SUCCESS,
  plan: 'monthly' | 'annual',
  token: string
}

export type SubscribePurchaseSuccessType = {
  type: typeof SUBSCRIBE_PROMO_CODE_SUCCESS
}

export const CREATOR_PROMO_APPLIED = 'CREATOR_PROMO_APPLIED'
export type CreatorPromoAppliedType = {
  type: typeof CREATOR_PROMO_APPLIED,
  creatorDisplayName: string
}

export type SubscribePurchaseErrorType = {
  type: typeof SUBSCRIBE_PROMO_CODE_ERROR,
  error: string
}

export const SUBSCRIBE_CHANGE_PLAN = 'SUBSCRIBE_CHANGE_PLAN'

export type SubscribeChangePlanType = {
  type: typeof SUBSCRIBE_CHANGE_PLAN,
  plan?: 'monthly' | 'annual' | 'six_months'
}

export const SUBSCRIBE_ADD_NAME = 'SUBSCRIBE_ADD_NAME'

export type SubscribeAddNameType = {
  type: typeof SUBSCRIBE_ADD_NAME,
  name: string
}

export const SUBSCRIBE_STRIPE_TOKEN = 'SUBSCRIBE_STRIPE_TOKEN'

export type SubscribeStripeTokensType = {
  type: typeof SUBSCRIBE_STRIPE_TOKEN,
  token: TokenType
}

export const SUBSCRIBE_MODAL_TOGGLE = 'SUBSCRIBE_MODAL_TOGGLE'
export type SubscribeModalToggleType = {
  type: typeof SUBSCRIBE_MODAL_TOGGLE
}

export const SUBSCRIBE_CANCEL = 'SUBSCRIBE_CANCEL'
export type SubscribeCancelType = {
  type: typeof SUBSCRIBE_CANCEL
}

export const SUBSCRIBE_FETCH_CARD_INFO = 'SUBSCRIBE_FETCH_CARD_INFO'
export type SubscribeFetchCardType = {
  type: typeof SUBSCRIBE_FETCH_CARD_INFO,
  last4: string,
  trialEnd?: number,
  card?: StripePurchaseCardType,
  lastPaymentSuccessful?: boolean
}

export const CATEGORY_FOLLOWS_FETCH = 'CATEGORY_FOLLOWS_FETCH'
export const CATEGORY_FOLLOWS_FETCH_SUCCESS = 'CATEGORY_FOLLOWS_FETCH_SUCCESS'
export const CATEGORY_FOLLOWS_FETCH_FAIL = 'CATEGORY_FOLLOWS_FETCH_FAIL'

export const AUDIO_PLAY = 'AUDIO_PLAY'
export const AUDIO_STARTED = 'AUDIO_STARTED'
export const AUDIO_LOADED = 'AUDIO_LOADED'
export const AUDIO_STOP = 'AUDIO_STOP'
export const AUDIO_PAUSE = 'AUDIO_PAUSE'
export const AUDIO_SEEK = 'AUDIO_SEEK'
export const AUDIO_PLAYING = 'AUDIO_PLAYING'
export const AUDIO_EVENT = 'AUDIO_EVENT'
export const AUDIO_FINISHED = 'AUDIO_FINISHED'

export const FAVORITE_FETCH = 'FAVORITE_FETCH'
export const FAVORITE_FETCH_SUCCESS = 'FAVORITE_FETCH_SUCCESS'
export const FAVORITE_FETCH_FAIL = 'FAVORITE_FETCH_FAIL'

export type FavoriteFetchType = {
  type: typeof FAVORITE_FETCH
}

export type FavoriteFetchSuccessType = {
  type: typeof FAVORITE_FETCH_SUCCESS,
  favorites: UserFavoritesType
}

export type FavoriteFetchFailType = {
  type: typeof FAVORITE_FETCH_FAIL
}

export const FAVORITE_TRACK = 'FAVORITE_TRACK'
export const FAVORITE_UNLOCKED_TRACKS = 'FAVORITE_UNLOCKED_TRACKS'
export const FAVORITE_TRACK_REMOVE = 'FAVORITE_TRACK_REMOVE'
export const FAVORITE_TRACK_SUCCESS = 'FAVORITE_TRACK_SUCCESS'
export const FAVORITE_TRACK_FAIL = 'FAVORITE_TRACK_FAIL'

export type FavoriteTrackType = {
  type: typeof FAVORITE_TRACK
}

export type FavoriteUnlockedTracksType = {
  type: typeof FAVORITE_UNLOCKED_TRACKS
}

export type FavoriteTrackSuccessType = {
  type: typeof FAVORITE_TRACK_SUCCESS,
  tracks: UserFavoritesType
}

export type FavoriteTrackRemoveType = {
  type: typeof FAVORITE_TRACK_REMOVE,
  trackId: string
}

export type FavoriteTrackFailType = {
  type: typeof FAVORITE_TRACK_FAIL
}

export const REMOTE_CONFIG_FETCH = 'REMOTE_CONFIG_FETCH'

export type RemoteConfigFetchType = {
  type: typeof REMOTE_CONFIG_FETCH,
  config: $Shape<AppStateType>
}

export const TRACK_RECOMMENDATIONS_FETCH = 'TRACK_RECOMMENDATIONS_FETCH'
export const TRACK_RECOMMENDATIONS_FETCH_SUCCESS = 'TRACK_RECOMMENDATIONS_FETCH_SUCCESS'
export const TRACK_RECOMMENDATIONS_FETCH_FAIL = 'TRACK_RECOMMENDATIONS_FETCH_FAIL'

export type TrackFetchRecommendationsType = {
  type: typeof TRACK_RECOMMENDATIONS_FETCH
}

export type TrackFetchRecommendationsSuccessType = {
  type: typeof TRACK_RECOMMENDATIONS_FETCH_SUCCESS,
  trackId: string,
  recommendations: RecommendationType[]
}

export type TrackFetchRecommendationsFailType = {
  type: typeof TRACK_RECOMMENDATIONS_FETCH_FAIL
}

export const USER_RECOMMENDATIONS_FETCH = 'USER_RECOMMENDATIONS_FETCH'
export const USER_RECOMMENDATIONS_FETCH_SUCCESS = 'USER_RECOMMENDATIONS_FETCH_SUCCESS'
export const USER_RECOMMENDATIONS_FETCH_FAIL = 'USER_RECOMMENDATIONS_FETCH_FAIL'

export type UserFetchRecommendationsType = {
  type: typeof USER_RECOMMENDATIONS_FETCH
}

export type UserFetchRecommendationsSuccessType = {
  type: typeof USER_RECOMMENDATIONS_FETCH_SUCCESS,
  recommendations: DiscoverPageRowType[]
}

export type UserFetchRecommendationsFailType = {
  type: typeof USER_RECOMMENDATIONS_FETCH_FAIL
}

export const WINDOW_SCREEN_SIZE = 'WINDOW_SCREEN_SIZE'
export const WINDOW_HAS_MOUSE = 'WINDOW_HAS_MOUSE'

export const FIRST_CATEGORY_FOLLOW_AT = 'FIRST_CATEGORY_FOLLOW_AT'
export const FOLLOW_CATEGORY = 'FOLLOW_CATEGORY'
export const FOLLOW_CATEGORY_REMOVE = 'FOLLOW_CATEGORY_REMOVE'
export const FOLLOW_CATEGORY_SUCCESS = 'FOLLOW_CATEGORY_SUCCESS'
export const FOLLOW_CATEGORY_FAIL = 'FOLLOW_CATEGORY_FAIL'

export const TOGGLE_PASSWORD_MODAL = 'TOGGLE_PASSWORD_MODAL'
export type TogglePasswordModalType = {
  type: typeof TOGGLE_PASSWORD_MODAL,
  email: string
}

export const PASSWORD_RESET_WITH_ACTION_CODE_SUCCESS = 'PASSWORD_RESET_WITH_ACTION_CODE_SUCCESS'
export type PasswordResetActionCodeSuccessType = {
  type: typeof PASSWORD_RESET_WITH_ACTION_CODE_SUCCESS
}

export const PASSWORD_RESET_WITH_ACTION_CODE_FAIL = 'PASSWORD_RESET_WITH_ACTION_CODE_FAIL'
export type PasswordResetActionCodeFailType = {
  type: typeof PASSWORD_RESET_WITH_ACTION_CODE_FAIL,
  message: string
}

export const STRIPE_UPGRADE_REQUEST = 'STRIPE_UPGRADE_REQUEST'
export type StripeUpgradeRequestType = {
  type: typeof STRIPE_UPGRADE_REQUEST,
  upgrade: UserStripeUpgradeType
}
export const STRIPE_UPGRADE_SUCCESS = 'STRIPE_UPGRADE_SUCCESS'
export type StripeUpgradeSuccessType = {
  type: typeof STRIPE_UPGRADE_SUCCESS,
  upgrade: UserStripeUpgradeType
}
export const STRIPE_UPGRADE_FAIL = 'STRIPE_UPGRADE_FAIL'
export type StripeUpgradeFailType = {
  type: typeof STRIPE_UPGRADE_FAIL,
  upgrade: UserStripeUpgradeType
}
export const STRIPE_UPGRADE_MODAL_TOGGLE = 'STRIPE_UPGRADE_MODAL_TOGGLE'
export const STRIPE_UPGRADE_RENEWS_AT = 'STRIPE_UPGRADE_RENEWS_AT'
export type StripeUpgradeRenewsAtType = {
  type: typeof STRIPE_UPGRADE_RENEWS_AT,
  renewsAt: number
}

export const SUBSCRIBE_REFERRED_MODAL = 'SUBSCRIBE_REFERRED_MODAL'
export const SUBSCRIBE_REFERRED_APPLY_REFER_ID = 'SUBSCRIBE_REFERRED_APPLY_REFER_ID'
export type SubscribeApplyReferIdType = {
  type: typeof SUBSCRIBE_REFERRED_APPLY_REFER_ID,
  referId: string
}

export const SUBSCRIBE_REFERRED_CLEAR_REFER_ID = 'SUBSCRIBE_REFERRED_CLEAR_REFER_ID'

export const USER_REFER_DATA = 'USER_REFER_DATA'
export type UserReferDataType = {
  type: typeof USER_REFER_DATA,
  referId: string,
  totalRefers: number
}

export const ROUTE_CHANGE_START = 'ROUTE_CHANGE_START'
export const ROUTE_CHANGE_COMPLETE = 'ROUTE_CHANGE_COMPLETE'

export const REFER_MODAL_SHOW_PROMPT = 'REFER_MODAL_SHOW_PROMPT'
export type ReferModalShowType = {
  type: typeof REFER_MODAL_SHOW_PROMPT
}

export const REFER_MODAL_DISMISS_PROMPT = 'REFER_MODAL_DISMISS_PROMPT'
export type ReferModalDismissType = {
  type: typeof REFER_MODAL_DISMISS_PROMPT
}

export const SUBSCRIBE_USER_EXPIRING = 'SUBSCRIBE_USER_EXPIRING'
export type SubscriptionExpiringType = {
  type: typeof SUBSCRIBE_USER_EXPIRING,
  textSubscribeButton: string,
  textAnnualSubheader: string,
  textAnnualCtaWebApp: string,
  showIsExpiringCta: boolean
}

export const FETCH_PROMO_DATA = 'FETCH_PROMO_DATA'
export type FetchPromoDataType = {
  type: typeof FETCH_PROMO_DATA
}

export const FETCH_PROMO_DATA_SUCCESS = 'FETCH_PROMO_DATA_SUCCESS'
export type FetchPromoDataSuccessType = {
  type: typeof FETCH_PROMO_DATA_SUCCESS,
  data: PromosType,
  promoCode: string
}

export const FETCH_PROMO_DATA_FAIL = 'FETCH_PROMO_DATA_FAIL'
export type FetchPromoDataFailType = {
  type: typeof FETCH_PROMO_DATA_FAIL,
  promoCode?: string
}

export const CLEAR_PROMO_DATA = 'CLEAR_PROMO_DATA'
export const TOGGLE_PROMO_FLOW_MODAL = 'TOGGLE_PROMO_FLOW_MODAL'

export const SET_HEADER_COPY_FOR_AUTH_AND_SUBSCRIBE_MODALS = 'SET_HEADER_COPY_FOR_AUTH_AND_SUBSCRIBE_MODALS'
export type SetHeaderCopyForAuthAndSubscribeModalsType = {
  type: typeof SET_HEADER_COPY_FOR_AUTH_AND_SUBSCRIBE_MODALS,
  textAuthHeader: string | null,
  textAuthSubheader: string | null,
  textSubscriptionConfirmationHeader: string | null,
  textSubscriptionConfirmationSubheader: string | null
}

export const SHARE_MODAL_OPEN = 'SHARE_MODAL_OPEN'
export type ShareModalOpenType = {
  type: typeof SHARE_MODAL_OPEN,
  shareTrackId: string
}
export const SHARE_MODAL_CLOSE = 'SHARE_MODAL_CLOSE'
export type ShareModalCloseType = {
  type: typeof SHARE_MODAL_CLOSE
}

export const SHOW_PAYMENT_FORM_ON_PAYWALL = 'SHOW_PAYMENT_FORM_ON_PAYWALL'
export type ShowPaymentModalFormOnPaywallType = {
  type: typeof SHOW_PAYMENT_FORM_ON_PAYWALL,
  isOnPayment: boolean
}

export const HIDDEN_AUTH_MODAL_OPEN = 'HIDDEN_AUTH_MODAL_OPEN'
export const HIDDEN_AUTH_MODAL_CLOSE = 'HIDDEN_AUTH_MODAL_CLOSE'

export const REFRESH_USER_PROVIDERS = 'REFRESH_USER_PROVIDERS'
export type RefreshUserProvidersType = {
  type: typeof REFRESH_USER_PROVIDERS,
  providers: ProvidersType[]
}

export const SOCIAL_SIGN_IN = 'SOCIAL_SIGN_IN'
export type SocialSignInType = {
  type: typeof SOCIAL_SIGN_IN,
  provider: ProvidersType
}

export const SOCIAL_SIGN_IN_SUCCESS = 'SOCIAL_SIGN_IN_SUCCESS'
export type SocialSignInSuccessType = {
  type: typeof SOCIAL_SIGN_IN_SUCCESS
}

export const SOCIAL_SIGN_IN_FAIL = 'SOCIAL_SIGN_IN_FAIL'
export type SocialSignInFailType = {
  type: typeof SOCIAL_SIGN_IN_FAIL,
  errorText: string
}

export const SOCIAL_LINK_ACCOUNT = 'SOCIAL_LINK_ACCOUNT'
export type SocialLinkAccountType = {
  type: typeof SOCIAL_LINK_ACCOUNT,
  provider: ProvidersType
}

export const SOCIAL_LINK_ACCOUNT_SUCCESS = 'SOCIAL_LINK_ACCOUNT_SUCCESS'
export type SocialLinkAccountSuccessType = {
  type: typeof SOCIAL_LINK_ACCOUNT_SUCCESS
}
export const DISABLE_SOCIAL_AUTH = 'DISABLE_SOCIAL_AUTH'
export type DisableSocialAuthType = {
  type: typeof DISABLE_SOCIAL_AUTH
}

export const SOCIAL_LINK_ACCOUNT_FAIL = 'SOCIAL_LINK_ACCOUNT_FAIL'
export type SocialLinkAccountFailType = {
  type: typeof SOCIAL_LINK_ACCOUNT_FAIL,
  errorText: string
}

export const ADMIN_ROLE_FETCH = 'ADMIN_ROLE_FETCH'
export type AdminRoleFetchType = {
  type: typeof ADMIN_ROLE_FETCH
}

export const ADMIN_ROLE_FETCH_SUCCESS = 'ADMIN_ROLE_FETCH_SUCCESS'
export type AdminRoleFetchSuccessType = {
  type: typeof ADMIN_ROLE_FETCH_SUCCESS,
  role: AdminEnumType
}

export const ADMIN_ROLE_FETCH_FAIL = 'ADMIN_ROLE_FETCH_FAIL'
export type AdminRoleFetchFailType = {
  type: typeof ADMIN_ROLE_FETCH_FAIL
}

export const ADMIN_OVERRIDE_CANCEL_FLOW = 'ADMIN_OVERRIDE_CANCEL_FLOW'
export type AdminOverrideCancelFlowType = {
  type: typeof ADMIN_OVERRIDE_CANCEL_FLOW,
  flow: CancelFlowType
}

export const CANCEL_SUBSCRIPTION_SHOW_MODAL = 'CANCEL_SUBSCRIPTION_SHOW_MODAL'
export type CancelSubscriptionShowModalType = {
  type: typeof CANCEL_SUBSCRIPTION_SHOW_MODAL
}

export const CANCEL_SUBSCRIPTION_CLOSE_MODAL = 'CANCEL_SUBSCRIPTION_CLOSE_MODAL'
export type CancelSubscriptionHideModalType = {
  type: typeof CANCEL_SUBSCRIPTION_CLOSE_MODAL
}

export const UPDATE_API_TEST_GROUP = 'UPDATE_API_TEST_GROUP'
export type UpdateApiTestGroupType = {
  type: typeof UPDATE_API_TEST_GROUP,
  group: AlgoTestAssignmentsType
}

export const CANCEL_SUBSCRIPTION_STATUS = 'CANCEL_SUBSCRIPTION_STATUS'
export type CancelSubscriptionStatusType = {
  type: typeof CANCEL_SUBSCRIPTION_STATUS,
  state: ?RequestStatusType
}

export const UPDATE_KEEP_SUBSCRIPTION_STATUS = 'UPDATE_KEEP_SUBSCRIPTION_STATUS'
export type UpdateKeepSubscriptionStatusType = {
  type: typeof UPDATE_KEEP_SUBSCRIPTION_STATUS,
  state: ?RequestStatusType
}

export const UPDATE_PAYMENT_METHOD_MODAL_TOGGLE = 'UPDATE_PAYMENT_METHOD_MODAL_TOGGLE'
export type UpdatePaymentMethodModalToggleType = {
  type: typeof UPDATE_PAYMENT_METHOD_MODAL_TOGGLE,
  isOpen: boolean
}

export const UPDATE_PAYMENT_METHOD_STATUS = 'UPDATE_PAYMENT_METHOD_STATUS'
export type UpdatePaymentMethodLoadingType = {
  type: typeof UPDATE_PAYMENT_METHOD_STATUS,
  status: ?RequestStatusType
}

export const UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR'
export type UpdatePaymentMethodErrorType = {
  type: typeof UPDATE_PAYMENT_METHOD_ERROR,
  error: string
}

export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS'
export type UpdatePaymentMethodSuccessType = {
  type: typeof UPDATE_PAYMENT_METHOD_SUCCESS,
  last4: string
}

export const UPDATE_CHECK_STRIPE_CHARGE_STATUS = 'UPDATE_CHECK_STRIPE_CHARGE_STATUS'
export type UpdateCheckStripeChargeStatusType = {
  type: typeof UPDATE_CHECK_STRIPE_CHARGE_STATUS,
  state: ?RequestStatusType,
  message?: string
}

export const SET_QUIZ_RESULT = 'SET_QUIZ_RESULT'
export type SetQuizResultType = {
  type: typeof SET_QUIZ_RESULT,
  result: string,
  genderPreference: string
}

export const USER_REFERRAL_INFO = 'USER_REFERRAL_INFO'
export type UserReferralInfoType = {
  type: typeof USER_REFERRAL_INFO,
  isSuccessfullyReferredAFriend: boolean
}

export const USER_FETCH_STORIES_LEFT_SUCCESS = 'USER_FETCH_STORIES_LEFT_SUCCESS'
export type UserFetchStoriesLeftSuccessType = {
  type: typeof USER_FETCH_STORIES_LEFT_SUCCESS,
  content: RecommendationType
}

export const REDEEM_GIFT_FAIL = 'REDEEM_GIFT_FAIL'
export type RedeemGiftFailType = {
  type: typeof REDEEM_GIFT_FAIL,
  msg: string
}

export const REDEEMING_GIFT = 'REDEEMING_GIFT'
export type RedeemingGiftType = {
  type: typeof REDEEMING_GIFT
}

export const REDEEM_GIFT_SUCCESS = 'REDEEM_GIFT_SUCCESS'
export type RedeemGiftSuccessType = {
  type: typeof REDEEM_GIFT_SUCCESS
}

export const GIFT_PURCHASE_FAIL = 'GIFT_PURCHASE_FAIL'
export type PurchaseGiftFailType = {
  type: typeof GIFT_PURCHASE_FAIL,
  message: string
}

export const GIFT_PURCHASE_PROCESSING = 'GIFT_PURCHASE_PROCESSING'
export type PurchasingGiftType = {
  type: typeof GIFT_PURCHASE_PROCESSING
}

export const GIFT_PURCHASE_SUCCESS = 'GIFT_PURCHASE_SUCCESS'
export type PurchaseGiftSuccessType = {
  type: typeof GIFT_PURCHASE_SUCCESS,
  giftCode: string
}

export const USER_BLOG_EMAIL_LOADING = 'USER_BLOG_EMAIL_LOADING'
export type UserBlogEmailLoadingType = {
  type: typeof USER_BLOG_EMAIL_LOADING
}

export const USER_BLOG_EMAIL_SUCCESS = 'USER_BLOG_EMAIL_SUCCESS'
export type UserBlogEmailSuccessType = {
  type: typeof USER_BLOG_EMAIL_SUCCESS
}

export const USER_BLOG_EMAIL_ERROR = 'USER_BLOG_EMAIL_ERROR'
export type UserBlogEmailErrorType = {
  type: typeof USER_BLOG_EMAIL_ERROR,
  errorText: string
}

export const REFUND_STATUS_UPDATED = 'REFUND_STATUS_UPDATED'
export type RefundStatusType = {
  type: typeof REFUND_STATUS_UPDATED,
  refund: RefundObjectType
}

export const UPDATE_APPLY_COUPON_SUBSCRIPTION_STATUS = 'UPDATE_APPLY_COUPON_SUBSCRIPTION_STATUS'
export type UpdateApplyCouponSubscriptionStatusType = {
  type: typeof UPDATE_APPLY_COUPON_SUBSCRIPTION_STATUS,
  state: ?RequestStatusType
}

export const SEARCH_FILTER_APPLY = 'SEARCH_FILTER_APPLY'
export type SearchFilterApplyType = {
  type: typeof SEARCH_FILTER_APPLY,
  id: string,
  searchFilter: SearchFilterType
}

export const SEARCH_FILTER_CLEAR = 'SEARCH_FILTER_CLEAR'
export type SearchFilterClearType = {
  type: typeof SEARCH_FILTER_CLEAR,
  id: string
}

export const TOGGLE_SUBSCRIPTION_MODAL = 'TOGGLE_SUBSCRIPTION_MODAL'
export type ToggleSubscriptionModalType = {
  type: typeof TOGGLE_SUBSCRIPTION_MODAL
}
