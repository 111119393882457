// @flow
import styled from 'styled-components'
import { colors } from './colors'
import type { ColorsType } from './colors'
import { config } from 'react-awesome-styled-grid'
import { MEDIA } from './media'
import { OutlineOnFocusVisible } from './buttons'

export type TextAlignType = 'left' | 'right'

export type FontPropsType = {
  color?: ColorsType,
  longTitle?: boolean,
  letterSpacing?: number,
  fontWeight?: number,
  textAlign?: TextAlignType,
  fontSize?: string,
  fontFamily?: string,
  lineHeight?: string,
  styles?: string,
  margin?: string
}

export const H1 = styled.h1`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family:  ${(props: FontPropsType): string => props.fontFamily || 'Queens Condensed Light'};
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '60px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '66px')};
  
  ${(props: any): string => config(props).media.lg`
    font-size: 70px;
    line-height: 77px;
  `}
  
  ${(props: any): string => config(props).media.xl`
    font-size: 90px;
    line-height: 94.5px;
  `}
 
  ${(props: FontPropsType): ?string => props.styles}
`

export const H160 = styled.h1`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: 36px;
  line-height: 43px;

  ${(props: any): string => config(props).media.lg`
    font-size: 60px;
    line-height: 66px;
  `}
  ${(props: FontPropsType): ?string => props.styles}
  
  ${(props: any): ?string => {
    if (props.spanColor) {
      return (
        `span {color: ${props.spanColor}}`
      )
    }
  }}
  
`

export const H190 = styled.h2`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: 36px;
  line-height: 53px;
  
  ${(props: any): string => config(props).media.sm`
    font-size: 45px;
    line-height: 67px;
  `}
  
  ${(props: any): string => config(props).media.lg`
    font-size: 70px;
    line-height: 77px;
  `}
  
  ${(props: any): string => config(props).media.xl`
    font-size: 90px;
    line-height: 94.5px;
  `}
 
  ${(props: FontPropsType): ?string => props.styles}
`

export const H260 = styled.h2`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: 45px;
  line-height: 49px;
  
  ${(props: any): string => config(props).media.lg`
    font-size: 50px;
    line-height: 60px;
  `}
  
  ${(props: any): string => config(props).media.xl`
    font-size: 60px;
    line-height: 66px;
  `}
  
  ${(props: FontPropsType): ?string => props.styles}
`

export const H245 = styled.h2`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: 36px;
  line-height: 43px;
  
  ${(props: any): string => config(props).media.lg`
    font-size: 45px;
    line-height: 54px;
  `}
  
  ${(props: FontPropsType): ?string => props.styles}
`

export const H250 = styled.h2`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: 36px;
  line-height: 43px;
  ${(props: any): string => config(props).media.xl`
    font-size: 50px;
    line-height: 60px;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 45px;
    line-height: 49px;
  `}
  
  ${(props: FontPropsType): ?string => props.styles}
`

export const H290 = styled.h2`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: 36px;
  line-height: 53px;
  
  ${(props: any): string => config(props).media.sm`
    font-size: 45px;
    line-height: 67px;
  `}
  
  ${(props: any): string => config(props).media.lg`
    font-size: 70px;
    line-height: 77px;
  `}
  
  ${(props: any): string => config(props).media.xl`
    font-size: 90px;
    line-height: 94.5px;
  `}
 
  ${(props: FontPropsType): ?string => props.styles}
`

export const H2 = styled.h2`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '60px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '110%')};
  
  ${(props: any): string => config(props).media.md`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '45px')};
  `}
  ${(props: any): string => config(props).media.sm`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '45px')};
  `}
  ${(props: FontPropsType): ?string => props.styles}
`

export const H3 = styled.h3`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '36px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '43px')};
  
  
  ${(props: any): string => config(props).media.lg`
    font-size: 45px;
    line-height: 54px;
  `}
  
  ${(props: FontPropsType): ?string => props.styles}
`

export const H4 = styled.h4`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '36px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  ${MEDIA.PHONEL`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '36px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${MEDIA.TABLET`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '36px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${MEDIA.DESKTOP`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '36px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${(props: FontPropsType): ?string => props.styles}
`

export const H5 = styled.h5`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '28px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  ${MEDIA.PHONEL`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '28px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${MEDIA.TABLET`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '28px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${MEDIA.DESKTOP`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '28px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${(props: FontPropsType): ?string => props.styles}
`

export const Quote = styled.p`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '50px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  ${MEDIA.PHONEL`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '50px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${MEDIA.TABLET`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '50px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${MEDIA.DESKTOP`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '50px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '120%')};
  `};
  ${(props: FontPropsType): ?string => props.styles}
`

export const P = styled.p`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: ${(props: FontPropsType): string => props.margin || '0'};;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '18px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '150%')};
  ${MEDIA.PHONEL`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '18px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '150%')};
  `};
  ${MEDIA.TABLET`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '18px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '150%')};
  `};
  ${MEDIA.DESKTOP`
    font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '18px')};
    line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '150%')};
  `};
  ${(props: FontPropsType): ?string => props.styles}
`

export const P18 = styled.p`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: ${(props: FontPropsType): string => props.margin || '0'};;
  font-size: 16px;
  line-height: 24px;
  
  ${(props: any): string => config(props).media.sm`
     font-size: 18px;
    line-height: 27px;
  `}
 
  ${(props: FontPropsType): ?string => props.styles}
  
  a {
    color: ${colors.tintOrange}
  }
`

export const P20 = styled.p`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Good Sans Regular';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: ${(props: FontPropsType): string => props.margin || '0'};;
  font-size: 16px;
  line-height: 24px;
  
  ${(props: any): string => config(props).media.sm`
     font-size: 18px;
    line-height: 27px;
  `}
  
  ${(props: any): string => config(props).media.lg`
     font-size: 20px;
    line-height: 30px;
  `}
 
  ${(props: FontPropsType): ?string => props.styles}
`

export const P16 = styled.p`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: ${(props: FontPropsType): string => props.margin || '0'};;
  font-size: 14px;
  line-height: 21px;
  
  ${(props: any): string => config(props).media.lg`
     font-size: 16px;
     line-height: 24px;
  `}
 
  ${(props: FontPropsType): ?string => props.styles}
`

export const P14 = styled.p`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: ${(props: FontPropsType): string => props.margin || '0'};;
  font-size: 12px;
  line-height: 18px;
  
  ${(props: any): string => config(props).media.lg`
     font-size: 14px;
      line-height: 18px;
  `}
 
  ${(props: FontPropsType): ?string => props.styles}
`

export const B = styled.b`
  font-family: 'Good Sans Bold';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 700};
  font-size: ${(props: FontPropsType): string => props.fontSize || '16px'};
  line-height: ${(props: FontPropsType): string => props.lineHeight || '24px'};
  color: ${(props: FontPropsType): string => props.color || colors.midnightBlue};
  
  ${(props: FontPropsType): ?string => props.styles}
`

export const LINK = styled.a`
  color: ${(props: FontPropsType): string => props.color || colors.white};
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: ${(props: FontPropsType): number => props.fontWeight || 300};
  letter-spacing: ${(props: FontPropsType): number => props.letterSpacing || 0};
  text-align: ${(props: FontPropsType): TextAlignType => props.textAlign || 'left'};
  margin: 0;
  text-decoration: underline;
  font-size: ${(props: FontPropsType): string => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props: FontPropsType): string => (props.lineHeight ? props.lineHeight : '150%')};
  cursor: pointer;
  ${(props: FontPropsType): ?string => props.styles}
`

export const SectionTitle = styled(P)`
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  color: ${(props: FontPropsType): string => props.color || colors.sand};
  ${(props: any): string => config(props).media.md`
    margin-bottom: 25px;
  `}
`

export const SectionHeader = styled(H3)`
  font-size: 36px;
  line-height: 120%;
  margin-bottom: 18px;
  color: ${(props: FontPropsType): string => props.color || colors.sand};
  ${(props: any): string => config(props).media.md`
    font-size: 60px;
    line-height: 110%;
    margin-bottom: 0;
  `}
`

export const SectionSubText = styled(P)`
  margin-bottom: 17px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: FontPropsType): string => props.color || colors.sand};
  ${(props: any): string => config(props).media.md`
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 27px;
  `}
`
export const Section2Header = styled(SectionHeader)`
  font-size: 28px;
  line-height: 34px;
  padding: 0;
  ${(props: any): string => config(props).media.lg`
    font-size: 60px;
    line-height: 66px
  `}
`

export const MarketingTitle = styled(H1)`
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 10px;
  text-align: center;
  color: ${colors.sand};
  ${(props: any): string => config(props).media.md`
    text-align: left;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 45px;
  `}
  ${(props: any): string => config(props).media.xl`
    font-size: 60px;
  `}
`
export const MarketingDescription = styled(P)`
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 46px;
  text-align: center;
  color: ${colors.sand};
  ${(props: any): string => config(props).media.md`
    font-size: 14px;
    text-align: left;
    margin-bottom: 33px;
  `}
  ${(props: any): string => config(props).media.lg`
    font-size: 18px;
    margin-bottom: 34px;
  `}
`

export const MarketingH2 = styled(H2)`
  font-size: 23px;
  line-height: 110%;
  margin-bottom: 9px;
  text-align: center;
  color: ${colors.sand};
  ${(props: any): string => config(props).media.md`
    margin-bottom: 15px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin-bottom: 35px;
  `}
`
export const GoodSansHeader1 = styled.h1`
  color: ${colors.white};
  font-family: "Good Sans Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin-block: 0;
  margin: 0;
  padding: 0;
  ${(props: any): string => config(props).media.lg`
    font-size: 20px;
  `}
`

/*
███    ██ ███████ ██     ██
████   ██ ██      ██     ██
██ ██  ██ █████   ██  █  ██
██  ██ ██ ██      ██ ███ ██
██   ████ ███████  ███ ███
*/
export const QueensH1 = `
  color: ${colors.white};
  font-family: 'Queens Condensed Regular';
  font-style: normal;
  font-weight: normal;
  line-height: 110%;
  margin-block: 0;
  margin: 0;
  padding: 0;

  font-size: 60px;
  @media (min-width: 1024px) {
    font-size: 70px;
  }
`
export const QueensH2 = `
  ${QueensH1};
  font-size: 45px;
  @media (min-width: 1024px) {
    font-size: 60px;
  }
`
export const QueensH3 = `
  ${QueensH1};
  font-size: 36px;
  @media (min-width: 1024px) {
    font-size: 45px;
  }
`
export const QueensH4 = `
  ${QueensH1};
  font-size: 28px;
  @media (min-width: 1024px) {
    font-size: 36px;
  }
`

export const QueensH5 = `
  color: ${colors.white};
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0;
  text-align: 'left';
  margin: 0;
  font-size: 26px;
  line-height: 120%;
  
  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 1024px) {
    font-size: 36px;
  }
  @media (min-width: 1440px) {
    font-size: 45px;
  }
  
`
export const GoodSansH1 = `
  color: ${colors.white};
  font-family: "Good Sans Bold";
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  margin-block: 0;
  marign: 0;
  padding: 0;

  font-size: 16px;
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`
export const GoodSansH2 = `
  ${GoodSansH1};
  font-size: 14px;
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`
export const GoodSansH3 = `
  ${GoodSansH1};
  font-size: 12px;
  @media (min-width: 1024px) {
    font-size: 16px;
  }
`
export const GoodSansH4 = `
  ${GoodSansH1};
  font-size: 10px;
  @media (min-width: 1024px) {
    font-size: 14px;
  }
`
export const GoodSansB1 = `
  color: ${colors.white};
  font-family: "Good Sans Regular";
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  margin-block: 0;
  margin: 0;
  padding: 0;

  font-size: 16px;
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`
export const GoodSansB2 = `
  ${GoodSansB1};
  font-size: 14px;
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`
export const GoodSansB3 = `
  ${GoodSansB1};
  font-size: 12px;
  @media (min-width: 1024px) {
    font-size: 16px;
  }
`
export const GoodSansB4 = `
  ${GoodSansB1};
  font-size: 11px;
  @media (min-width: 1024px) {
    font-size: 14px;
  }
`
export const GoodSansB5 = `
  ${GoodSansB1};
  font-size: 10px;
  @media (min-width: 1024px) {
    font-size: 12px;
  }
`

export const GoodSansLink = `
  font-family: "Good Sans Bold";
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  margin-block: 0;
  margin: 0;
  padding: 0;
  color: ${colors.tintOrange};
  text-decoration: underline;
  font-size: 12px;
  @media (min-width: 1024px) {
    font-size: 14px;
  }
`

export const Button1Text = `
  color: ${colors.white};
  font-family: "Good Sans Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-block: 0;
  marign: 0;
  padding: 0;
  border-radius: 8px;
  :hover {
    opacity: 0.8;
  }
  ${OutlineOnFocusVisible};
`
export const Button2Text = `
  ${Button1Text};
  text-decoration-line: underline;
  color: ${colors.tintOrange}
`
