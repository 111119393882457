// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const MyStoriesIcon = (props: PropsType): React.Node => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || '17'} height={props.size || '17'} viewBox="0 0 17 16" fill="none">
      <path
        d="M10.911 2.24724e-09C10.0567 5.02816e-05 9.44079 0.81883 9.67755 1.63962L13.5713 15.1378C13.7186 15.6484 14.1859 16 14.7173 16C15.5045 16 16.0758 15.2508 15.8675 14.4916L12.1491 0.944001C11.996 0.386436 11.4892 -3.40284e-05 10.911 2.24724e-09Z"
        fill={props.color || colors.white}/>
      <path
        d="M0.896973 1.23082C0.896973 0.55109 1.448 5.98251e-05 2.12773 5.98251e-05C2.80746 5.98251e-05 3.3585 0.551091 3.3585 1.23082V14.7692C3.3585 15.4489 2.80746 16 2.12773 16C1.448 16 0.896973 15.4489 0.896973 14.7692V1.23082Z"
        fill={props.color || colors.white}/>
      <path
        d="M5.20463 1.23086C5.20463 0.551131 5.75566 0.000100435 6.43539 0.000100435C7.11512 0.000100435 7.66615 0.551131 7.66615 1.23086V14.7692C7.66615 15.449 7.11512 16 6.43539 16C5.75566 16 5.20463 15.449 5.20463 14.7692V1.23086Z"
        fill={props.color || colors.white}/>
    </svg>
  )
}

export default MyStoriesIcon
