// @flow
import * as React from 'react'
import { colors } from '../colors'

export const DownArrow = (props: {
  color?: string,
  size?: number,
  reverse?: boolean
}): React.Node => {
  const color = props.color || colors.sand
  const width = props.size || 14
  const height = props.size ? props.size * 0.5555555556 : 10

  return (
    <svg style={{ transform: props.reverse ? 'scaleY(-1)' : 'none' }} width={width} height={height} viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.10718L7 6.89285L13 1.10718" stroke={color} strokeWidth="2"/>
    </svg>
  )
}
