// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const PauseIcon = (props: PropsType): React.Node => {
  return (
    <svg
      width={props.size || '100%'}
      height={props.size || '100%'}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='24' cy='24' r='24' fill={colors.white} />
      <path
        d='M17.76 15.0938C17.3771 15.0938 17.0667 15.4042 17.0667 15.7871V33.8137C17.0667 34.1967 17.3771 34.5071 17.76 34.5071H21.2266C21.6096 34.5071 21.92 34.1967 21.92 33.8137V15.7871C21.92 15.4042 21.6096 15.0938 21.2266 15.0938H17.76Z'
        fill={colors.orange}
      />
      <path
        d='M26.7733 15.0938C26.3904 15.0938 26.08 15.4042 26.08 15.7871V33.8137C26.08 34.1967 26.3904 34.5071 26.7733 34.5071H30.24C30.6229 34.5071 30.9333 34.1967 30.9333 33.8137V15.7871C30.9333 15.4042 30.6229 15.0938 30.24 15.0938H26.7733Z'
        fill={colors.orange}
      />
    </svg>
  )
}

export default PauseIcon
