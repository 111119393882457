// @flow
import * as React from 'react'
import { initializeStore } from './store'
import { isServer } from './env'
import type { AppStateType } from './reducers/appstate'
import type NextAppType from 'next/document'
import type { Action, Store } from 'redux'
import { loadAppActionAsync } from './actions/loadActions'
import { firebaseApp } from './services/firebaseInitialize'

const __NEXT_REDUX_STORE__: string = '__NEXT_REDUX_STORE__'

function getOrCreateStore (initialState?: AppStateType): Store<AppStateType, Action<any>> {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}

const WithReduxStore = (App: NextAppType): Class<React$Component<any, any>> => {
  return class AppWithRedux extends React.Component<{}, {}> {
    static async getInitialProps (appContext: { ctx: {reduxStore: Store<AppStateType, Action<any>>, pathname: string, query: any, req: any, res: any, err?: any, asPath: string }}): Promise<any> {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore()
      if (isServer) {
        await firebaseApp()
      }
      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      let appProps = {}
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext)
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState()
      }
    }

    reduxStore: Store<AppStateType, Action<any>>

    constructor (props: any): void {
      super(props)
      this.reduxStore = getOrCreateStore(props.initialReduxState)
    }

    async componentDidMount (): Promise<void> {
      if (!isServer) {
        await this.reduxStore.dispatch(loadAppActionAsync())
      }
    }

    render (): React.Node {
      return <App {...this.props} reduxStore={this.reduxStore} />
    }
  }
}

export default WithReduxStore
