// @flow

import type {
  PromoStateObjectType,
  RefundObjectType,
  RequestStatusType,
  SubscriptionPromoType,
  SubscriptionPurchaseType,
  SubscriptionTextType,
  UserType
} from '../flowTypes'
import type { SearchFilterType } from '../models/Search'
import type { PlayType } from '@services/audio'
import type { DiscoverPageRowType } from '../models/Discover'

export type AppStateType = {
  remoteConfig: any,
  user: UserType,
  userLoaded: boolean,
  subscriptionPromo?: SubscriptionPromoType,
  subscriptionPurchase: SubscriptionPurchaseType,
  subscriptionText: SubscriptionTextType,
  subscriptionReferId?: string,
  textCurrencySymbol: string,
  textCurrencyISO: string,
  textAnnualBannerWebApp: string,
  textAnnualYearlyPrice: string,
  textAnnualBillingCycle: string,
  textWebAppAnnualCycle: string,
  textWebAppMonthlyCycle: string,
  textAnnualPrice: string,
  textAnnualTrialText: string,
  textAnnualCtaWebApp: string,
  textAnnualArialLabel: string,
  textMonthlyPrice: string,
  textMonthlyBillingCycle: string,
  textMonthlyCtaWebApp: string,
  textMonthlyArialLabel: string,
  loginRequestInProgress: boolean,
  loginFormErrorCode?: string,
  loginFormErrorText?: string,
  hasMouse: boolean,
  featureAppleAuth: boolean,
  featureFacebookAuth: boolean,
  featureGoogleAuth: boolean,
  resetPasswordEmail?: string,
  resetPasswordErrorCode?: string,
  resetPasswordErrorText?: string,
  resetPasswordEmailSent: boolean,
  passwordResetMessage?: string,
  passwordResetSuccess?: boolean,
  redeemingGift: boolean,
  redeemingGiftErrorMessage?: string,
  purchasingGiftErrorMessage?: string,
  purchasingGift: boolean,
  giftCode?: string,
  quizResult?: string,
  quizGenderPreference?: string,
  promoFlowModalOpen: boolean,
  subscriptionModalOpen: boolean,
  isOnPayment: boolean,
  textSubscriptionModalHeader: string,
  textPaywallValueProps: string[],
  promo?: PromoStateObjectType,
  textAuthHeader: string,
  textAuthSubheader: string,
  textSubscriptionConfirmationHeader: string,
  textSubscriptionConfirmationSubheader: string,
  featureValentinesGift: boolean,
  blogEmailCaptureText: {
    intro: string,
    header: string,
    body: string,
    submit: string,
    dismiss: string
  },
  blogEmailCaptureSuccessText: {
    intro: string,
    header: string,
    body: string,
    dismiss: string
  },
  blogEmailErrorText?: string,
  blogEmailIsLoading: boolean,
  blogEmailIsSuccess: boolean,
  featureBlogEmailCapture: boolean,
  featureAnnualOnly: boolean,
  creatorDisplayName?: string,
  featurePrideGift: boolean,
  refund?: RefundObjectType,
  emailChangeSuccess: boolean,
  emailChangeErrorCode: string,
  emailChangeErrorText: string,
  passwordChangeSuccess: boolean,
  passwordChangeErrorCode: string,
  passwordChangeErrorText: string,
  linkAccountRequestInProgress: boolean,
  linkAccountErrorText?: string,
  linkAccountRequestProvider?: string,
  showCancelSubscriptionModal?: boolean,
  updatePaymentMethodError?: string,
  updatePaymentMethodStatus?: RequestStatusType,
  textCancellationSurvey: {
    cancelSurveyHeader: string,
    cancelSurveyDescription: string,
    cancellationSurveyOptions: string[],
    cancellationSurveyLastOptions: string[]
  },
  applyNewCouponStatus?: RequestStatusType,
  cancelSubscriptionStatus?: RequestStatusType,
  searchFilters: SearchFilterType,
  currentPlaying?: PlayType,
  lockedModalVisible: boolean,
  recommendations: DiscoverPageRowType[],
  featureLongPreviewPlay?: boolean,
  subscriptionCTA: string
}

export const initialState: AppStateType = {
  remoteConfig: {},
  user: {},
  userLoaded: false,
  subscriptionPurchase: { inProcess: false, plan: 'annual' },
  subscriptionText: {},
  textCurrencySymbol: '$',
  textCurrencyISO: 'USD',
  textAnnualBannerWebApp: 'MOST POPULAR',
  textAnnualYearlyPrice: '69.99',
  textAnnualBillingCycle: '/mo', // shared on remote config
  textWebAppAnnualCycle: 'Annually',
  textWebAppMonthlyCycle: 'Monthly',
  textAnnualPrice: '5.83',
  textAnnualTrialText: 'First 7 Days Free',
  textAnnualCtaWebApp: 'START 7-DAY FREE TRIAL',
  subscriptionCTA: 'Try Dipsea',
  textAnnualArialLabel: 'Choose the annual plan with first 7 Days Free',
  textMonthlyPrice: '12.99',
  textMonthlyBillingCycle: '/yr', // shared on remote config
  textMonthlyCtaWebApp: 'PURCHASE MONTHLY',
  textMonthlyArialLabel: 'Choose the monthly plan',
  loginRequestInProgress: false,
  hasMouse: false,
  featureAppleAuth: true,
  featureFacebookAuth: true,
  featureGoogleAuth: true,
  resetPasswordEmailSent: false,
  redeemingGift: false,
  purchasingGiftErrorMessage: undefined,
  purchasingGift: false,
  promoFlowModalOpen: false,
  subscriptionModalOpen: false,
  isOnPayment: false,
  textSubscriptionModalHeader: 'Get unlimited access to hundreds of sexy audio stories and wellness sessions',
  textPaywallValueProps: [
    'Hundreds of stories with new releases every week',
    'Hot & heavy, straight & queer, diverse themes',
    'Soothing bedtime stories & wellness sessions',
    'Serialized stories with characters you’ll love'
  ],
  textAuthHeader: 'Try risk free',
  textAuthSubheader: 'Start a free trial and unlock our endless library of audio stories, bingeable books, sleep scenes, and wellness sessions',
  textSubscriptionConfirmationHeader: 'You just unlocked 1,000 original spicy audiobooks and more',
  textSubscriptionConfirmationSubheader: 'Download the app to experience romantic fiction brought to life. Crafted with care by our team of professional writers and top tier narrators, with Dipsea you can go deep with your favorite characters and get lost in their world.',
  featureValentinesGift: false,
  blogEmailCaptureText: {
    intro: 'SHARE YOUR EMAIL',
    header: 'Get 30 Days Free',
    body: 'Unlock hundreds of sexy audio stories, erotic meditations, and sleep scenes for hotter “me-time” on the Dipsea app.',
    submit: 'Get 30 days free',
    dismiss: 'Skip for now'
  },
  blogEmailCaptureSuccessText: {
    intro: 'CHECK YOUR INBOX',
    header: 'Your Promo Is\nWaiting for You!',
    body: 'We sent you a special welcome email, plus an exclusive promo for 30 Days Free on the Dipsea app.',
    dismiss: 'close'
  },
  blogEmailErrorText: undefined,
  blogEmailIsLoading: false,
  blogEmailIsSuccess: false,
  featureBlogEmailCapture: true,
  featureAnnualOnly: true,
  creatorDisplayName: undefined,
  featurePrideGift: false,
  emailChangeSuccess: false,
  emailChangeErrorCode: '',
  emailChangeErrorText: '',
  passwordChangeSuccess: false,
  passwordChangeErrorCode: '',
  passwordChangeErrorText: '',
  linkAccountRequestInProgress: false,
  showCancelSubscriptionModal: false,
  textCancellationSurvey: {
    cancelSurveyHeader: 'Why are you leaving?',
    cancelSurveyDescription: 'We’re always trying to improve, and your feedback is very helpful.',
    cancellationSurveyOptions: [
      'The price is too high',
      'I don’t listen frequently enough',
      'I can’t find stories that suit my interests'
    ],
    cancellationSurveyLastOptions: ['Other']
  },
  searchFilters: {
  },
  lockedModalVisible: false,
  recommendations: []
}
