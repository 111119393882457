// @flow
import type { UserType } from '../flowTypes'
import { envConfig, isServer, isTest } from '../env'
import { sanitizeUrlWithEmailPass } from './analytics'

function intializeSegment (APP_ID: string): void {
  ((APP_ID: string): void => {
    // Create a queue, but don't obliterate an existing one!
    const analytics = window.analytics = window.analytics || []

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.')
      }
      return
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on'
    ]

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = (method: any): any => {
      return (): any => {
        const args = Array.prototype.slice.call(arguments)
        args.unshift(method)
        analytics.push(args)
        return analytics
      }
    }

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i]
      analytics[key] = analytics.factory(key)
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = (key: any, options: any): void => {
      // Create an async script element based on your key.
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://cdn.segment.com/analytics.js/v1/' +
                key + '/analytics.min.js'

      // Insert our script next to the first script element.
      const first: any = document.getElementsByTagName('script')[0]
      first.parentNode.insertBefore(script, first)
      analytics._loadOptions = options
    }

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.1.0'

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(APP_ID, { initialPageview: false })

    // Make the first page call to load the integrations. If
    // you'd like to manually name or tag the page, edit or
    // move this call however you'd like.

    // A delay is causing the page name to log our segment app id. This _usually_ fixes the issue:
    setTimeout((): void => logPageSegment(), 400)
  })(APP_ID)
}

function shouldLogEvent (name?: string): boolean {
  if (window &&
        (
          !(window.location || {}).href.includes('email=') &&
            !(window.location || {}).href.includes('password=')
        )) {
    return true
  } else {
    return false
  }
}

export function initSegment (): void {
  if (!isServer && !isTest) {
    const publicRuntimeConfig = envConfig()
    const APP_ID = publicRuntimeConfig.SEGMENT_APP_ID || ''
    if (window) {
      if (!window.analytics) {
        intializeSegment(APP_ID)
      } else {
        delete window.analytics
        intializeSegment(APP_ID)
      }
    }
  }
}

export function identifyUserSegment (user: UserType, retryTime: number = 0): void {
  sanitizeUrlWithEmailPass()
  if (shouldLogEvent('identify')) {
    if (window?.analytics) {
      window.analytics.identify(user.id, {
        email: user.email
      })
    } else {
      if (retryTime < 50) {
        setTimeout((): void => {
          identifyUserSegment(user, ++retryTime)
        }, 1000)
      }
    }
  }
}

export function logPageSegment (page?: string, retryTime: number = 0): void {
  sanitizeUrlWithEmailPass()
  if (shouldLogEvent('page')) {
    if (window?.analytics) {
      window.analytics.page({
        url: window.location.href,
        path: page || window.location.pathname
      })
    } else {
      if (retryTime < 50) {
        setTimeout((): void => {
          logPageSegment(page, ++retryTime)
        }, 1000)
      }
    }
  }
}

export function resetSegment (retryTime: number = 0): void {
  sanitizeUrlWithEmailPass()
  if (shouldLogEvent('reset')) {
    if (window?.analytics) {
      window.analytics.reset()
    } else {
      if (retryTime < 50) {
        setTimeout((): void => {
          resetSegment(++retryTime)
        }, 1000)
      }
    }
  }
}

export function getIterableCampaignInfo (): any {
  if (!isServer) {
    const search = new URLSearchParams(window.location.search)
    const campaignId = search.get('campaignId')
    const templateId = search.get('templateId')
    if (campaignId && templateId) {
      return {
        campaignId,
        templateId
      }
    }
  }
  return {}
}

export function trackSegment (eventName: string, params: any = {}, waitForUser: boolean = false, retryTime: number = 0): void {
  // console.log('eventName:', eventName, 'params:', params)
  sanitizeUrlWithEmailPass()
  if (shouldLogEvent(eventName)) {
    if (window?.analytics) {
      if (waitForUser && !window?.analytics) {
        if (retryTime < 50) {
          setTimeout((): void => {
            trackSegment(eventName, params, waitForUser, ++retryTime)
          }, 1000)
        }
      } else {
        window.analytics.track(eventName, { ...params, ...getIterableCampaignInfo() })
      }
    } else {
      if (retryTime < 50) {
        setTimeout((): void => {
          trackSegment(eventName, params, waitForUser, ++retryTime)
        }, 1000)
      }
    }
  }
}

export function updateUserSegment (userId: string, params: {}): void {
  sanitizeUrlWithEmailPass()
  if (shouldLogEvent('updateuser')) {
    window.analytics.identify(userId, params)
  }
}

export function identifyUserWithCallback (userId?: string, email: string, callback: () => void): void {
  sanitizeUrlWithEmailPass()
  if (shouldLogEvent('callback')) {
    if (userId) {
      // analytics.identify([userId], [traits], [options], [callback]);
      window.analytics.identify(userId, { email }, {}, callback)
    } else {
      // https://segment.com/docs/connections/spec/identify/#anonymous-id
      window.analytics.identify({ email }, {}, callback)
    }
  }
}
