// @flow
import type { AppStateType } from './appstate'
import { initialState } from './appstate'
import { merge, setIn } from 'immutable-light'
import { cloneDeep } from 'lodash'
import * as actionTypes from '../actions/actionTypes'
import type { UserFetchedType } from '../actions/actionTypes'
import { REQUEST_STATUS } from '../flowTypes'
import type { SearchFilterKeyType } from '../models/Search'

export function applyRemoteConfig (
  stateObject: AppStateType,
  remoteConfig: any,
  key?: string
): AppStateType {
  const oldState = cloneDeep(stateObject)
  if (key && remoteConfig && remoteConfig[key]) {
    const newStateKey = {
      [key]: remoteConfig[key]
    }
    return merge(oldState, newStateKey)
  } else {
    oldState.remoteConfig = remoteConfig
    return merge(oldState, remoteConfig)
  }
}

export function mainReducer (
  state: AppStateType = initialState,
  action: any
): AppStateType {
  let newState = {}
  switch (action.type) {
    case actionTypes.REMOTE_CONFIG_FETCH:
      newState = applyRemoteConfig(state, action.config)
      return newState
    case actionTypes.USER_FETCHED: {
      const newUserObj: UserFetchedType = action.user
      if (
        state.user &&
        newUserObj.user &&
        state.user.referId &&
        state.user.totalRefers
      ) {
        newUserObj.user.referId = state.user.referId
        newUserObj.user.totalRefers = state.user.totalRefers
      }
      newState = merge(state, { user: newUserObj })
      newState.userLoaded = true
      if (action.user && action.user.referredBy && action.user.referredBy.id) {
        newState.subscriptionReferId = action.user.referredBy.id
      }
      return newState
    }
    case actionTypes.SUBSCRIBE_PROMO_CODE_SUCCESS:
      newState = merge(state, {
        subscriptionPromo: {
          promo: action.promo,
          userEnteredPromo: action.userEnteredPromo,
          welcomeBannerText: action.welcomeBannerText,
          valid: true
        }
      })
      newState.subscriptionText = action.subscriptionText
      return newState
    case actionTypes.SUBSCRIBE_PROMO_CODE_ERROR:
      newState = merge(state, {
        subscriptionPromo: {
          promo: undefined,
          userEnteredPromo: action.userEnteredPromo,
          valid: false
        }
      })
      newState.subscriptionText = action.subscriptionText
      return newState
    case actionTypes.SUBSCRIBE_PROMO_CODE_RESET:
      newState = merge(state, { subscriptionPromo: {} })
      newState.subscriptionText = { ...action.subscriptionText }
      return newState
    case actionTypes.SUBSCRIBE_CHANGE_PLAN: {
      const subscriptionPurchase = Object.assign({}, state.subscriptionPurchase)
      subscriptionPurchase.plan = action.plan
      subscriptionPurchase.error = undefined
      newState = merge(state, { subscriptionPurchase })
      newState.subscriptionText = action.subscriptionText
      return newState
    }
    case actionTypes.USER_LOGGED_OUT:
      newState = merge(state, { user: {}, recommendations: [] })
      return newState
    case actionTypes.USER_SIGN_IN:
      return merge(state, {
        loginRequestInProgress: true,
        loginFormErrorText: undefined
      })
    case actionTypes.USER_SIGN_IN_SUCCESS:
      return merge(state, {
        loginRequestInProgress: false,
        loginFormErrorText: undefined
      })
    case actionTypes.USER_SIGN_IN_FAIL:
      return merge(state, {
        loginRequestInProgress: false,
        loginFormErrorText: action.error.message,
        loginFormErrorCode: action.error.code
      })
    case actionTypes.USER_SIGN_UP:
      return merge(state, {
        loginRequestInProgress: true,
        loginFormErrorText: undefined
      })
    case actionTypes.USER_SIGN_UP_SUCCESS:
      return merge(state, {
        loginRequestInProgress: false,
        loginFormErrorText: undefined
      })
    case actionTypes.USER_SIGN_UP_FAIL:
      return merge(state, {
        loginRequestInProgress: false,
        loginFormErrorText: action.error.message,
        loginFormErrorCode: action.error.code
      })
    case actionTypes.USER_RESET_PASSWORD:
      return merge(state, {
        resetPasswordEmailSent: false
      })
    case actionTypes.USER_RESET_PASSWORD_SUCCESS:
      return merge(state, {
        resetPasswordEmailSent: true
      })
    case actionTypes.USER_RESET_PASSWORD_FAIL:
      return merge(state, {
        resetPasswordEmailSent: false,
        resetPasswordErrorCode: action.error.code,
        resetPasswordErrorText: action.error.message
      })
    case actionTypes.PASSWORD_RESET_WITH_ACTION_CODE_FAIL:
      return merge(state, { passwordResetMessage: action.message, passwordResetSuccess: false })
    case actionTypes.PASSWORD_RESET_WITH_ACTION_CODE_SUCCESS:
      return merge(state, { passwordResetMessage: null, passwordResetSuccess: true })
    case actionTypes.SUBSCRIBE_PURCHASE:
      return merge(state, {
        subscriptionPurchase: {
          inProcess: true,
          token: action.token,
          plan: (state.subscriptionPurchase || {}).plan,
          error: undefined,
          name: (state.subscriptionPurchase || {}).name
        }
      })
    case actionTypes.SUBSCRIBE_PURCHASE_SUCCESS:
      newState = merge(state, {
        subscriptionPurchase: {
          inProcess: false,
          plan: (state.subscriptionPurchase || {}).plan,
          name: (state.subscriptionPurchase || {}).name,
          isSuccessful: true
        }
      })
      newState.subscriptionPromo = undefined
      newState.user.isSubscribed = true
      newState.user.subscription_type = 'stripe'
      return newState
    case actionTypes.SUBSCRIBE_PURCHASE_ERROR:
      return merge(state, {
        subscriptionPurchase: {
          inProcess: false,
          name: (state.subscriptionPurchase || {}).name,
          plan: (state.subscriptionPurchase || {}).plan,
          token: (state.subscriptionPurchase || {}).token,
          error: action.error,
          isSuccessful: false
        }
      })
    case actionTypes.SUBSCRIBE_ADD_NAME: {
      return merge(state, {
        subscriptionPurchase: {
          name: action.name,
          inProcess: (state.subscriptionPurchase || {}).inProcess,
          token: (state.subscriptionPurchase || {}).token,
          plan: (state.subscriptionPurchase || {}).plan,
          error: (state.subscriptionPurchase || {}).error,
          last4: (state.subscriptionPurchase || {}).last4
        }
      })
    }
    case actionTypes.REDEEMING_GIFT: {
      return merge(state, {
        redeemingGiftErrorMessage: undefined,
        redeemingGift: true
      })
    }
    case actionTypes.REDEEM_GIFT_FAIL: {
      return merge(state, {
        redeemingGiftErrorMessage: action.msg,
        redeemingGift: false
      })
    }
    case actionTypes.REDEEM_GIFT_SUCCESS: {
      return merge(state, {
        redeemingGiftErrorMessage: undefined,
        redeemingGift: false
      })
    }
    case actionTypes.GIFT_PURCHASE_PROCESSING: {
      return merge(state, {
        purchasingGiftErrorMessage: undefined,
        purchasingGift: true
      })
    }
    case actionTypes.GIFT_PURCHASE_FAIL: {
      return merge(state, {
        purchasingGiftErrorMessage: action.message,
        purchasingGift: false
      })
    }
    case actionTypes.GIFT_PURCHASE_SUCCESS: {
      return merge(state, {
        purchasingGiftErrorMessage: undefined,
        purchasingGift: false,
        giftCode: action.giftCode
      })
    }
    case actionTypes.WINDOW_HAS_MOUSE:
      return merge(state, {
        hasMouse: action.hasMouse
      })
    case actionTypes.SET_QUIZ_RESULT:
      return merge(state, {
        quizResult: action.result,
        quizGenderPreference: action.genderPreference
      })
    case actionTypes.TOGGLE_PROMO_FLOW_MODAL:
      return setIn(state, 'promoFlowModalOpen', !state.promoFlowModalOpen)
    case actionTypes.SUBSCRIBE_MODAL_TOGGLE:
      return setIn(state, 'subscriptionModalOpen', !state.subscriptionModalOpen)
    case actionTypes.SHOW_PAYMENT_FORM_ON_PAYWALL:
      return merge(state, {
        isOnPayment: action.isOnPayment
      })
    case actionTypes.SET_HEADER_COPY_FOR_AUTH_AND_SUBSCRIBE_MODALS: {
      return merge(state, {
        textAuthHeader: action.textAuthHeader || state.textAuthHeader,
        textAuthSubheader: action.textAuthSubheader || state.textAuthSubheader,
        textSubscriptionConfirmationHeader: action.textSubscriptionConfirmationHeader || state.textSubscriptionConfirmationHeader,
        textSubscriptionConfirmationSubheader: action.textSubscriptionConfirmationSubheader || state.textSubscriptionConfirmationSubheader
      })
    }
    case actionTypes.USER_BLOG_EMAIL_LOADING: {
      return merge(state, {
        blogEmailErrorText: undefined,
        blogEmailIsLoading: true,
        blogEmailIsSuccess: false
      })
    }
    case actionTypes.USER_BLOG_EMAIL_SUCCESS: {
      return merge(state, {
        blogEmailErrorText: undefined,
        blogEmailIsLoading: false,
        blogEmailIsSuccess: true
      })
    }
    case actionTypes.USER_BLOG_EMAIL_ERROR: {
      return merge(state, {
        blogEmailErrorText: action.errorText,
        blogEmailIsLoading: false,
        blogEmailIsSuccess: false
      })
    }
    case actionTypes.CREATOR_PROMO_APPLIED: {
      return merge(state, {
        creatorDisplayName: action.creatorDisplayName
      })
    }
    case actionTypes.REFUND_STATUS_UPDATED: {
      return merge(state, {
        refund: action.refund
      })
    }
    case actionTypes.USER_EMAIL_UPDATE_FAIL:
      return merge(state, {
        emailChangeErrorCode: action.error.code,
        emailChangeErrorText: action.error.message,
        emailChangeSuccess: false
      })
    case actionTypes.USER_EMAIL_UPDATE_SUCCESS:
      return merge(state, {
        emailChangeErrorCode: '',
        emailChangeErrorText: '',
        emailChangeSuccess: true
      })
    case actionTypes.USER_EMAIL_UPDATE_RESET:
      return merge(state, {
        emailChangeErrorCode: '',
        emailChangeErrorText: '',
        emailChangeSuccess: false
      })
    case actionTypes.USER_PASSWORD_UPDATE_FAIL:
      return merge(state, {
        passwordChangeErrorCode: action.error.code,
        passwordChangeErrorText: action.error.message,
        passwordChangeSuccess: false
      })
    case actionTypes.USER_PASSWORD_UPDATE_SUCCESS:
      return merge(state, {
        passwordChangeErrorCode: '',
        passwordChangeErrorText: '',
        passwordChangeSuccess: true
      })
    case actionTypes.SOCIAL_LINK_ACCOUNT:
      return merge(state, {
        linkAccountRequestInProgress: true,
        linkAccountErrorText: undefined,
        linkAccountRequestProvider: action.provider
      })
    case actionTypes.SOCIAL_LINK_ACCOUNT_SUCCESS:
      return merge(state, {
        linkAccountRequestInProgress: false,
        linkAccountErrorText: undefined,
        linkAccountRequestProvider: undefined
      })
    case actionTypes.SOCIAL_LINK_ACCOUNT_FAIL:
      return merge(state, {
        linkAccountRequestInProgress: false,
        linkAccountErrorText: action.errorText,
        linkAccountRequestProvider: undefined
      })
    case actionTypes.REFRESH_USER_PROVIDERS:
      newState = cloneDeep(state)
      newState.user.providers = action.providers
      return newState
    case actionTypes.CANCEL_SUBSCRIPTION_SHOW_MODAL:
      return setIn(state, 'showCancelSubscriptionModal', true)
    case actionTypes.CANCEL_SUBSCRIPTION_CLOSE_MODAL:
      return merge(state, {
        showCancelSubscriptionModal: false,
        cancelSubscriptionStatus: undefined
      })
    case actionTypes.SUBSCRIBE_FETCH_CARD_INFO: {
      const subscriptionPurchase = cloneDeep(state.subscriptionPurchase)
      subscriptionPurchase.last4 = action.last4
      subscriptionPurchase.trialEnd = action.trialEnd
      subscriptionPurchase.lastPaymentSuccessful = action.lastPaymentSuccessful
      if (action.card) {
        subscriptionPurchase.card = action.card
      }
      return setIn(state, 'subscriptionPurchase', subscriptionPurchase)
    }
    case actionTypes.UPDATE_PAYMENT_METHOD_STATUS:
      return setIn(state, 'updatePaymentMethodStatus', action.status)
    case actionTypes.UPDATE_PAYMENT_METHOD_ERROR:
      return merge(state, {
        updatePaymentMethodStatus: REQUEST_STATUS.fail,
        updatePaymentMethodError: action.error
      })
    case actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS:
      newState = cloneDeep(state)
      newState.updatePaymentMethodStatus = REQUEST_STATUS.success
      newState.updatePaymentMethodError = undefined
      newState.chargeRequestStatus = undefined
      newState.chargeRequestMessage = undefined
      newState.subscriptionPurchase.last4 = action.last4
      return newState
    case actionTypes.UPDATE_APPLY_COUPON_SUBSCRIPTION_STATUS:
      return setIn(state, 'applyNewCouponStatus', action.state)
    case actionTypes.CANCEL_SUBSCRIPTION_STATUS:
      return setIn(state, 'cancelSubscriptionStatus', action.state)
    case actionTypes.SEARCH_FILTER_CLEAR: {
      const searchId = (action: actionTypes.SearchFilterClearType).id
      return setIn(state, ['searchFilters', searchId], undefined)
    }
    case actionTypes.SEARCH_FILTER_APPLY: {
      const { id, searchFilter } = (action: actionTypes.SearchFilterApplyType)
      const keys = Object.keys(searchFilter)

      let newFilteredState = state
      keys.forEach((type: SearchFilterKeyType): void => {
        if (type === 'gender') {
          if (!Object.keys(searchFilter[type] || {}).length) {
            // if no gender tag filters are applied, set as undefined to show all tracks
            newFilteredState = setIn(newFilteredState, ['searchFilters', id, type], undefined)
            return newFilteredState
          }
        }

        newFilteredState = setIn(newFilteredState, ['searchFilters', id, type], searchFilter[type])
      })
      return newFilteredState
    }
    case 'PLAY_AUDIO':
      return setIn(state, 'currentPlaying', action.audio)
    case 'CLEAR_AUDIO':
      return setIn(state, 'currentPlaying', undefined)
    case actionTypes.TOGGLE_SUBSCRIPTION_MODAL:
      return setIn(state, 'lockedModalVisible', !state.lockedModalVisible)
    case actionTypes.USER_RECOMMENDATIONS_FETCH_SUCCESS:
      return setIn(state, 'recommendations', action.recommendations)
    default:
      return state
  }
}
