// @flow
// import * as firebase from 'firebase/app'
import {
  updatePassword as updateFBpassword,
  unlink,
  updateEmail,
  sendPasswordResetEmail,
  confirmPasswordReset,
  signOut,
  signInWithRedirect,
  linkWithPopup,
  OAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  getAuth,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  fetchSignInMethodsForEmail,
  getRedirectResult
} from 'firebase/auth'
import type {
  CurrentUserType,
  FirebaseUserCredentialType,
  ProviderObjectType,
  ProvidersType,
  UserType
} from '../flowTypes'
import { events } from '../services/analytics'

export function currentUser (): ?UserType {
  const currentUser: ?CurrentUserType = getAuth().currentUser
  if (!currentUser || !currentUser.email) {
    return
  }
  const user: UserType = {
    id: currentUser.uid,
    email: currentUser.email,
    isAnonymous: currentUser.isAnonymous
  }
  if (currentUser.providerData) {
    user.providers = currentUser.providerData.map((provider: ProviderObjectType): ProvidersType => provider.providerId)
  }
  return (user: UserType)
}

export function monitorAuthChange (callback: (CurrentUserType) => any): () => {} {
  return onAuthStateChanged(getAuth(), callback)
}

export function signUpAsync (email: string, password: string): Promise<CurrentUserType> {
  return createUserWithEmailAndPassword(getAuth(), email.toLowerCase(), password)
}

function getQueryParam (paramName: string): 'signin' | 'signup' | null {
  const urlParams = new URLSearchParams(window.location.search)
  return (urlParams.get(paramName): any)
}

export function getRedirectResultAsync (): Promise<void> {
  return getRedirectResult(getAuth()).then((result: {providerId: string, user: {uid: string, email: string}, operationType: 'signIn' | 'signUp'}): any => {
    if (result) {
      let provider = 'oauth'
      if (result.providerId) {
        switch (result.providerId) {
          case 'facebook.com':
            provider = 'facebook'
            break
          case 'google.com':
            provider = 'google'
            break
          case 'apple.com':
            provider = 'apple'
            break
        }
      }
      const method = getQueryParam('method')
      if (method === 'signup') {
        events.signed_up(result.user?.email, provider)
      } else {
        events.signed_in(result.user?.email, provider)
      }
      return result.user
    }
  })
}

export function signInAsync (email: string, password: string): Promise<void> {
  return signInWithEmailAndPassword(getAuth(), email.toLowerCase(), password)
}
export function signInWithAppleAsync (linkAccount: boolean): Promise<FirebaseUserCredentialType> {
  // eslint-disable-next-line new-cap
  const provider = new OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')
  if (linkAccount) {
    return linkWithPopup(getAuth().currentUser, provider)
  } else {
    return signInWithRedirect(getAuth(), provider)
  }
}

export function signInWithFacebookAsync (linkAccount: boolean): Promise<FirebaseUserCredentialType> {
  // eslint-disable-next-line new-cap
  const provider = new FacebookAuthProvider()
  provider.addScope('email')
  if (linkAccount) {
    return linkWithPopup(getAuth().currentUser, provider)
  } else {
    return signInWithRedirect(getAuth(), provider)
  }
}

export function signInWithGoogleAsync (linkAccount: boolean): Promise<FirebaseUserCredentialType> {
  // eslint-disable-next-line new-cap
  const provider = new GoogleAuthProvider()
  provider.addScope('email')
  if (linkAccount) {
    return linkWithPopup(getAuth().currentUser, provider)
  } else {
    return signInWithRedirect(getAuth(), provider)
  }
}

export function signOutAsync (): Promise<void> {
  return signOut(getAuth())
}

export function sendPasswordResetLink (email: string): Promise<void> {
  return sendPasswordResetEmail(getAuth(), email)
}

export async function updateEmailAddress (email: string, password: string): Promise<any> {
  const user = getAuth().currentUser
  const credential = EmailAuthProvider.credential(user.email, password)
  await reauthenticateWithCredential(user, credential)
  return updateEmail(getAuth().currentUser, email)
}

export async function updatePassword (newPassword: string, oldPassword: string): Promise<any> {
  const user = getAuth().currentUser
  const credential = EmailAuthProvider.credential(user.email, oldPassword)
  await reauthenticateWithCredential(user, credential)
  return updateFBpassword(user, newPassword)
}

// Error Codes
//   auth/expired-action-code
//   auth/invalid-action-code
//   auth/user-disabled
//   auth/user-not-found
//   auth/weak-password
export async function confirmPasswordResetAsync (code: string, newPassword: string): Promise<void> {
  return confirmPasswordReset(getAuth(), code, newPassword)
}

export async function removeConnectionAsync (providerId: ProvidersType): Promise<any> {
  return unlink(getAuth().currentUser, providerId)
}

export async function addEmailAddressAsync (newEmail: string, password: string): Promise<any> {
  const credential = EmailAuthProvider.credential(newEmail, password)
  return linkWithCredential(getAuth().currentUser, credential)
}

export async function checkSignInEmail (email: string): Promise<Array<string>> {
  return fetchSignInMethodsForEmail(getAuth(), email.toLocaleLowerCase())
}
