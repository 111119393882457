// @flow

import type { PlayType } from '@services/audio'

export function playAudio (audio: PlayType): any {
  return {
    type: 'PLAY_AUDIO',
    audio: { ...audio }
  }
}

export function clearAudio (): any {
  return {
    type: 'CLEAR_AUDIO'
  }
}
