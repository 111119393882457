// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from './colors'
import { config, ThemedStyledProps } from 'react-awesome-styled-grid'
import type { ColorsType } from './colors'
import Link from 'next/link'

export type ButtonPropsType = {
  backgroundColor?: string,
  borderColor?: string,
  fontSize?: string,
  color?: ColorsType,
  padding?: string,
  borderRadius?: string,
  fontWeight?: string,
  height?: string,
  width?: string,
  minWidth?: string,
  disabledBackgroundColor?: ColorsType,
  disabledColor?: ColorsType,
  disabledBorderColor?: ColorsType,
  styles?: string,
  hoverOpacity?: boolean // if true, uses hover opacity instead standard hover styles
}

export const OutlineOnFocusVisible = `
  outline: none;
  box-shadow: 0 0 0 2pt transparent;
  transition: box-shadow .25s;
  :focus-visible {
    box-shadow: 0 0 0 2pt ${colors.white};
  }
`

export const CTAPrimary = styled.button((props: ButtonPropsType): any => ({
  border: `none`,
  borderRadius: props.borderRadius || '20px',
  width: props.width || undefined,
  fontFamily: 'Good Sans Bold',
  fontStyle: 'normal',
  fontWeight: props.fontWeight || 'bold',
  fontSize: props.fontSize || '12px',
  color: props.color || colors.white,
  letterSpacing: '0.03em',
  textTransform: 'uppercase',
  padding: props.padding || '10px 24px',
  height: props.height || '40px',
  backgroundColor: props.backgroundColor || colors.orange,
  cursor: 'pointer',
  outline: 'none',
  lineHeight: '150%',
  textAlign: 'center',
  minWidth: props.minWidth || '184px',
  '&:hover': {
    transition: 'all 0.3s',
    opacity: '0.8'
  },
  '&:disabled': {
    backgroundColor: props.disabledBackgroundColor || colors.orange,
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  OutlineOnFocusVisible,
  ...props.styles
}))

export const CTAPrimaryLink = styled(Link)`
  border-radius: 20px;
  font-family: 'Good Sans Bold';
  font-style: normal;
  font-seight: bold;
  font-size: 12px;
  color: ${colors.white};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  height: 40px;
  background-color: ${colors.orange};
  cursor: pointer;
  line-height: 150%;
  text-align: center;
  min-width: 184px;
  &:hover {
    transition: all 0.3s;
    opacity: 0.8;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  ${OutlineOnFocusVisible}
  ${(props: ButtonPropsType): any => props.styles && { ...props.styles }}
`

export const AnchorCTA = styled.a`
  display: block;
  border: none;
  border-radius: 20px;
  font-family: 'Good Sans Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background-color: #F2472A;
  cursor: pointer;
  outline: none;
  text-align: center;
  min-width: 120px;
  transition: box-shadow .25s;
  text-decoration: none;
  padding: 13px 55px;
`

export const CTASecondary = styled.button((props: ButtonPropsType): any => ({
  border: `1px solid ${props.color || colors.white}`,
  borderRadius: props.borderRadius || '20px',
  width: props.width || undefined,
  fontFamily: 'Good Sans Bold',
  fontStyle: 'normal',
  fontWeight: props.fontWeight || 'bold',
  fontSize: props.fontSize || '12px',
  color: props.color || colors.white,
  letterSpacing: '0.03em',
  textTransform: 'uppercase',
  padding: props.padding || '10px 24px',
  height: props.height || '40px',
  backgroundColor: props.backgroundColor || 'transparent',
  cursor: 'pointer',
  outline: 'none',
  lineHeight: '150%',
  textAlign: 'center',
  minWidth: props.minWidth || '184px',
  '&:hover, &:focus': {
    OutlineOnFocusVisible,
    transition: 'all 0.3s',
    color: props.hoverOpacity ? props.color : props.color === colors.orange ? colors.white : colors.midnightBlue,
    backgroundColor: props.hoverOpacity ? props.color : props.color === colors.orange ? colors.orange : colors.sand,
    opacity: props.hoverOpacity ? 0.5 : 1,
    border: `1px solid ${props.hoverOpacity ? props.color || colors.white : props.color === colors.orange ? colors.orange : colors.sand}`
  },
  ...props.styles
}))

export const CTASecondaryLink = styled(Link)`
  border: 1px solid ${(props: ButtonPropsType): string => props.color || colors.white};
  border-radius: 20px;
  font-family: 'Good Sans Bold';
  font-style: normal;
  font-seight: bold;
  font-size: 12px;
  color: ${(props: ButtonPropsType): string => props.color || colors.white};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  height: 40px;
  cursor: pointer;
  line-height: 150%;
  text-align: center;
  min-width: 184px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover,
  &:focus {
    transition: all 0.3s;
    outline: none;
    color: ${(props: ButtonPropsType): string =>
      props.hoverOpacity && props.color
        ? props.color
        : props.color === colors.orange
        ? colors.white
        : colors.midnightBlue};
    background-color: ${(props: ButtonPropsType): string =>
      props.hoverOpacity && props.color
        ? props.color
        : props.color === colors.orange
        ? colors.orange
        : colors.sand};
    opacity: ${(props: ButtonPropsType): number => (props.hoverOpacity ? 0.5 : 1)};
    border: 1px solid
      ${(props: ButtonPropsType): string =>
        props.hoverOpacity
          ? props.color || colors.white
          : props.color === colors.orange
          ? colors.orange
          : colors.sand};
  }
  ${OutlineOnFocusVisible}

  ${(props: ButtonPropsType): any => props.styles && { ...props.styles }}
`

export const CTASecondaryHyperlink = styled.a((props: ButtonPropsType): any => ({
  border: `1px solid ${props.color || colors.white}`,
  borderRadius: props.borderRadius || '20px',
  width: props.width || undefined,
  fontFamily: 'Good Sans Regular',
  fontStyle: 'normal',
  fontWeight: props.fontWeight || '700',
  fontSize: props.fontSize || '12px',
  color: props.color || colors.white,
  letterSpacing: '0.03em',
  textTransform: 'uppercase',
  padding: props.padding || '0 24px',
  height: props.height || '40px',
  lineHeight: props.height || '40px',
  textAlign: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  backgroundColor: props.backgroundColor || 'transparent',
  outline: 'none',
  '&:hover, &:focus': {
    transition: 'all 0.3s',
    opacity: 1,
    color: props.color === colors.orange ? colors.white : colors.midnightBlue,
    backgroundColor: props.color === colors.orange ? colors.orange : colors.sand,
    border: `1px solid ${props.color === colors.orange ? colors.orange : colors.sand}`
  },
  ...props.styles
}))

export const BurgerButton = (props: {
  onClick: () => void,
  isOpen: boolean | null
}): React.Node => {
  return (
    <Burger
      className="burger"
      onClick={props.onClick}
      isOpen={props.isOpen}
      aria-label={props.isOpen ? 'close navigation menu' : 'open navigation menu'}
    >
      <div />
    </Burger>
  )
}

const Burger = styled.button`
    background: none;
    border: none;
    border-radius:8px;
    display: block;
    height: 32px;
    margin: 0 11px 0 -4px;
    outline: none;
    position: relative;
    width: 32px;

    > div {
      position: absolute;
      top: 50%;
      left: 4px;
      width: 24px;
      height: 2px;
      border-radius: 1px;
      transform: translate(-50%, -50%);

      &:before,
      &:after {
        position: absolute;
        content: "";
        width: 24px;
        height: 2px;
        border-radius: 1px;
        background-color: ${colors.white};
      }

      &:before {
        transform: translateY(-5px);
        animation: ${(props: { isOpen: boolean }): string => props.isOpen === null ? '' : 'burger1 250ms linear'};
      }

      @keyframes burger1 {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-5px) rotate(0deg);
        }
      }

      &:after {
        transform: translateY(5px);
        animation: ${(props: { isOpen: boolean }): string => props.isOpen === null ? '' : 'burger2 250ms linear'};
      }

      @keyframes burger2 {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(5px) rotate(0deg);
        }
      }
    }

    ${(props: { isOpen: boolean }): ?string => {
    if (props.isOpen) {
      return `
                > div {
                  visibility: hidden;
                  &:before, &:after {
                    visibility: visible;
                    background-color: ${colors.white};
                    transform-origin: center center;
                    transform: translateY(0);
                    transition: all 250ms linear;
                  }
                  &:before {
                    transform: rotate(-45deg);
                    animation: burger1a 250ms linear;
                  }
                  @keyframes burger1a {
                    0%   { transform: translateY(0); }
                    50%  { transform: translateY(0); }
                    100% { transform: translateY(0) rotate(-45deg); }
                  }
                  &:after {
                    transform: rotate(45deg);
                    animation: burger2a 250ms linear;
                  }
                  @keyframes burger2a {
                    0%   { transform: translateY(0); }
                    50%  { transform: translateY(0); }
                    100% { transform: translateY(0) rotate(45deg); }
                  }
                }
              `
    }
  }}
  ${OutlineOnFocusVisible}
`
export const SocialButtons = styled.button`
  width: 100%;
  height: 40px;
  margin: 16px auto 0;
  background-color: ${(props: any): string => props.isOutlineStyle ? 'unset' : props.backgroundColor || colors.white};
  border-radius: 32px;
  cursor: pointer;
  outline: none;
  border: ${(props: any): string => props.isOutlineStyle ? `0.5px solid ${colors.white70}` : '0'};
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Good Sans Bold';
  font-size: 12px;
  line-height: unset;
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
  transition: opacity .6s cubic-bezier(0.19,1,0.22,1);
  color:  ${(props: any): string => props.isOutlineStyle ? colors.white : props.color || colors.black} !important;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
`
export const CTAPrimaryTransparent = styled.button((props: ButtonPropsType): any => ({
  border: `1px solid ${props.borderColor || colors.white}`,
  borderRadius: props.borderRadius || '5px',
  width: props.width || '100%',
  fontFamily: 'Good Sans Regular',
  fontWeight: props.fontWeight || '700',
  fontSize: props.fontSize || '12px',
  color: props.color || colors.white,
  letterSpacing: '1px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  margin: '0',
  height: props.height || '40px',
  backgroundColor: props.backgroundColor || colors.midnightBlue,
  cursor: 'pointer',
  transition: 'opacity .6s cubic-bezier(0.19, 1, 0.22, 1)',
  '&:hover': {
    opacity: '.8'
  },
  '&:disabled': {
    backgroundColor: props.disabledBackgroundColor || colors.midnightBlue,
    color: props.disabledColor || colors.midnightBlue,
    border: `1px solid ${props.disabledBorderColor || colors.white}`,
    cursor: 'not-allowed'
  },
  outline: 'none'
}))

export const UnderlineOnFocusVisible = `
  border: 1px solid transparent;
  transition: border-bottom .25s;
  :focus-visible {
    border-bottom: 1px solid ${colors.white};
  }
`

export const NavButton = styled.button`
  background: none;
  color: ${colors.sand};
  cursor: pointer;
  font-family: 'Good Sans Medium';
  font-size: 12px;
  line-height: 18px;
  height: 33px;
  outline: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  ${(props: ThemedStyledProps): string => config(props).media.md`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: ${colors.white};
  `}
  ${(props: ThemedStyledProps): string => config(props).media.xl`
      font-size: 12px;
  `}
  ${UnderlineOnFocusVisible}
  ${(props: ThemedStyledProps): string => props.styles}
`
// Orange pill button with white text, on hover is 80%
export const CTAPrimaryOrange = styled.button`
  background-color: ${colors.orange};
  border: none;
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  font-family: "Good Sans Bold";
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  height: 48px;
  letter-spacing: 0.3px;
  line-height: 18px;
  margin: 0px;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  :hover,
  :focus {
    opacity: 0.8;
  }
  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  ${OutlineOnFocusVisible}
`
// Blue pill button with sand text, on hover is 80%
export const CTAPrimaryBlue = styled(CTAPrimaryOrange)`
  background-color: ${colors.accentBlue};
`
// Orange button underlined
export const CTAOrangeUnderlined = styled.button`
  font-family: "Good Sans Bold";
  font-style: normal;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${colors.tintOrange};
  text-decoration: underline;
  border: none;
  background: transparent;
  cursor: pointer;
  letter-spacing: 0.3px;
  border-radius: 8px;
  :hover {
    opacity: 0.8;
  }
  ${OutlineOnFocusVisible};
`
export const OrangeUnderlinedLink = styled.a`
  font-family: "Good Sans Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: ${colors.tintOrange};
  text-decoration: underline;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    opacity: 0.8;
  }
  letter-spacing: 0.3px;
  ${OutlineOnFocusVisible};
`

// Sand button underlined
export const CTASandUnderlined = styled.button`
  font-family: "Good Sans Bold";
  font-style: normal;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${colors.sand};
  text-decoration: underline;
  border: none;
  background: transparent;
  cursor: pointer;
  letter-spacing: 0.3px;
  border-radius: 8px;
  :hover {
    opacity: 0.8;
  }
  ${OutlineOnFocusVisible};
`

// White outline pill button with white text, on hover white background with midnight text
export const CTASecondaryWhite = styled.button`
  background: none;
  border: 1px solid ${colors.white};
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  font-family: "Good Sans Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  height: 48px;
  letter-spacing: 0.3px;
  line-height: 18px;
  margin: 0px;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  :hover,
  :focus {
    background-color: ${colors.white};
    color: ${colors.midnightBlue};
  }
  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  ${OutlineOnFocusVisible}
`
export const InlineUnderlineLink = styled.span`
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    border-radius: 2px;
    background: white;
    width: 100%;
    transition: 0.1s ease-in-out;
    transform: translate3d(0, 2px, 0);
    will-change: transform;
  }
  &:hover,
  &:focus {
    &:after {
      transform: translate3d(0, 0px, 0);
    }
  }
`
