// @flow
import * as React from 'react'
import { colors } from '../colors'

export const SmallXIcon = (props: {
  backgroundColor?: string,
  xColor?: string,
  size?: number
}): React.Node => {
  const backgroundColor = props.backgroundColor || colors.white
  const xColor = props.xColor || colors.red
  const size = props.size || 20

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height={size} width={size} rx={size / 2} fill={backgroundColor} />

      <path
        d="M12.9346 6.9563L6.95636 13.1251"
        stroke={xColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95661 6.95581L12.9349 13.1247"
        stroke={xColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
