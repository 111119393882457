// @flow
import * as React from 'react'
import { colors } from '../colors'

export const DipseaLogo = (props: {
  color?: string,
  size?: number
}): React.Node => {
  const color = props.color || colors.white
  const width = props.size || 138
  const height = props.size ? props.size * 0.333 : 46

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 402 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M155.8 30.9C145.7 30.9 137.3 36.3 133.2 41.1L132.5 42L132.3 31.9V31.7L118.4 35.1V35.4C118.4 35.4 123.3 36.4 123.6 44.3V121.9C123.6 130.5 118.4 131.5 118.4 131.5V131.8H123.6H132.2H141.3V131.5C141.3 131.5 132.2 130.5 132.2 121.9V111.1V98.3C132.2 95.8 133.6 93.5 136.5 93.5C143.9 93.5 148.8 100 159.1 100C177.9 100 189.7 84.4 189.7 65.2C189.7 43 175.1 30.9 155.8 30.9ZM157.1 94.8C146.8 94.8 143.4 90.9 136.6 90.9C134.2 90.9 132.4 92.3 131.8 93.2H132.2L132.3 58.3C132.3 45.6 142.2 32.9 155.1 32.9C170.9 32.9 180.7 45.8 180.7 65.1C180.8 76.8 174.4 94.8 157.1 94.8Z" fill={color}/>
      <path d="M227 59.9C216.1 59 206.3 58.1 206.3 49.4C206.3 39.1 215.8 32.9 225.2 32.9C233.9 32.9 242.2 38.7 247.5 49.9L247.7 50.1L253.5 42C248.7 37.1 238.7 30.8 225.6 30.8C208.8 30.8 198.2 39.7 198.2 49.5C198.2 65.7 214.8 67.1 224.6 67.9L226.1 68C234.9 68.7 248.1 69.8 248.1 80.2C248.1 90.5 240.1 97.4 228.2 97.4C216.4 97.4 206.2 88.9 202.6 76.6L202.3 76.3L196 83.6C201.3 94 212.8 99.9 228.1 99.9C239.6 99.9 257.2 94.6 257.2 80.3C257.1 62.5 241.4 61.1 227 59.9Z" fill={color}/>
      <path d="M102.1 23.6C105.856 23.6 108.9 20.5108 108.9 16.7C108.9 12.8892 105.856 9.79999 102.1 9.79999C98.3445 9.79999 95.3 12.8892 95.3 16.7C95.3 20.5108 98.3445 23.6 102.1 23.6Z" fill={color}/>
      <path d="M107.8 89.9V33.6V33.3L93.9 35.9V36.2C93.9 36.2 98.5 37.1 99 44.3V90C98.4 97.2 93.9 98.1 93.9 98.1V98.4H99H107.8H112.9V98C113 98 108.4 97.1 107.8 89.9Z" fill={color}/>
      <path d="M395.1 87.7V50.8C395.1 38.3 382.1 30.8 369.1 30.8C358.3 30.8 348.2 34.3 339.9 40.9L344.2 49H344.4C352.4 38.3 360.3 32.9 369.5 32.9C373.8 32.9 377.8 34.6 380.9 37.7C384.4 41.2 386.5 46.4 386.5 51.3V52.1C386.5 62.8 374.8 62.9 362.5 63C347.5 63.2 332.5 63.3 332.5 80.6C332.5 91.6 341 100.2 361 100.2C374.3 100.2 383.5 90.8 387.1 84.9L386.4 84.3V98.2L395.3 97.6L401.6 97.2V96.9H401.5H401.6C401.5 96.9 395.1 96.3 395.1 87.7ZM386.3 70.2C386.3 79.8 376.9 97.6 360.8 97.6C348.9 97.6 340.9 90.7 340.9 80.4C340.9 69 353.9 68.1 364.4 67.3C370.5 66.9 380.7 66.1 385.7 60L386.4 59.1L386.3 70.2Z" fill={color}/>
      <path d="M38.1 0.200012H5.79999H0.600006V0.5C0.600006 0.5 5.79999 1.50001 5.79999 10.1V10V88.4V88.3C5.79999 96.9 0.600006 97.9 0.600006 97.9V98.2H5.79999L40.2 98.3C65.6 98 85.2 77.9 85.2 49.6C85.1 20.8 63.5 0.200012 38.1 0.200012ZM36.8 95.5H14.7V2.89999H36.8C59.8 2.89999 76.3 21.7 76.3 49.8C76.2 74.8 61.4 95.5 36.8 95.5Z" fill={color}/>
      <path d="M301.3 30.9C276.5 30.9 265.9 46.6 265.9 65.2C265.9 71.1 265.9 80.1 265.9 80.1C265.9 92.6 278.9 100.1 291.9 100.1C303.7 100.1 314.6 95.9 323.4 88L317.1 82.1C307.4 92.9 301 98 291.4 98C280 98 274.4 90.1 274.4 79.6V78.8C274.4 68.1 285.4 68 297.7 67.9C312.7 67.7 327.7 66.1 327.7 48.9C327.9 38 318.7 30.9 301.3 30.9ZM295.9 63.5C289.8 63.9 280.3 64.7 275.3 70.8L274.6 71.7L274.7 65.4C274.7 47.9 282.2 33 299.7 33C311.6 33 319.3 38.8 319.3 49.1C319.4 60.4 306.4 62.8 295.9 63.5Z" fill={color}/>
    </svg>
  )
}
