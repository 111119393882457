// @flow

export const DipseaContentfulAssets = {
  'gifting-content-universe-tablet-top.png': 'https://images.ctfassets.net/03of3q5qzesq/67mjnSjTuukAxzCaKboRGI/8abc9a76ee34927217264062b2799982/gifting-content-universe-tablet-top.png',
  'gifting-content-universe-mobile-top.png': 'https://images.ctfassets.net/03of3q5qzesq/5rtxi6d5l9LHxrriUHsXxu/5ad2afbba2504cae5627e2f8c9287df0/gifting-content-universe-mobile-top.png',
  'gifting-content-universe-desktop.png': 'https://images.ctfassets.net/03of3q5qzesq/5JvS8SZRZXC4s7ZbBTEh0M/751002b4ce70604c40efbadaad4edf2b/Gifting_Content_Universe_Desktop_3x_2.png',
  'gifting-content-universe-tablet-bottom.png': 'https://images.ctfassets.net/03of3q5qzesq/Vy2LU67itcYooRpGDFYZJ/aa7d687ee57d94f3d046313635274237/gifting-content-universe-tablet-bottom.png',
  'gifting-redeem-background-mobile.jpg': 'https://images.ctfassets.net/03of3q5qzesq/Q9KMT68DaNslx2H8fR1Us/e5a3614c913a8c7a912f1c81813888e9/gifting-redeem-background-mobile.jpg',
  'gifting-redeem-background-desktop.jpg': 'https://images.ctfassets.net/03of3q5qzesq/5PnbEZYNJsKw5XK26kgsm5/d412ac84a5ff51d2442b091d2077b6e8/gifting-redeem-background-desktop.jpg',
  'gifting-content-universe-mobile-bottom.png': 'https://images.ctfassets.net/03of3q5qzesq/pRS3dfRyflQNiNiD2zzjF/dd90902019979cc34895f7e3cdf19676/gifting-content-universe-mobile-bottom.png',
  'dipsea_desktop_gifting_hero-v3.mp4': 'https://videos.ctfassets.net/03of3q5qzesq/2pM3OnhG1gRPjEIGJTwyUb/9ac1872650767c91b8012da862575f87/dipsea_desktop_gifting_hero-v3.mp4',
  'share-image-dusk.jpg': 'https://images.ctfassets.net/03of3q5qzesq/6j6v5i0t8LjxePAkxuqGEm/4ab7df85672df9e5c77b6cbcbf1aa457/share-image-dusk.jpg',
  'dipsea_mobile_gifting_hero.jpg': 'https://images.ctfassets.net/03of3q5qzesq/7wYeg8QbOIW3HDFet03a37/28be24eb62c14d3784a4470971b7bc4f/dipsea_mobile_gifting_hero.jpg',
  'redeem.png': 'https://images.ctfassets.net/03of3q5qzesq/7kqgJMVQrOzFLB1S2rIGKw/f476dfa2e999fa2db2af71d0a1be122b/redeem.png',
  'Header-Video-v3.mp4': 'https://videos.ctfassets.net/03of3q5qzesq/2sNY67ds3yxije1CNjfOI8/1867d3bc3af9bedc6be222ba7e7652c6/Header-Video-v3.mp4',
  'our-universe-tablet-top.png': 'https://images.ctfassets.net/03of3q5qzesq/1aWTZxKQlO5RjJbsDFzmYD/a62733326a3e11c12a997bc007eeb957/our-universe-tablet-top.png',
  'our-universe-mobile-top.png': 'https://images.ctfassets.net/03of3q5qzesq/7DPRfc65BOepMxhMNV93Fv/c4a18627a19c4f09dd9778f2b6835231/Homepage_Mobile_Top_Image_Cropped.png',
  'our-universe-mobile-bottom.png': 'https://images.ctfassets.net/03of3q5qzesq/3Ex1BeXmdjrjY7qnNNKHsk/e1667988a1ac34874640d834ef49aab2/Homepage_Mobile_Bottom_Image_Cropped.png',
  'our-universe-tablet-bottom.png': 'https://images.ctfassets.net/03of3q5qzesq/6wMUH5Iv1SiU9MeXiHynfX/60f55db09d907ef2110b4fbd9b3d2a4d/our-universe-tablet-bottom.png',
  'drive-to-download-phone.png': 'https://images.ctfassets.net/03of3q5qzesq/1n4Rztq6zsNr3yPq6LYrhZ/f4edc79f70cc11a80a3699f58435222b/drive-to-download-phone.png',
  'gifting-mobile-email_2x.png': 'https://images.ctfassets.net/03of3q5qzesq/41rrMMREpe8r4hk4q5Vjnr/0658bcf9e5622d5f51bfc8feacddffaa/gifting-mobile-email_2x.png',
  'gifting-desktop-email_2x.png': 'https://images.ctfassets.net/03of3q5qzesq/2zaNKxMKnUJwlThfahXJf0/8acfe225e2abb93cd9f9591418b0ac2a/gifting-desktop-email_2x.png',
  'gifting-mobile-mail_2x.png': 'https://images.ctfassets.net/03of3q5qzesq/7aUKImjirp72hJb2SXqPY3/0eab6db5eb6d3beb9acdf58d9c92389f/gifting-mobile-mail_2x.png',
  'gifting-desktop-mail_2x.png': 'https://images.ctfassets.net/03of3q5qzesq/28xzL7ifkPrQmJlcCxhuCu/850ce82516cc14ef69c82e9dce31eed4/gifting-desktop-mail_2x.png',
  'dipsea_ourstory_write.jpg': 'https://images.ctfassets.net/03of3q5qzesq/50P0SYUERRBvZte0cKR3Mb/9fcd0977492aa500fafdbae00d43c7b5/dipsea_ourstory_write.jpg',
  'dipsea_ourstory_mixandmaster.jpg': 'https://images.ctfassets.net/03of3q5qzesq/1B0Wdg29iEKReMpYahtGuu/fe81846a3afbd9c3edd664ff465178f7/dipsea_ourstory_mixandmaster.jpg',
  'dipsea_ourstory_record.jpg': 'https://images.ctfassets.net/03of3q5qzesq/3NEtH2jxNr6W9crhHsaarr/6c818617ba61eabb3f33309bd6962379/dipsea_ourstory_record.jpg',
  'About-Us-Scrolling-Image-v4.png': 'https://images.ctfassets.net/03of3q5qzesq/lpQjTUzT2ZM3hQwJ9fUhh/a87af7d689845ff216aa4b3c02be3dd8/About-Us-Scrolling-Image-v4.png',
  'About-Us-Mobile.png': 'https://images.ctfassets.net/03of3q5qzesq/3Zh7q5l8sSAsf4VP7R6tK0/d0874b589edf2d7bb9527701f2b96951/About-Us-Mobile.png',
  'home-dawn.jpg': 'https://images.ctfassets.net/03of3q5qzesq/6WJTurvySZQ5wGfYODl6OZ/30f7fe51781ff6ac05296a8f944f0418/home-dawn.jpg',
  'dipsea_desktop_ourstory_background_glow.jpg': 'https://images.ctfassets.net/03of3q5qzesq/2MilyuwiUABEa6Dr72Pz33/b247eda7862a9d68ae458e11d7e2496f/dipsea_desktop_ourstory_background_glow.jpg',
  'Voice-Actor-Dusk.jpg': 'https://images.ctfassets.net/03of3q5qzesq/Ydyicy9h828OgEzaIMPuO/be5bc99407321d7ae0e3251554f51e69/Voice-Actor-Dusk.jpg',
  'Voice-Actor-Dawn.jpg': 'https://images.ctfassets.net/03of3q5qzesq/3e4liQ0DHOovBevAgn3iUF/34a79123438f7a31c793284676f4f8d5/Voice-Actor-Dawn.jpg',
  'Voice-Actor-Dusk-Mobile.jpg': 'https://images.ctfassets.net/03of3q5qzesq/4ub79EJCPNdg45jxpjlFUg/3997f9ebc72e4e20a351c76879ff2f40/Voice-Actor-Dusk-Mobile.jpg',
  'Dipsea-Vday-TikTok-Video': 'https://videos.ctfassets.net/03of3q5qzesq/5PBEWHPMyPgko8szDS6SJq/211cb19fd97cd7866a8b7d8a96bb1da2/dipsea-vday-vid.mov',
  'vday-landing-img': 'https://images.ctfassets.net/03of3q5qzesq/3UKvhnUwceCnEwUdCplEn1/b8831612c2a93a7ae1baf903ec261771/v-day-lander.jpg',
  'tiktok-template-full-1': 'https://images.ctfassets.net/03of3q5qzesq/59GK3l52VbOLMC7JzFbS0D/245265723b9911e0c06218dd3aa336da/Dating-Myself-v2.png',
  'tiktok-template-full-2': 'https://images.ctfassets.net/03of3q5qzesq/6143yDBRQavIcizCX71HT5/7560dad44898831d980cf6c54c85c89f/Ex-v2.png',
  'tiktok-template-full-3': 'https://images.ctfassets.net/03of3q5qzesq/2JG5nPN0EQuC8ACTfbTiLF/d430e482ad7101b7e21be01369131f9d/Slide-In-DMs-v2.png',
  'tiktok-template-preview-1': 'https://images.ctfassets.net/03of3q5qzesq/dGLi1SoUXrkA6EBrYOvMq/29a0d9d4b34afcf19bbfed407c603c1b/Dating-Myself.png',
  'tiktok-template-preview-2': 'https://images.ctfassets.net/03of3q5qzesq/3D03JCpj6kpKccSAY8Me9K/370fe9139a155bf83f9094c0edca8356/Ex.png',
  'tiktok-template-preview-3': 'https://images.ctfassets.net/03of3q5qzesq/TXNtnf7BlVDJjgP8pdKoF/266e351b37d31a68ba59e8e306e8b6e9/Slide-in-DMs.png',
  'marketing-landing-bottom-desktop': 'https://images.ctfassets.net/03of3q5qzesq/60VjEJcYGje87mgA8u2EkW/8039715cc734d77c5985c13585f9be9e/marketing-landing-bottom-bg.png',
  'marketing-landing-bottom-mobile': 'https://images.ctfassets.net/03of3q5qzesq/7eSqSORRD3dcqjVJz1HSkv/d62636e4eb3cbbb9444bf947f3f8d158/marketing-landing-bottom-mobile.png',
  'Quiz_LandingPage_HeroImage_Mobile_Smaller.jpg': 'https://images.ctfassets.net/03of3q5qzesq/qcd8xU7JDxtkwywvKjaLs/c5f4ddf41ccc11850d8295218bbcf6aa/Quiz_LandingPage_HeroImage_Mobile_Smaller.jpg', // 375 px / 758 px
  'Quiz_LandingPage_HeroImage_Desktop_Tablet_Smaller.jpg': 'https://images.ctfassets.net/03of3q5qzesq/3YFxr7XORokOj5SdkCrO6s/6ea958206b62be24e94fa5ac60f53f94/Quiz_LandingPage_HeroImage_Desktop_Tablet_Smaller.jpg', // 1440 px / 1014 px
  auth_modal_gradient: 'https://images.ctfassets.net/03of3q5qzesq/6EFcbMe1vUZgjAmz92Zpkp/148d4dbb2899330734ca4b64c17a3c27/auth__modal_gradient.png',
  trope_quiz_intro_mobile: 'https://images.ctfassets.net/03of3q5qzesq/sCqmgsTTGmohPVbW8EVrW/319a305d2d3b4bb6b30930cde3307d89/Trope_Quiz_Landing_Page_Hero_Image_Mobile_1x.png', // 375px / 758px
  trope_quiz_intro_desktop: 'https://images.ctfassets.net/03of3q5qzesq/nirUHfOsau9ichs7urBlB/7d86775a6a521071ea1ff255419dbc7d/Trope_Quiz_Landing_Page_Hero_Image_Desktop.png', // 1440px / 1014px
  quiz_hunk_cecelia: 'https://images.ctfassets.net/03of3q5qzesq/6GDCeD0frIT0NPS6jaerm7/e0b59587aa145132e85641039e78ab6e/quiz-hunk-Cecelia.png', // 400 x 400px
  quiz_hunk_charli: 'https://images.ctfassets.net/03of3q5qzesq/6ztCNWJHVpThJTcumYTqq7/7c70899a954d780483cccce639c11f2d/quiz-hunk-Charli.png', // 400 x 400px
  quiz_hunk_jack: 'https://images.ctfassets.net/03of3q5qzesq/2r7fWkMjnGM6inz3M1cxhj/1c9147bb32787e189d128315f9e77783/quiz-trope-Hometown.png', // 400 x 400px
  quiz_hunk_james: 'https://images.ctfassets.net/03of3q5qzesq/6tu6wqkM2KSnmMdCPzktjS/734b9d8d0679dc57486e80f91c3ddff9/quiz-hunk-James.png', // 400 x 400px
  quiz_hunk_jennifer: 'https://images.ctfassets.net/03of3q5qzesq/2Kc23JUDq8RPguydKY2mpU/20c77b809b4940739969198c18907abd/quiz-hunk-Jennifer.png', // 400 x 400px
  quiz_hunk_malcolm: 'https://images.ctfassets.net/03of3q5qzesq/64TElfIu7j5OpPdcEJZtUv/cb4180128c26e1b28ca17eb33589b311/quiz-hunk-Malcolm.png', // 400 x 400px
  quiz_trope_hometown: 'https://images.ctfassets.net/03of3q5qzesq/2r7fWkMjnGM6inz3M1cxhj/1c9147bb32787e189d128315f9e77783/quiz-trope-Hometown.png', // 400 x 400px
  quiz_trope_lecture_me: 'https://images.ctfassets.net/03of3q5qzesq/6ccLJjqLiITImhL3Hwpkdy/b06bb756122e401ba037023ce808e739/quiz-trope-Lecture_Me.png', // 400 x 400px
  quiz_trope_playing_the_field: 'https://images.ctfassets.net/03of3q5qzesq/5RMI2uuByigU9G96uGy3YC/a929e336bcbb60e834714bd903698d59/quiz-trope-Playing_the_Field.png', // 400 x 400px
  quiz_trope_the_figure: 'https://images.ctfassets.net/03of3q5qzesq/7f9frA0V1wwJjblIwkMD3w/452bf0c87b9b34ee8d0f1911d8b14a57/quiz-trope-The_Figure.png', // 400 x 400px
  quiz_trope_the_local: 'https://images.ctfassets.net/03of3q5qzesq/2iOUm8fxt2RH0TzOnfuguJ/209809d788db24c2350f1eac9b79bc3c/quiz-trope-The_Local.png', // 400 x 400px
  quiz_trope_the_tradition: 'https://images.ctfassets.net/03of3q5qzesq/7IxkQLgaROROm0798ePbIE/8e82163d47db2f25a0a9ceeb084c51e2/quiz-trope-The_Tradition.png', // 400 x 400px
  quiz_fantasy_forbidden_fruit: 'https://images.ctfassets.net/03of3q5qzesq/7ti2U3XulLzP3qCdZqXyan/7346895a196a5ec0387c95004c49bc59/735_ForbiddenFruit1_SarahAliceRabbit_Compressed.png', // 400 x 400px
  quiz_fantasy_close_companions: 'https://images.ctfassets.net/03of3q5qzesq/3g5kjdRrBJpugG3TjbBpRs/c779a1d5de0356c2ae1141f16339f529/799_CloseCompanions1_Midjourney_Compressed.png',
  quiz_fantasy_courted: 'https://images.ctfassets.net/03of3q5qzesq/2yKiV0T7cR5g6W8rsngOsE/bae41fd2641140e52b5a77e85048c48b/699_CourtedI_LouisaCannell_Compressed.png',
  quiz_fantasy_night_school: 'https://images.ctfassets.net/03of3q5qzesq/2qHbuTtawgiCYpOl4QkOw4/ad56c9a763479b4532ed17aaceb9b51b/672_NightSchool1_SarahAliceRabbit-fs8.png',
  blog_email_capture_header: 'https://images.ctfassets.net/03of3q5qzesq/1uTbGeGJNKrtQS3kQ5HBBC/e15eb1f317565775ce882ac8cf5e58da/blog-email-backgound.png', // 594px x 230px,
  giftEmailPrideMobile: 'https://images.ctfassets.net/03of3q5qzesq/7JQTeoN9yDkaQjsvrf4izC/6f1f4131d7cf5fcc448b9d45fa4c1a30/pride-gift-mobile.png', // 932px / 480px
  giftEmailPrideDeskop: 'https://images.ctfassets.net/03of3q5qzesq/7D7i6jKeRvDHX9pzpE2bjK/36f0a3ea4f383e9471cc9013c9ea0da1/pride-gift-desktop.png' // 2188px / 1134px
}
