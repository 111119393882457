// @flow
export function sortSlugs (slugs?: { [key: string]: number }): string[] {
  if (slugs) {
    return Object.keys(slugs).sort((a: string, b: string): number => slugs[a] - slugs[b])
  }
  return []
}

export function getDurationText (durInMinutes: number, accessible: boolean = false): string {
  const durationInMinutes = Math.round(durInMinutes)
  if (durationInMinutes < 60) {
    if (accessible) {
      return `${Math.round(durationInMinutes)} ${durationInMinutes > 1 ? 'minutes' : 'minute'}`
    }
    return `${Math.round(durationInMinutes)} min`
  }
  const hours = Math.floor(durationInMinutes / 60)
  const minutes = durationInMinutes % 60
  if (minutes === 0) {
    if (accessible) {
      return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
    }
    return `${hours}hr`
  }

  if (accessible) {
    return `${Math.round(hours)} ${hours > 1 ? 'hours' : 'hour'} ${Math.round(minutes)} ${minutes > 1 ? 'minutes' : 'minute'} `
  }

  return `${Math.round(hours)} hr ${Math.round(minutes)} min`
}

export function convertDeepLink (link: string | null): string {
  if (!link) {
    return ''
  }
  let newLink = link
  if (newLink.includes('https://www.dipseastories.com/u')) {
    newLink = link.replace('https://www.dipseastories.com/u/', '/')
  }
  if (newLink.includes('https://app.dipseastories.com/stories/')) {
    newLink = link.replace('https://app.dipseastories.com', '')
  }
  if (newLink === '/categories/hunks-category') {
    newLink = '/characters'
  }
  if (newLink === '/categories/audiobooks-category') {
    newLink = '/tags/audiobook-tag'
  }
  if (newLink === '/categories/serialized-stories-category') {
    newLink = '/series'
  }
  if (newLink === '/categories/characters-category') {
    newLink = '/characters'
  }
  if (newLink === '/categories/creator-audios-category') {
    newLink = '/creators'
  }
  if (newLink === '/categories/sexy-reads-category') {
    newLink = '/books'
  }
  if (newLink === '/categories/new-releases-category') {
    newLink = '/stories/new-releases'
  }
  if (newLink === '/favorites') {
    newLink = '/my-stories/favorites'
  }
  if (newLink === '/listenHistory') {
    newLink = '/my-stories/listen-history'
  }
  if (newLink === '/book-list') {
    newLink = '/books'
  }
  if (newLink.includes('categories/bananaamarie-category')) {
    newLink = '/creators/bananaamarie-category'
  }
  if (newLink.includes('categories/mairsyy-category')) {
    newLink = '/creators/mairsyy-category'
  }
  if (newLink.includes('categories/therashadexperience-category')) {
    newLink = '/creators/therashadexperience-category'
  }
  if (newLink.includes('categories/gregrogstad__-category')) {
    newLink = '/creators/gregrogstad__-category'
  }
  if (newLink.includes('categories/joshdreamin-category')) {
    newLink = '/creators/joshdreamin-category'
  }
  if (newLink.includes('categories/kenna_bethany-category')) {
    newLink = '/creators/kenna_bethany-category'
  }
  if (newLink.includes('categories/beckymissal-category')) {
    newLink = '/creators/beckymissal-category'
  }
  if (newLink.includes('all-series')) {
    newLink = '/audiobooks'
  }
  return newLink
}
