// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const SearchIcon = (props: PropsType): React.Node => {
  return (
    <svg
      height={props.size || 30}
      width={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1003 20.9105C7.15901 20.9105 3.1413 16.9212 3.1413 12.0148C3.1413 7.10843 7.15901 3.11908 12.1003 3.11908C17.0415 3.11908 21.0592 7.10843 21.0592 12.0148C21.0592 16.9212 17.0415 20.9105 12.1003 20.9105M29.4465 26.8057L22.0079 19.4199C21.9325 19.3448 21.854 19.2796 21.7723 19.2231C23.2958 17.2144 24.2005 14.7191 24.2005 12.0148C24.2005 5.38984 18.7724 0 12.1003 0C5.42816 0 0 5.38984 0 12.0148C0 18.6402 5.42816 24.0297 12.1003 24.0297C14.7138 24.0297 17.1389 23.2034 19.1179 21.7933C19.1839 21.8903 19.2593 21.9807 19.3441 22.0647L26.7827 29.4507C27.1502 29.8157 27.6308 30 28.1146 30C28.5984 30 29.079 29.8157 29.4465 29.4507C30.1847 28.7209 30.1847 27.5356 29.4465 26.8057"
        fill={props.color || colors.white}
      />
    </svg>
  )
}

export default SearchIcon
