// @flow
import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown'
import { MARKS } from '@contentful/rich-text-types'
import { colors } from '../components/shared'
import type { TrackPropsType } from '../models/Track'
import { convertDeepLink } from '../models/helpers'
import type { DipseaContentType } from '../flowTypes'
import { convertTrackBySlugToProps } from '../models/Track'
import { getLibrary } from '../services/dipseaApi'

const space = process.env.NEXT_PUBLIC_CONTENTFULL_SPACEID
const accessToken = process.env.NEXT_PUBLIC_CONTENTFULL_ACCESS_TOKEN

const client = require('contentful').createClient({
  space,
  accessToken
})

export type ImageType = {
  title: string,
  file: {
    url: string,
    details: { size: number, image: {width: number, height: number} },
    fileName: string,
    contentType: string
  },
  fields?: {
    title?: string,
    file: {
      url: string,
      details: { size: number, image: {width: number, height: number} },
      fileName: string,
      contentType: string
    }
  }
}
export type BlogCategoryType = {
  id: string,
  name: string
}
export type AuthorType = {
  id: string,
  authorName: string | null,
  shortBio: string | null,
  image: ImageType | null,
  twitter: string | null,
  instagram: string | null
}
export type BlogPostType = {
  id: string,
  title: string,
  slug: string,
  headTitleTagText?: string,
  headMetaNameDescription?: string,
  excerpt: string,
  date: string,
  author: AuthorType | null,
  categories: BlogCategoryType[] | null,
  blogContent: string,
  blogContent1: string | null,
  blogContent2: string | null,
  postPhoto?: ImageType | null,
  related?: BlogPostType[] | null,
  trackPreviews?: TrackPropsType[] | null,
  tryFreeButtonText: string,
  tryFreeButtonColor: string,
  tryFreeButtonLink: string,
  tryFreeText1: string,
  tryFreeText2: string,
  tryFreeBackground: string,
  thematicTag?: string[]
}

function convertItemIntoImage (item?: any): ImageType | null {
  if (!item) {
    return null
  }

  const image = {
    id: item.sys.id,
    title: item.fields?.title,
    file: {
      url: item.fields?.file.url,
      details: item.fields?.file.details,
      fileName: item.fields?.file.fileName,
      contentType: item.fields?.file.contentType
    }
  }
  return image
}

function convertItemIntoAuthor (item?: any): AuthorType | null {
  if (!item) {
    return null
  }

  const author = {
    id: item.sys.id,
    authorName: item.fields?.authorName || null,
    shortBio: item.fields?.shortBio || null,
    twitter: item.fields?.twitter || null,
    instagram: item.fields?.instagram || null,
    image: convertItemIntoImage(item.fields?.image) || null
  }
  return author
}

function convertCategories (items?: any[]): BlogCategoryType[] {
  if (!items) {
    return []
  }
  return items.map((item: any): BlogCategoryType => ({
    id: item.sys.id,
    name: item.fields?.category || null
  }))
}

function convertBlogTrackPreview (items?: any[], library?: DipseaContentType): TrackPropsType[] | null {
  if (!items) {
    return null
  }
  if (!library) {
    return null
  }
  // $FlowFixMe
  return items.map((item: any): TrackPropsType | null => {
    const slug = convertDeepLink(item.fields?.url)
    const slugId = slug.split('/')[2]
    const track = convertTrackBySlugToProps(slugId, library)
    return track
  }).filter((item: TrackPropsType | null): boolean => item !== null)
}
function convertItemIntoBlogPost (item: any, related: boolean = true, library?: DipseaContentType): BlogPostType {
  const blogPost: BlogPostType = {
    id: item.sys.id,
    title: item.fields.title,
    slug: item.fields.slug,
    headTitleTagText: item.fields.headTitleTagText || null,
    headMetaNameDescription: item.fields.headMetaNameDescription || null,
    excerpt: item.fields.excerpt || '',
    date: item.fields.date.replace('-', '.').replace('-', '.'),
    blogContent: item.fields.blogContent,
    blogContent1: item.fields.blogContent1 || null,
    blogContent2: item.fields.blogContent2 || null,
    author: convertItemIntoAuthor(item.fields.author) || null,
    postPhoto: convertItemIntoImage(item.fields.postPhoto) || null,
    categories: convertCategories(item.fields.categories) || null,
    related: related && item.fields.relatedArticles
      ? item.fields.relatedArticles.map((item: any): BlogPostType => convertItemIntoBlogPost(item, false))
      : null,
    trackPreviews: convertBlogTrackPreview(item.fields.trackPreviews, library) || null,
    tryFreeButtonText: item.fields.tryFreeButtonText || 'Try Risk Free',
    tryFreeButtonColor: item.fields.tryFreeButtonColor || colors.orange,
    tryFreeButtonLink: item.fields.tryFreeButtonLink || null,
    tryFreeText1: item.fields.tryFreeText || "Ready to take your next solo sesh to a whole new level? That's what Dipsea is for.",
    tryFreeText2: item.fields.tryFreeText2 || 'Dipsea is an app for Sexy Stories, Sleep Scenes, and Wellness Sessions designed to turn you on and then tuck you in.',
    tryFreeBackground: item.fields.tryFreeBackground || null,
    thematicTag: item.fields.thematicTag || null
  }
  return blogPost
}

export async function fetchBlogCategories (): Promise<BlogCategoryType[]> {
  const entries = await client.getEntries({
    content_type: 'category'
  })

  return entries.items.map((item: any): BlogCategoryType => {
    const category: BlogCategoryType = {
      id: item.sys.id,
      name: item.fields.category
    }
    return category
  })
}

export type BlogIndexPageType = {
  title: string,
  subtitle: string,
  headTitleTagText: string,
  headMetaNameDescription: string,
  headMetaNameTwittertitle: string,
  headMetaNameTwitterdescription: string,
  headMetaPropertyOgtitle: string,
  headMetaPropertyOgdescription: string,
  featuredBlog: BlogPostType,
  mostPopular: BlogPostType[],
  headMetaImage?: ImageType | null
}

// https://app.contentful.com/spaces/03of3q5qzesq/entries/MK3kP4gz2MFN61YNsIJwD
const BLOG_INDEX_ENTRY_ID = 'MK3kP4gz2MFN61YNsIJwD'

export async function fetchBlogIndex (): Promise<BlogIndexPageType> {
  const entries = await client.getEntries({
    content_type: 'blogIndexPage',
    limit: 1,
    'sys.id': BLOG_INDEX_ENTRY_ID,
    include: 2
  })

  const entry = entries.items[0]

  const indexPage: BlogIndexPageType = {
    title: entry.fields.title,
    subtitle: entry.fields.subtitle,
    headTitleTagText: entry.fields.headTitleTagText,
    headMetaNameDescription: entry.fields.headMetaNameDescription,
    headMetaNameTwittertitle: entry.fields.headMetaNameTwittertitle,
    headMetaNameTwitterdescription: entry.fields.headMetaNameTwitterdescription,
    headMetaPropertyOgtitle: entry.fields.headMetaPropertyOgtitle,
    headMetaPropertyOgdescription: entry.fields.headMetaPropertyOgdescription,
    headMetaImage: convertItemIntoImage(entry.fields.headMetaImage),
    featuredBlog: convertItemIntoBlogPost(entry.fields.featuredBlog),
    mostPopular: entry.fields.mostPopular.map((item: any): BlogPostType => convertItemIntoBlogPost(item))
  }
  return indexPage
}

export type FetchBlogPostsQueryParamsType = {
  categoryId?: string,
  skip?: number,
  limit?: number,
  excludeIds?: string[],
  thematicId?: string
}

export async function fetchBlogPosts (params: FetchBlogPostsQueryParamsType): Promise<BlogPostType[]> {
  const queryParams: any = {
    content_type: 'blogPost',
    skip: params.skip || 0,
    limit: params.limit || 1000,
    include: 2,
    order: '-fields.date'
  }

  if (params.categoryId) {
    queryParams.links_to_entry = params.categoryId
  }

  if (params.excludeIds) {
    queryParams['sys.id[nin]'] = params.excludeIds.join(',')
  }

  if (params.thematicId) {
    queryParams['fields.thematicTag'] = params.thematicId
  }

  try {
    const entries = await client.getEntries(queryParams)
    const posts: BlogPostType[] = []

    entries.items.forEach((item: any): void => {
      try {
        posts.push(convertItemIntoBlogPost(item, false))
      } catch (e) {
        console.error(e)
      }
    })
    return posts
  } catch (e) {
    console.error(e)
  }
  return []
}

const fallbackForHTML = (rawData: any): any => {
  if (rawData.type === 'html') {
    return {
      nodeType: MARKS.CODE,
      data: {
        rawHtml: rawData.value
      },
      content: [
        {
          nodeType: 'text',
          value: rawData.value,
          marks: [],
          data: {
            rawHtml: rawData.value
          }
        }
      ]
    }
  } else if (rawData.type === 'image') {
    const rawHtml = `<div style="max-width: 800px; width: 100%; margin: 0 auto;"><img alt="${rawData.alt}" src="${rawData.url}" /></div>`
    return {
      nodeType: MARKS.CODE,
      data: {
        rawHtml
      },
      content: [
        {
          nodeType: 'text',
          value: rawHtml,
          marks: [],
          data: {
            rawHtml
          }
        }
      ]
    }
  }
}

export async function fetchBlogEntry (slug: string): Promise<?BlogPostType> {
  const queryParams: any = {
    content_type: 'blogPost',
    limit: 1,
    'fields.slug': slug,
    include: 2
  }

  try {
    const [entries, library] = await Promise.all([client.getEntries(queryParams), getLibrary()])
    const item = entries.items[0]
    const blog = convertItemIntoBlogPost(item, true, library)

    if (blog.blogContent) {
      blog.blogContent = await richTextFromMarkdown(blog.blogContent, fallbackForHTML)
    }

    if (blog.blogContent1) {
      blog.blogContent1 = await richTextFromMarkdown(blog.blogContent1, fallbackForHTML)
    }

    if (blog.blogContent2) {
      blog.blogContent2 = await richTextFromMarkdown(blog.blogContent2, fallbackForHTML)
    }
    return blog
  } catch (e) {
    console.error(e)
  }
}

export type BlogSlugsType = { paths: { params: { slug: string } }[], fallback: boolean }

export async function generateBlogSlugs (): Promise<BlogSlugsType> {
  const queryParams: any = {
    content_type: 'blogPost',
    limit: 1000,
    select: 'fields.slug'
  }
  let paths = []
  try {
    const entries = await client.getEntries(queryParams)
    const slugs: string[] = entries.items.map((item: any): any => item.fields.slug)
    const finalSlugs = slugs.filter((slug: string): boolean => !slug.includes('placeholder'))
    // const finalSlugs = slugs
    paths = finalSlugs.map((slug: string): { params: { slug: string } } => ({ params: { slug: slug.trim() } }))
  } catch (e) {
    console.error(e)
  }
  return {
    paths,
    fallback: false
  }
}

export type CategorySlugsType = { paths: { params: { slug: string, id: string } }[], fallback: boolean }

export async function generateCategorySlugs (): Promise<CategorySlugsType> {
  let paths = []
  try {
    const entries = await fetchBlogCategories()
    // $FlowFixMe
    paths = entries.map((item: BlogCategoryType): { params: { slug: string, id: string } } => ({
      params: {
        slug: item.name.toLowerCase(),
        id: item.id
      }
    }))
  } catch (e) {
    console.error(e)
  }
  return {
    paths,
    fallback: false
  }
}
export type HomePageSpotLightItemType = {
  id: string,
  title: string,
  description: string,
  cta: string,
  link: string,
  image?: ImageType | null
}

export type HomePageSpotLightType = {
  id: string,
  sectionTitle: string,
  spotlights: HomePageSpotLightItemType[]
}

function convertItemIntoSpotlightItem (item: any): HomePageSpotLightItemType {
  return {
    id: item?.sys?.id || item.id,
    title: item?.fields?.title || item.title,
    description: item?.fields?.description || item.description || null,
    cta: item?.fields?.ctaTitle || item.ctaTitle,
    image: convertItemIntoImage(item?.fields?.image || item.image),
    link: item?.fields?.link || item.link
  }
}

function convertBlogIntoSpotlightItem (item: BlogPostType): HomePageSpotLightItemType {
  return {
    id: item.id,
    title: item.title,
    description: item.excerpt,
    cta: 'Read More',
    image: item.postPhoto,
    link: `/blog/${item.slug}`
  }
}

function convertItemIntoSpotlight (item: any): HomePageSpotLightType {
  return {
    id: item.sys.id,
    sectionTitle: item.fields.spotlightTitle,
    spotlights: item.fields.spotlights.map((spotlight: any): HomePageSpotLightItemType => convertItemIntoSpotlightItem(spotlight))
  }
}

export async function fetchHomePageSpotlight (): Promise<any> {
  const entries = await client.getEntries({
    content_type: 'homepageSpotlightContainer',
    limit: 1,
    'sys.id': '1m25QnVQUqGUBKvYJrdRzt',
    include: 2
  })

  const item = entries.items[0]

  return convertItemIntoSpotlight(item)
}

export async function fetchFinePrintPage (type: 'terms' | 'privacypolicy' | 'referralTerms' | 'referralGiftTerms' | 'paymentterms'): Promise<any> {
  try {
    const entries = await client.getEntries({
      content_type: 'terms'
    })
    const item = entries.items[0].fields[type]
    const text = await richTextFromMarkdown(item)
    return text
  } catch (error) {
    console.error(error)
  }
}

export async function fetchBlogArticlesByTheme (slug: string): Promise<HomePageSpotLightType | null> {
  const blogs = await fetchBlogPosts({ thematicId: slug })
  if (!blogs.length) {
    return null
  }

  const items = blogs.map(convertBlogIntoSpotlightItem)
  return {
    id: `${slug}-blogs`,
    sectionTitle: `SPOTLIGHT ON ${slug.toUpperCase().replace('-', ' ')}`,
    spotlights: items
  }
}
