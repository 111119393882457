// @flow

function normalizeSrc (src: string): string {
  return src[0] === '/' ? src.slice(1) : src
}

// https://www.contentful.com/developers/docs/references/images-api/#/introduction

export type ImageLoaderPropsType = {
  src: string,
  quality?: number, // Quality as a percentage, between 1 and 100.
  width?: number,
  height?: number,
  format?: 'jpg' | 'png' | 'webp' | 'gif' | 'avif',
  progressiveJPG?: boolean
}

export function contentfulLoader (props: ImageLoaderPropsType): string {
  const { src, quality, width, height, format, progressiveJPG } = props

  let newSrc = src
  if (src.match(/^\/\/images/i)) {
    newSrc = src.replace(/^\/\/images/i, 'https://images')
  }

  const params = []

  if (width) {
    params.push('w=' + width)
  }

  if (height) {
    params.push('h=' + height)
  }

  if (quality) {
    params.push('q=' + quality)
  }

  if (format) {
    params.push('fm=' + format)
  }

  if (progressiveJPG) {
    params.push('fl=progressive')
  }

  return `${normalizeSrc(newSrc)}?${params.join('&')}`
}
