// @flow
import {
  confirmPasswordResetAsync,
  currentUser,
  monitorAuthChange,
  sendPasswordResetLink,
  signInAsync,
  signInWithAppleAsync,
  signInWithFacebookAsync,
  signInWithGoogleAsync,
  signOutAsync,
  signUpAsync,
  updateEmailAddress,
  updatePassword,
  checkSignInEmail, addEmailAddressAsync, removeConnectionAsync, getRedirectResultAsync
} from '../services/firebaseAuth'
import type {
  CurrentUserType,
  ProvidersType,
  UserScoreCardConversionQuizResultsType,
  UserType,
  QuizType,
  UserCancellationSurveyAnswerType
} from '../flowTypes'
import * as actionTypes from './actionTypes'
import {
  deleteAccountAsync,
  getCurrentUserAsync,
  saveConversionDataToScoreCard,
  updateEmailAndAgeAsync,
  updateEmailAsync,
  userChange,
  saveTikTokCreatorPromoToScoreCard, userSaveCancellationSurveyAnswer, removeStorageItems
} from '../models/User'
import type {
  TogglePasswordModalType,
  UserFetchRecommendationsSuccessType
} from './actionTypes'
import { fetchRemoteConfigAsync } from '../services/firebaseRemoteConfig'
import type { AppStateType } from '../reducers/appstate'
import { segmentIdentifyUser, segmentReset, snapIdentifyUser } from './segmentActions'
import { events } from '../services/analytics'
import { isDev, isStaging } from '../env'
import { identifyUserSegment, identifyUserWithCallback } from '../services/segment'
import { updateIntercom } from '../services/intercom'
import {
  cancelStripeRenewalAction,
  monitorStripePurchaseRefund,
  retrievePaymentInfo,
  userCancellationCompleteAction
} from './subscriptionActions'
import { PROVIDERS } from '../flowTypes'
import type { DiscoverPagePropsType } from '../models/Discover'
import { reportError } from '../services/bugSnag'

export function monitorAuthChangeAsync (): any {
  return async (dispatch: any): Promise<void> => {
    window.userMonitor = monitorAuthChange((userObject: CurrentUserType): void => {
      if (userObject) {
        dispatch(userLoggedIn())
      } else {
        dispatch(userLoggedOut())
      }
    })
  }
}

export function getRedirectResult (): any {
  return async (dispatch: any, _getState: any): Promise<void> => {
    try {
      await getRedirectResultAsync()
    } catch (e) {
      reportError(e)
    }
  }
}

export function userLoggedIn (): any {
  return async (dispatch: any, _getState: any): Promise<void> => {
    dispatch({
      type: actionTypes.USER_LOGGED_IN
    })
    await dispatch(loadUserAsync())
    await dispatch(remoteConfigAsync())
    dispatch(segmentIdentifyUser())
    dispatch(snapIdentifyUser())
    dispatch(initializeIntercom())
  }
}

export function initializeIntercom (): any {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const state: AppStateType = getState()
      if (state.user) {
        updateIntercom(state.user)
      }
    } catch (error) {
      console.log('Error setting up intercom', { error })
    }
  }
}

export function userLoggedOut (): any {
  return async (dispatch: any, _getState: any): Promise<void> => {
    dispatch({
      type: actionTypes.USER_LOGGED_OUT
    })
  }
}

export function remoteConfigAsync (): any {
  return async (dispatch: any): Promise<void> => {
    try {
      const remoteConfig = await fetchRemoteConfigAsync()
      dispatch({
        type: actionTypes.REMOTE_CONFIG_FETCH,
        config: remoteConfig
      })
    } catch (error) {
      // do nothing, if this doesn't happen, it's likely the result of a network error.
      if (isDev || isStaging()) {
        console.error('Error getting remote config', { error })
      }
    }
  }
}

export function userFetched (user: UserType): actionTypes.UserFetchedType {
  return {
    type: actionTypes.USER_FETCHED,
    user
  }
}

export function loadUserAsync (replaceListener: boolean = false): any {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const user: UserType = await getCurrentUserAsync()
      const state = getState()
      if (user) {
        identifyUserSegment(user)
        const existingUser = state.user || {}
        await dispatch(userFetched({ ...existingUser, ...user }))
      }
      if (user.stripe_purchase_key) {
        dispatch(monitorStripePurchaseRefund(user.stripe_purchase_key))
        dispatch(retrievePaymentInfo())
      }
      if (replaceListener && window && window.userListener) {
        window.userListener.off()
        delete window.userListener
      }
      if (window && !window.userListener) {
        window.userListener = userChange((user: UserType): void => {
          dispatch(userFetched(user))
          dispatch(userCancellationCompleteAction())
        })
      }
    } catch (error) {
      console.error('Error loading user', { error })
    }
  }
}

export function signIn (email: string, password: string): any {
  return async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: actionTypes.USER_SIGN_IN
      })
      await signInAsync(email, password)
      dispatch({
        type: actionTypes.USER_SIGN_IN_SUCCESS
      })
      // wait for segment identify to be called on auth change
      events.signed_in(email, 'email')
    } catch (error) {
      reportError(error)
      dispatch({
        type: actionTypes.USER_SIGN_IN_FAIL,
        error
      })
    }
  }
}

export function signUp (email: string, password: string, showPaywallModalAfterSignUp: boolean = false): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    try {
      dispatch({
        type: actionTypes.USER_SIGN_UP
      })
      const firebaseUser = await signUpAsync(email, password)
      if (showPaywallModalAfterSignUp) {
        // await dispatch(openSubscribeModal())
      }
      await updateEmailAndAgeAsync(email)
      dispatch({
        type: actionTypes.USER_SIGN_UP_SUCCESS
      })
      const { subscriptionPromo, creatorDisplayName } = getState()
      const userId = firebaseUser.uid

      if (creatorDisplayName) {
        // user signed up with a creator promo. Save to scorecard
        await saveTikTokCreatorPromoToScoreCard(creatorDisplayName)
      }

      await new Promise((resolve: () => void): void => {
        identifyUserWithCallback(userId, email, (): void => {
          events.signed_up(email, 'email')

          const params = {}
          if (subscriptionPromo?.promo) {
            params.promo = subscriptionPromo?.promo
            if (subscriptionPromo?.userEnteredPromo) params.userEnteredPromo = subscriptionPromo?.userEnteredPromo
            if (creatorDisplayName) params.creatorDisplayName = creatorDisplayName
            events.promo_account_created(userId, email, params)
          }
          resolve()
        })
      })
    } catch (error) {
      reportError(error)
      dispatch({
        type: actionTypes.USER_SIGN_UP_FAIL,
        error
      })
    }
  }
}

export function signInWithApple (): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    try {
      dispatch({
        type: actionTypes.USER_SIGN_IN
      })
      const firebaseUser = await signInWithAppleAsync(false)
      dispatch({
        type: actionTypes.USER_SIGN_IN_SUCCESS
      })

      const { subscriptionPromo, creatorDisplayName } = getState()
      const email = (firebaseUser.user || {}).email || ''
      const uid = (firebaseUser.user || {}).uid || ''
      if (firebaseUser && (firebaseUser.additionalUserInfo || {}).isNewUser) {
        events.signed_up(email, 'apple')

        const params = {}
        if (subscriptionPromo?.promo) {
          params.promo = subscriptionPromo?.promo
          if (subscriptionPromo?.userEnteredPromo) params.userEnteredPromo = subscriptionPromo?.userEnteredPromo
          if (creatorDisplayName) params.creatorDisplayName = creatorDisplayName
          events.promo_account_created(uid, email, params)
        }
      } else {
        events.signed_in(email, 'apple')
      }
    } catch (error) {
      reportError(error)
      const errorText = getSocialAuthErrorText(error)
      dispatch({
        type: actionTypes.USER_SIGN_IN_FAIL,
        error: {
          message: errorText,
          code: 4
        }
      })
    }
  }
}

export function signInWithFacebook (): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    try {
      dispatch({
        type: actionTypes.USER_SIGN_IN
      })
      const firebaseUser = await signInWithFacebookAsync(false)
      dispatch({
        type: actionTypes.USER_SIGN_IN_SUCCESS
      })

      const { subscriptionPromo, creatorDisplayName } = getState()
      const email = (firebaseUser.user || {}).email || ''
      const uid = (firebaseUser.user || {}).uid || ''
      if (firebaseUser && (firebaseUser.additionalUserInfo || {}).isNewUser) {
        events.signed_up(email, 'facebook')

        const params = {}
        if (subscriptionPromo?.promo) {
          params.promo = subscriptionPromo?.promo
          if (subscriptionPromo?.userEnteredPromo) params.userEnteredPromo = subscriptionPromo?.userEnteredPromo
          if (creatorDisplayName) params.creatorDisplayName = creatorDisplayName
          events.promo_account_created(uid, email, params)
        }
      } else {
        events.signed_in(email, 'facebook')
      }
    } catch (error) {
      reportError(error)
      const errorText = getSocialAuthErrorText(error)
      dispatch({
        type: actionTypes.USER_SIGN_IN_FAIL,
        error: {
          message: errorText,
          code: 4
        }
      })
    }
  }
}

export function signInWithGoogle (): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    try {
      dispatch({
        type: actionTypes.USER_SIGN_IN
      })
      const firebaseUser = await signInWithGoogleAsync(false)
      dispatch({
        type: actionTypes.USER_SIGN_IN_SUCCESS
      })

      const { subscriptionPromo, creatorDisplayName } = getState()
      const email = (firebaseUser.user || {}).email || ''
      const uid = (firebaseUser.user || {}).uid || ''
      if (firebaseUser && (firebaseUser.additionalUserInfo || {}).isNewUser) {
        events.signed_up(email, 'google')

        const params = {}
        if (subscriptionPromo?.promo) {
          params.promo = subscriptionPromo?.promo
          if (subscriptionPromo?.userEnteredPromo) params.userEnteredPromo = subscriptionPromo?.userEnteredPromo
          if (creatorDisplayName) params.creatorDisplayName = creatorDisplayName
          events.promo_account_created(uid, email, params)
        }
      } else {
        events.signed_in(email, 'google')
      }
    } catch (error) {
      reportError(error)
      const errorText = getSocialAuthErrorText(error)
      dispatch({
        type: actionTypes.USER_SIGN_IN_FAIL,
        error: {
          message: errorText,
          code: 4
        }
      })
    }
  }
}

export function saveBlogEmail (email: string, asPath: string): any {
  return async (dispatch: any): Promise<void> => {
    try {
      // start loading dots
      dispatch({
        type: actionTypes.USER_BLOG_EMAIL_LOADING
      })

      // check if the email is in use
      const signInMethodsForEmail: string[] = await checkSignInEmail(email)

      if (signInMethodsForEmail.length) {
        // if email is in use, show error message 'This email is already in use'
        dispatch({
          type: actionTypes.USER_BLOG_EMAIL_ERROR,
          errorText: 'This email is already in use'
        })
      } else {
        // if email is not in use, show success modal and event to iterable
        dispatch({
          type: actionTypes.USER_BLOG_EMAIL_SUCCESS
        })
        events.blog_email_capture(email, asPath)
      }
    } catch (error) {
      dispatch({
        type: actionTypes.USER_BLOG_EMAIL_ERROR,
        errorText: 'Sorry we had a problem saving your email. Please try again.'
      })
    }
  }
}

export function signOut (): any {
  return async (dispatch: any): Promise<void> => {
    try {
      events.signed_out()
      dispatch({
        type: actionTypes.USER_SIGN_OUT
      })
      await signOutAsync()
      dispatch(segmentReset())
      removeStorageItems()
      dispatch({
        type: actionTypes.USER_SIGN_OUT_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: actionTypes.USER_SIGN_OUT_FAIL,
        error
      })
    }
  }
}

export function updateEmailAction (email: string, password: string): any {
  return async (dispatch: any): Promise<void> => {
    try {
      events.updateEmail()
      await updateEmailAddress(email, password)
      await updateEmailAsync(email)
      await dispatch(loadUserAsync())
      dispatch({
        type: actionTypes.USER_EMAIL_UPDATE_SUCCESS
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: actionTypes.USER_EMAIL_UPDATE_FAIL,
        error
      })
    }
  }
}

export function updatePasswordAction (newPassword: string, oldPassword: string): any {
  return async (dispatch: any): Promise<void> => {
    try {
      events.updatePassword()
      await updatePassword(newPassword, oldPassword)
      dispatch({
        type: actionTypes.USER_PASSWORD_UPDATE_SUCCESS
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: actionTypes.USER_PASSWORD_UPDATE_FAIL,
        error
      })
    }
  }
}

export function setScreenWidth (size: number): any {
  return (dispatch: any): void => {
    dispatch({
      type: actionTypes.WINDOW_SCREEN_SIZE,
      size
    })
  }
}

export function setDeviceType (hasMouse: boolean): any {
  return (dispatch: any): void => {
    dispatch({
      type: actionTypes.WINDOW_HAS_MOUSE,
      hasMouse
    })
  }
}

export function sendPasswordResetLinkAction (email: string): any {
  return async (dispatch: any): Promise<void> => {
    try {
      dispatch({
        type: actionTypes.USER_RESET_PASSWORD
      })
      await sendPasswordResetLink(email)
      dispatch({
        type: actionTypes.USER_RESET_PASSWORD_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: actionTypes.USER_RESET_PASSWORD_FAIL,
        error
      })
    }
  }
}

export function togglePasswordModal (email?: string): TogglePasswordModalType {
  return {
    type: actionTypes.TOGGLE_PASSWORD_MODAL,
    email: email || ''
  }
}

export function logErrorPage (path: string): any {
  return async (_dispatch: any): Promise<void> => {
    events.page_error_log(path)
  }
}
export function deleteAccountAction (): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    const state = getState()
    try {
      if (state.user.subscription_type === 'stripe' && state.user.isSubscribed && !state.user.subscription_expires_at) {
        await dispatch(cancelStripeRenewalAction())
      }
      await deleteAccountAsync()
      dispatch(loadUserAsync())
      events.deleteAccount()
    } catch (err) {
      // do nothing
    }
  }
}

export function confirmPasswordResetAction (code: string, newPassword: string): any {
  return async (dispatch: any): Promise<void> => {
    try {
      await confirmPasswordResetAsync(code, newPassword)
      dispatch({
        type: actionTypes.PASSWORD_RESET_WITH_ACTION_CODE_SUCCESS
      })
    } catch (e) {
      dispatch({
        type: actionTypes.PASSWORD_RESET_WITH_ACTION_CODE_FAIL,
        message: e.code
      })
    }
  }
}

export function showAuthModal (isShown: boolean, isSignIn: boolean = false): any {
  return async (dispatch: any): Promise<void> => {
    try {
      if (isShown) {
        dispatch({ type: actionTypes.HIDDEN_AUTH_MODAL_OPEN, isSignIn })
      } else {
        dispatch({ type: actionTypes.HIDDEN_AUTH_MODAL_CLOSE })
      }
    } catch (e) {
      // do nothing
    }
  }
}

export function socialLinkAccount (provider: ProvidersType): actionTypes.SocialLinkAccountType {
  return {
    type: actionTypes.SOCIAL_LINK_ACCOUNT,
    provider
  }
}

export function socialLinkAccountSuccess (): actionTypes.SocialLinkAccountSuccessType {
  return {
    type: actionTypes.SOCIAL_LINK_ACCOUNT_SUCCESS
  }
}

export function socialLinkAccountFail (errorText: string): actionTypes.SocialLinkAccountFailType {
  return {
    type: actionTypes.SOCIAL_LINK_ACCOUNT_FAIL,
    errorText
  }
}

export function updateProviders (providerId: ProvidersType): any {
  return (dispatch: any, getState: () => AppStateType): void => {
    const state = getState()
    let providers = state.user.providers || []
    if (providers.includes(providerId)) {
      providers = providers.filter((provider: ProvidersType): boolean => provider !== providerId)
    } else {
      providers.push(providerId)
    }
    dispatch({
      type: actionTypes.REFRESH_USER_PROVIDERS,
      providers
    })
  }
}

export function getSocialAuthErrorText (error: { code?: string, message: string }): string {
  let errorText = 'We had a problem signing into your account'
  if (error && error.code) {
    switch (error.code) {
      case 'auth/account-exists-with-different-credential':
        errorText = 'That email is already in use'
        break
      case 'auth/invalid-credential':
        break
      case 'auth/operation-not-allowed':
        break
      case 'auth/user-disabled':
        break
      case 'auth/user-not-found':
        break
      case 'auth/wrong-password':
        break
      case 'auth/invalid-verification-code':
        break
      case 'auth/invalid-verification-id':
        break
      // Apple errors
      case '1001':
        errorText = 'We had a problem signing into your account'
        break
      case '1004':
        errorText = 'Sign in with Apple failed. Please close the app and try again'
        break
      // Google errors
      case '-5':
        errorText = 'Error signing in with Google.'
        break
    }
  }
  return errorText
}

export function setQuizResult (
  name: string,
  id: string,
  genderPreference: string,
  results: UserScoreCardConversionQuizResultsType,
  quizType: QuizType,
  attempts: number = 0
): any {
  return async (dispatch: any): Promise<void> => {
    dispatch({
      type: actionTypes.SET_QUIZ_RESULT,
      result: name,
      genderPreference
    })
    const user = currentUser()
    if (user && user.email && user.id) {
      try {
        await saveConversionDataToScoreCard(
          id,
          name,
          genderPreference,
          quizType,
          results
        ).then((): void => {
          if (quizType === 'hunk-quiz') {
            return identifyUserWithCallback(user.id, user.email, (): void => {
              // this is a segment event
              return events.conversion_quiz_completed({
                hunk_id: id,
                hunk_name: name,
                sexual_preference_answer: genderPreference
              })
            })
          } else if (quizType === 'trope-quiz') {
            return events.trope_conversion_quiz_completed({
              series_id: id,
              series_name: name,
              sexual_preference_answer: genderPreference
            })
          } else if (quizType === 'fantasy-quiz') {
            return events.fantasy_conversion_quiz_completed({
              series_id: id,
              series_name: name,
              sexual_preference_answer: genderPreference
            })
          }
        })
        // FB event
        events.quiz_results({
          quizType,
          results: { id, name },
          genderPreference
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      if (attempts < 5) {
        setTimeout(
          (): void =>
            dispatch(
              setQuizResult(name, id, genderPreference, results, quizType, attempts++)
            ),
          1000
        )
      } else {
        events.conversion_quiz_completed({
          hunk_id: id,
          hunk_name: name,
          sexual_preference_answer: genderPreference
        })
      }
    }
  }
}

export function toggleAppleConnection (isConnected: boolean): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    try {
      dispatch(socialLinkAccount(PROVIDERS.apple))
      if (isConnected) {
        const state = getState()
        const providers = (state.user || {}).providers || []
        if (providers.length <= 1) {
          return dispatch(socialLinkAccountFail('You cannot disconnect your only sign in method'))
        }
        await removeConnectionAsync(PROVIDERS.apple)
      } else {
        await signInWithAppleAsync(true)
      }
      dispatch(updateProviders(PROVIDERS.apple))
      dispatch(socialLinkAccountSuccess())
    } catch (error) {
      const errorText = getSocialAuthErrorText(error)
      dispatch(socialLinkAccountFail(errorText))
    }
  }
}

export function toggleGoogleConnection (isConnected: boolean): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    try {
      dispatch(socialLinkAccount(PROVIDERS.google))
      if (isConnected) {
        const state = getState()
        const providers = (state.user || {}).providers || []
        if (providers.length <= 1) {
          return dispatch(socialLinkAccountFail('You cannot disconnect your only sign in method'))
        }
        await removeConnectionAsync(PROVIDERS.google)
      } else {
        await signInWithGoogleAsync(true)
      }
      dispatch(updateProviders(PROVIDERS.google))
      dispatch(socialLinkAccountSuccess())
    } catch (error) {
      const errorText = getSocialAuthErrorText(error)
      dispatch(socialLinkAccountFail(errorText))
    }
  }
}

export function toggleFacebookConnection (isConnected: boolean): any {
  return async (dispatch: any, getState: () => AppStateType): Promise<void> => {
    try {
      dispatch(socialLinkAccount(PROVIDERS.facebook))
      if (isConnected) {
        const state = getState()
        const providers = (state.user || {}).providers || []
        if (providers.length <= 1) {
          return dispatch(socialLinkAccountFail('You cannot disconnect your only sign in method'))
        }
        await removeConnectionAsync(PROVIDERS.facebook)
      } else {
        await signInWithFacebookAsync(true)
      }
      dispatch(updateProviders(PROVIDERS.facebook))
      dispatch(socialLinkAccountSuccess())
    } catch (error) {
      const errorText = getSocialAuthErrorText(error)
      dispatch(socialLinkAccountFail(errorText))
    }
  }
}

export function addEmailAction (email: string, password: string): any {
  return async (dispatch: any): Promise<void> => {
    try {
      await addEmailAddressAsync(email, password)
      await updateEmailAsync(email)
      events.signed_in(email, 'email')
      await dispatch(loadUserAsync())
      dispatch({
        type: actionTypes.USER_EMAIL_UPDATE_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: actionTypes.USER_EMAIL_UPDATE_FAIL,
        error
      })
    }
  }
}

export function resetEmailUpdate (): actionTypes.UserEmailUpdateResetType {
  return {
    type: actionTypes.USER_EMAIL_UPDATE_RESET
  }
}

export function saveCancellationSurveyAnswer (usersAnswer: string, otherText?: string): any {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const params: UserCancellationSurveyAnswerType = {
        canceled_at: Date.now(),
        users_answer: usersAnswer
      }

      if (otherText) {
        params.other_text = otherText
      }

      await userSaveCancellationSurveyAnswer(params)
      events.user_answered_cancellation_survey(params)
    } catch (error) {
      console.log(error)
    }
  }
}
export function userFetchRecommendationsSuccess (recommendations: DiscoverPagePropsType): UserFetchRecommendationsSuccessType {
  return {
    type: actionTypes.USER_RECOMMENDATIONS_FETCH_SUCCESS,
    recommendations: recommendations.pageRows
  }
}
