// @flow
export async function sha256 (message: string): Promise<string> {
  try {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message)

    // hash the message
    const hashBuffer = await window.crypto?.subtle?.digest('SHA-256', msgBuffer)

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer))

    // convert bytes to hex string
    // $FlowFixMe
    const hashHex = hashArray.map((b: Buffer): string => ('00' + b.toString(16)).slice(-2)).join('')
    return hashHex
  } catch (error) {
    console.log(error)
  }
  return ''
}

export const initSnapChatAsync = async (email: string): Promise<any> => {
  if (window && window.snaptr) {
    try {
      const emailLower = email.toLowerCase()
      const emailHash = await sha256(emailLower)
      return window.snaptr('init', 'e3b15c53-5c4b-433e-90bc-0d8ff2312285', {
        user_hashed_email: emailHash
      })
    } catch (error) {

    }
  }
}

export const trackAnnualPurchaseSnapChat = async (transactionId: string): Promise<void> => {
  if (window && window.snaptr) {
    const tid = await sha256(transactionId)
    window.snaptr('track', 'PURCHASE', {
      currency: 'USD',
      price: 69.99,
      item_category: 'annual',
      item_ids: ['annual'],
      transaction_id: tid
    })
  }
}

export const trackSignUpSnapChat = async (): Promise<void> => {
  if (window && window.snaptr) {
    window.snaptr('track', 'SIGN_UP')
  }
}

export const trackPageViewSnapChat = async (): Promise<void> => {
  if (window && window.snaptr) {
    window.snaptr('track', 'PAGE_VIEW')
  }
}
