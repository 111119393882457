// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const DiscoverIcon = (props: PropsType): React.Node => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || '100%'}
         height={props.size || '100%'} viewBox="0 0 16 16" fill="none"
         style={{ display: 'flex' }}
    >
      <rect x="0.364258" y="9.19727" width="6.30431" height="6.30431" rx="0.70762" fill={props.color || colors.white}/>
      <rect x="9.05957" y="9.19727" width="6.30431" height="6.30431" rx="0.70762" fill={props.color || colors.white}/>
      <rect x="0.364258" y="0.498047" width="6.30431" height="6.30431" rx="0.70762" fill={props.color || colors.white}/>
      <rect x="9.05957" y="0.498047" width="6.30431" height="6.30431" rx="0.70762" fill={props.color || colors.white}/>
    </svg>
  )
}

export default DiscoverIcon
