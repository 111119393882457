// @flow
import { envConfig, isServer } from '../env'

export function firebaseApp (): any {
  if (isServer) {
    if (global.firebaseClient) {
      return global.firebaseClient
    }
    const publicRuntimeConfig = envConfig()
    const initializeApp = require('firebase/app').initializeApp
    require('firebase/auth')
    require('firebase/database')
    const firebaseConfig = {
      apiKey: publicRuntimeConfig.FIREBASE_API_KEY,
      authDomain: publicRuntimeConfig.FIREBASE_AUTH_NAME,
      databaseURL: publicRuntimeConfig.FIREBASE_DB_URL,
      projectId: publicRuntimeConfig.FIREBASE_PROJECT_ID,
      storageBucket: publicRuntimeConfig.FIREBASE_STORAGE,
      messagingSenderId: publicRuntimeConfig.FIREBASE_MSG_SDR_ID,
      appId: publicRuntimeConfig.FIREBASE_APP_ID,
      measurementId: publicRuntimeConfig.FIREBASE_MEASUREMENT_ID
    }

    global.firebaseClient = initializeApp(firebaseConfig)
    return global.firebaseClient
  } else if (!window.firebase) {
    const publicRuntimeConfig = envConfig()
    const initializeApp = require('firebase/app').initializeApp
    require('firebase/auth')
    require('firebase/database')
    require('firebase/remote-config')
    require('firebase/analytics')

    const firebaseConfig = {
      apiKey: publicRuntimeConfig.FIREBASE_API_KEY,
      authDomain: publicRuntimeConfig.FIREBASE_AUTH_NAME,
      databaseURL: publicRuntimeConfig.FIREBASE_DB_URL,
      projectId: publicRuntimeConfig.FIREBASE_PROJECT_ID,
      storageBucket: publicRuntimeConfig.FIREBASE_STORAGE,
      messagingSenderId: publicRuntimeConfig.FIREBASE_MSG_SDR_ID,
      appId: publicRuntimeConfig.FIREBASE_APP_ID,
      measurementId: publicRuntimeConfig.FIREBASE_MEASUREMENT_ID
    }

    window.firebase = initializeApp(firebaseConfig)
    const { getAuth, setPersistence, browserLocalPersistence } = require('firebase/auth')
    setPersistence(getAuth(), browserLocalPersistence)
  } else {
    return window.firebase
  }
}
