import { css } from 'styled-components'

// This creates the media templates, which allows for simple
// breakpoint usage inside styled-components, e.g.:
//
// ie: everything up until PHONEXL 660 gets a blue border
// ${MEDIA.TABLET`
//    border: 1px solid blue;
// `};
//
// ie: everything up until DESKTOPL 1400 gets a red border
// ${MEDIA.DESKTOPL`
//    border: 1px solid red;
// `};
//
// Edit or add breakpoints inside constants/breakpoints.js

export const maxWidth = 1920

export const BREAKPOINTS = {
  DESKTOPXL: 1690,
  DESKTOPL: 1400, // MacBookPro 15-inch, iPadPro
  DESKTOP: 1024, // MacBookPro 13.3-inch
  TABLETL: 990, // iPad 2 9.7-inch, iPad mini
  TABLETM: 800,
  TABLET: 660, // Amazon Kindle, Microsoft Lumia
  PHONEL: 414, // iphone 6+,6s+,7+,8+
  PHONE: 374 // iphone 6,6s,7,8
}

export const MEDIA = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${BREAKPOINTS[label] / 16}em) {
      ${css(...args)};
    }
  `

  acc[`MAX_${label}`] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label] / 16}em) {
      ${css(...args)};
    }
  `
  return acc
}, {})
