// @flow
import * as React from 'react'
import { colors } from '../colors'

export const TikTokIcon = (props: {
  color?: string,
  size?: number
}): React.Node => {
  const color = props.color || colors.sand
  const size = props.size || 30

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="tiktok"
    >
      <path
        d="M14.9997 0C6.71553 0 0 6.71553 0 14.9997C0 23.2839 6.71553 30 14.9997 30C23.2839 30 30 23.2845 30 14.9997C30 6.71498 23.2845 0 14.9997 0ZM20.6197 13.7365C19.2754 13.8248 18.232 13.3033 17.3669 12.1945V12.5898C17.3669 14.2451 17.3713 15.9004 17.3669 17.5562C17.3608 19.6324 15.8343 21.3465 13.8059 21.5642C11.7774 21.7819 9.91893 20.4359 9.47471 18.4119C8.93664 15.9742 10.8329 13.5854 13.3161 13.5738C13.4142 13.5779 13.5121 13.587 13.6093 13.601V15.5022C13.3317 15.5411 13.0668 15.55 12.818 15.6183C11.8308 15.8887 11.1922 16.9004 11.3615 17.8949C11.5415 18.9533 12.4321 19.6674 13.5066 19.6163C14.5555 19.5652 15.4551 18.7473 15.48 17.7361C15.5195 16.1364 15.5072 14.535 15.5111 12.9346C15.515 11.4443 15.5111 9.95391 15.5111 8.41357C16.1219 8.41357 16.7066 8.40968 17.2908 8.42023C17.3358 8.42023 17.4063 8.51408 17.4185 8.57405C17.7689 10.3093 18.8411 11.2721 20.5619 11.5498C20.5714 11.5498 20.5797 11.5614 20.6175 11.5892L20.6197 13.7365ZM21.5187 9.6313L21.4959 9.6163H21.4887L21.4859 9.60853L21.4959 9.61464L21.5248 9.60797L21.5187 9.6313Z"
        fill={color}
      />
    </svg>
  )
}
