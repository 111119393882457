// @flow
import type { AppStateType } from '../reducers/appstate'
import { identifyUserSegment, initSegment, resetSegment } from '../services/segment'
import { initSnapChatAsync } from '../services/snapchat'

export function initializeSegment (): any {
  return async (_dispatch: any): Promise<void> => {
    try {
      initSegment()
    } catch (error) {
    }
  }
}

export function segmentIdentifyUser (): any {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const state: AppStateType = getState()
      if (state.user) {
        identifyUserSegment(state.user)
      }
    } catch (error) {
    }
  }
}

export function snapIdentifyUser (): any {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const state: AppStateType = getState()
      if (state.user && state.user.email) {
        initSnapChatAsync(state.user.email)
      }
    } catch (error) {
    }
  }
}

export function segmentReset (): any {
  return async (_dispatch: any): Promise<void> => {
    try {
      resetSegment()
    } catch (error) {
      console.log('Error initializing segment', { error })
    }
  }
}
