// @flow
import * as React from 'react'
import { colors } from '../colors'

export const CheckMark = (props: {
  backgroundColor?: string,
  checkColor?: string,
  size?: number
}): React.Node => {
  const backgroundColor = props.backgroundColor || colors.white
  const checkColor = props.checkColor || colors.orange
  const size = props.size || 24

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height={size} width={size} rx={size / 2} fill={backgroundColor} />

      <path
        d="M17 8.34961L10.125 15.4521L7 12.2237"
        stroke={checkColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
