// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string,
  isTriangle?: boolean
}

const PlayIcon = (props: PropsType): React.Node => {
  if (props.isTriangle) {
    return (
      <svg
        width={props.size || '100%'}
        height={props.size || '100%'}
        viewBox='0 0 11 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.34555 0.833655C1.67897 0.460356 0.856934 0.942163 0.856934 1.70615V10.2237C0.856934 11.0032 1.70917 11.483 2.3756 11.0787L9.67638 6.64971C10.3313 6.25242 10.3146 5.29651 9.64633 4.92224L2.34555 0.833655Z'
          fill={props.color || colors.midnightBlue}
        />
      </svg>
    )
  }
  return (
    <svg
      width={props.size || '100%'}
      height={props.size || '100%'}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='24' cy='24' r='24' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.9743 15.234C18.3078 14.8607 17.4857 15.3426 17.4857 16.1065V33.4241C17.4857 34.2036 18.338 34.6834 19.0044 34.2791L33.848 25.2743C34.5029 24.877 34.4863 23.9211 33.818 23.5468L18.9743 15.234Z'
        fill={colors.orange}
      />
    </svg>
  )
}

export default PlayIcon
