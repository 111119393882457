// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const HeartOutlineIcon = (props: PropsType): React.Node => {
  return (
    <svg
      height={props.size || 30}
      width={props.size || 30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47291 3.16345C7.82888 3.16345 7.22975 3.27485 6.70524 3.46366C4.64869 4.2041 3.37148 6.1441 3.37148 8.52685C3.37148 13.7622 8.11498 18.461 13.6075 23.9021C14.1546 24.4443 14.7128 24.9975 15.2751 25.5595C15.8374 24.9969 16.396 24.4437 16.9434 23.9012C22.4356 18.4604 27.1788 13.7619 27.1788 8.52685C27.1788 6.14536 25.9084 4.20599 23.8636 3.46523C21.9309 2.76507 18.9746 3.12695 16.4743 6.23945L15.2708 7.73827L14.0722 6.23536C12.2659 3.96966 10.2068 3.16345 8.47291 3.16345V3.16345ZM15.275 29.9999L14.1801 28.8875C13.2655 27.9576 12.3406 27.0416 11.4464 26.1555C5.45201 20.2175 0.275024 15.0888 0.275024 8.52679C0.275024 4.77235 2.34272 1.69572 5.67092 0.497109C8.92728 -0.675074 12.484 0.260784 15.2784 2.93714C18.0882 0.259211 21.6518 -0.676018 24.9029 0.500885C28.2165 1.70107 30.2749 4.77613 30.2749 8.52679C30.2749 15.0885 25.0982 20.2165 19.1044 26.1548C18.2099 27.041 17.2848 27.9573 16.3695 28.8875L15.275 29.9999Z"
        fill={props.color || colors.white}
      />
    </svg>
  )
}

export default HeartOutlineIcon
