// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { config } from 'react-awesome-styled-grid'
import { useRouter, withRouter } from 'next/router'
import SearchIcon from '@components/shared/icons/SearchIcon'
import { useDispatch, useSelector } from 'react-redux'
import type { AppStateType } from '@reducers/appstate'
import type { PlayType } from '@services/audio'
import DiscoverIcon from '@components/shared/icons/DiscoverIcon'
import MyStoriesIcon from '@components/shared/icons/MyStoriesIcon'
import { toggleSubscriptionModalAction } from '../../actions/subscriptionActions'

type PropsType = {
  isMiniPlayer: boolean,
  router: {
    pathname: string
  }
}

function DiscoverMobileBottomNavComponent (props: PropsType): React.Node {
  const currentlyPlaying = useSelector((state: AppStateType): ?PlayType => state.currentPlaying)
  const router = useRouter()
  const userSignedIn = useSelector((state: AppStateType): boolean => {
    return !!state?.user?.id
  })
  if (currentlyPlaying && !props.isMiniPlayer) {
    return null
  }
  const navigateHome = (): void => {
    router.push('/discover')
  }
  const navigateSearch = (): void => {
    router.push('/search')
  }
  const dispatch = useDispatch()
  const navigateMyStories = (): void => {
    if (userSignedIn) {
      router.push('/my-stories/favorites')
    } else {
      dispatch(toggleSubscriptionModalAction())
    }
  }

  let tabSelected = 'discover'
  switch (props.router.pathname) {
    case '/search':
      tabSelected = 'search'
      break
    case '/my-stories/favorites':
      tabSelected = 'my-stories'
      break
    case '/my-stories/listen-history':
      tabSelected = 'my-stories'
      break
  }
  return (
    <DiscoverMobileBottomNavContainer>
      <NavIconContainer onClick={navigateHome}>
        <DiscoverIcon size={28} color={tabSelected === 'discover' ? colors.white : colors.white50}/>
        <NavTextContainer $selected={tabSelected === 'discover'}>Browse</NavTextContainer>
      </NavIconContainer>
      <NavIconContainer onClick={navigateSearch}>
        <SearchIcon size={28} color={tabSelected === 'search' ? colors.white : colors.white50}/>
        <NavTextContainer $selected={tabSelected === 'search'}>Search</NavTextContainer>
      </NavIconContainer>
      <NavIconContainer onClick={navigateMyStories}>
        <MyStoriesIcon size={28} color={tabSelected === 'my-stories' ? colors.white : colors.white50}/>
        <NavTextContainer $selected={tabSelected === 'my-stories'}>My Library</NavTextContainer>
      </NavIconContainer>
    </DiscoverMobileBottomNavContainer>
  )
}

export const DiscoverBottomMobileNav = withRouter(DiscoverMobileBottomNavComponent)
export const NAV_BAR_HEIGHT = 83
const NavIconContainer = styled.div`
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

const NavTextContainer = styled.div`
    text-align: center;
    font-family: "Good Sans Regular";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
    ${(props: any): string => {
      return props.$selected ? `color: ${colors.white};` : `color: ${colors.white50};`
    }}
margin-top: 8px;
`

const DiscoverMobileBottomNavContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: ${NAV_BAR_HEIGHT}px;
    padding-top: 8px;
    background-color: ${colors.midnightBlue};
    z-index: 2;

    ${(props: any): string => config(props).media.md`
      display: none;
    `}
    ${(props: any): string => config(props).media.lg`
      display: none;
  `}
    ${(props: any): string => config(props).media.xl`
      display: none;
  `}
`
