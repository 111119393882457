// @flow
import * as React from 'react'
import { colors } from '../colors'

export type PropsType = {
  size?: number,
  color?: string
}

const HeartFilledIcon = (props: PropsType): React.Node => {
  return (
    <svg
      height={props.size || 30}
      width={props.size || 30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30L13.9051 28.8876C12.9905 27.9577 12.0656 27.0417 11.1714 26.1555C5.177 20.2175 0 15.0888 0 8.52677C0 4.77231 2.06771 1.69567 5.39591 0.49705C8.65229 -0.675138 12.209 0.260724 15.0034 2.93709C17.8133 0.259151 21.3768 -0.676082 24.6279 0.500826C27.9416 1.70102 30 4.77609 30 8.52677C30 15.0885 24.8233 20.2166 18.8295 26.1549C17.935 27.041 17.0098 27.9574 16.0945 28.8876L15 30Z"
        fill={props.color || colors.white}
      />
    </svg>
  )
}

export default HeartFilledIcon
